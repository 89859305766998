import { Component, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { ApolloError } from "@apollo/client";
import { Apollo } from "apollo-angular";
import { sendContactForm } from "src/app/graphql/mutations";

import { MatSnackBar, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss']
})
export class ContactFormComponent {
  @ViewChild('submitButton', { static: true }) submitButton: ElementRef;
  @ViewChild('submitNewsButton', { static: true }) submitNewsButton: ElementRef;

  get email() {
    return this.contactForm.get('email');
  }
  get name() {
    return this.contactForm.get('name');
  }
  get message() {
    return this.contactForm.get('message');
  }

  get newsEmail() {
    return this.newsLetterForm.get('email');
  }

  contactForm: UntypedFormGroup;
  isSubmitting = false;
  submitted: boolean = false;

  newsLetterForm: UntypedFormGroup;
  isNewsSubmitting = false;
  newsSubmitted: boolean = false;

  translations =
    {
      1: {
        "hu": "Küldj nekünk üzenetet, ha bármilyen, a platformmal kapcsolatos visszajelzésed vagy fejlesztési ötleted van!",
        "en": "Get in touch with us if you have any feedback regarding our platform or ideas for improvement."
      },
      2: {
        "hu": "Név",
        "en": "Name"
      },
      3: {
        "hu": "Add meg a neved.",
        "en": "Enter your name."
      },
      4: {
        "hu": "Email",
        "en": "Email"
      },
      5: {
        "hu": "Add meg az emailcímed.",
        "en": "Enter your email address."
      },
      6: {
        "hu": "Üzenet",
        "en": "Message"
      },
      7: {
        "hu": "Írd meg az üzeneted.",
        "en": "Leave a message."
      },
      8: {
        "hu": "KÜLDÉS",
        "en": "SUBMIT"
      },
      9: {
        "hu": "Értesülj a menta újdonságokról elsőként",
        "en": "Get menta news delivered to your inbox"
      },
      10: {
        "hu": "Megerősítem és elfogadom adataim az Adatvédelmi szabályzatban foglaltak szerinti felhasználását.",
        "en": "I confirm that I have read and agree to the use of my data as stated in the Privacy Policy."
      },
      11: {
        "hu": "FELIRATKOZÁS",
        "en": "SUBSCRIBE"
      }
    }

  constructor(
    public apollo: Apollo,
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private matSnackBar: MatSnackBar
  ) {
    this.contactForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      message: ['', Validators.required]
    });
    this.newsLetterForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onNewsLetterSubmit() {
    this.matSnackBar.open("Update successful", "Okay",
      {
        duration: 50000,
        horizontalPosition: "center",
        verticalPosition: "top" as MatSnackBarVerticalPosition,
        // direction: "rtl"
      });

    if (this.newsLetterForm.valid) {
      console.log('newsletter form!')
      const spanElement = this.submitNewsButton.nativeElement.querySelector('span');
      spanElement.textContent = this.translate.currentLang == 'en' ? 'SUBSCRIBING' : 'FELIRATKOZÁS';
      this.isNewsSubmitting = true;

      // Apollo

      this.apollo.mutate({
        mutation: sendContactForm(
          'Newsletter subscription',
          this.newsEmail.value,
          'Newsletter subscription')
      }).subscribe({
        next: (response) => {
          console.log(response);
          if (response['data']['sendContactForm']) {
            this.isNewsSubmitting = false;
            this.newsSubmitted = true;
            const spanElement = this.submitNewsButton.nativeElement.querySelector('span');
            spanElement.textContent = this.translate.currentLang == 'en' ? 'SUBSCRIBED' : 'BEKÜLDVE';
          }
          else {
            spanElement.textContent = this.translate.currentLang == 'en' ? 'ERROR!' : 'HIBA!';
            setTimeout(() => {
              spanElement.textContent = this.translate.currentLang == 'en' ? 'SUBSCRIBE' : 'FELIRATKOZÁS';
            }, 1000);
          }
        },
        error: (e: ApolloError) => {
          //create snackbar for error message
          this.isSubmitting = false;
          spanElement.textContent = this.translate.currentLang == 'en' ? 'ERROR!' : 'HIBA!';
          setTimeout(() => {
            spanElement.textContent = this.translate.currentLang == 'en' ? 'SUBSCRIBE' : 'FELIRATKOZÁS';
          }, 1000);

        }
      });

    } else {
      this.newsLetterForm.markAllAsTouched();
    }
  }

  onSubmit() {

    if (this.contactForm.valid) {
      const spanElement = this.submitButton.nativeElement.querySelector('span');
      spanElement.textContent = this.translate.currentLang == 'en' ? 'SUBMITTING' : 'KÜLDÉS';
      this.isSubmitting = true;

      // Apollo request

      console.log('contact form!')

      this.apollo.mutate({
        mutation: sendContactForm(
          this.name.value,
          this.email.value,
          this.message.value)
      }).subscribe({
        next: (response) => {
          console.log(response);
          if (response['data']['sendContactForm']) {
            // Reset isSubmitting flag and set submitted flag to true
            this.isSubmitting = false;
            this.submitted = true;
            const spanElement = this.submitButton.nativeElement.querySelector('span');
            spanElement.textContent = this.translate.currentLang == 'en' ? 'SUBMITTED' : 'ELKÜLDVE';
          }
          else {
            this.isSubmitting = false;
            spanElement.textContent = this.translate.currentLang == 'en' ? 'ERROR!' : 'HIBA!';
            setTimeout(() => {
              spanElement.textContent = this.translate.currentLang == 'en' ? 'SUBMIT' : 'KÜLDÉS';
            }, 1000);

          }
        },
        error: (e: ApolloError) => {
          //create snackbar for error message
          this.isSubmitting = false;
          spanElement.textContent = this.translate.currentLang == 'en' ? 'ERROR!' : 'HIBA!';
          setTimeout(() => {
            spanElement.textContent = this.translate.currentLang == 'en' ? 'SUBMIT' : 'KÜLDÉS';
          }, 1000);

          console.log(e)
          console.log(e.networkError['error']['errors'][0]['message'])
        }
      });

    } else {
      this.contactForm.markAllAsTouched();
    }
  }
}


