export const text = {
  "feedback":{
    "en": "Something not working properly? Let us know!",
    "hu": "Valami nem működik? Visszajelzés küldése"
  },
  "anon":{
    "en": "The following identifier has been assigned to this assessment:",
    "hu": "A felmérő azonosítója:"
  },
  "noPersonal":{
    "en": "Your mental health professional has requested no personal details from you.",
    "hu": "A terapeutája nem vár személyes adatokat."
  },
  "specify":{
    "en": "Please, specify...",
    "hu": "Kérem, pontosítson..."
  },
  "choose": {
    "en": "Choose...",
    "hu": "Válasszon..."
  },
  "label": {
    "lastName": {
      "en": "Last name",
      "hu": "Vezetéknév"
    },
    "firstName": {
      "en": "First name",
      "hu": "Keresztnév"
    },
    "sex": {
      "en": "Sex",
      "hu": "Nem"
    },
    "dob": {
      "en": "Date of birth",
      "hu": "Születési dátum"
    },
    "nationality": {
      "en": "Nationality",
      "hu": "Nemzetiség"
    },
    "email": {
      "en": "Email",
      "hu": "Email cím"
    },
    "phone": {
      "en": "Phone number",
      "hu": "Telefonszám"
    },
    "address": {
      "en": "Address line 1",
      "hu": "Lakcím - utcanév, házszám"
    },
    "city": {
      "en": "City",
      "hu": "Város"
    },
    "zip": {
      "en": "ZIP code",
      "hu": "Irányítószám"
    },
    "country": {
      "en": "Country",
      "hu": "Ország"
    },
  },
  "error": {
    "lastName": {
      "en": "Enter your last name.",
      "hu": "Írja be a vezetéknevét."
    },
    "firstName": {
      "en": "Enter your first name.",
      "hu": "Írja be a keresztnevét."
    },
    "sex": {
      "en": "Select your sex.",
      "hu": "Válassza ki a nemét."
    },
    "dob": {
      "en": "Select your date of birth.",
      "hu": "Adja meg a születési dátumát."
    },
    "nationality": {
      "en": "Select your nationality.",
      "hu": "Válassza ki a nemzetiségét."
    },
    "email": {
      "en": "Enter your email.",
      "hu": "Adja meg az email címét."
    },
    "phone": {
      "en": "Enter your phone number. Only numbers are allowed.",
      "hu": "Adja meg a telefonszámát. Csak számokat használjon."
    },
    "address": {
      "en": "Enter your address.",
      "hu": "Adja meg a címét."
    },
    "city": {
      "en": "Enter your city.",
      "hu": "Adja meg a városát."
    },
    "zip": {
      "en": "Enter your ZIP code.",
      "hu": "Adja meg az irsz.-át."
    },
    "country": {
      "en": "Select your country.",
      "hu": "Válassza ki az országát."
    },
  },

  "title": {
    "welcome": {
      "en": "Online assessment",
      "hu": "Online felmérés"
    },
    "personal": {
      "en": "Personal details",
      "hu": "Személyes adatok"
    },
    "submit": {
      "en": "Submit answers",
      "hu": "Válaszok beküldése"
    },
  },
  "stage": {
    "welcome": {
      "en": "The following assessment was created by your mental health professional and the results will be shared only with him/her.",
      "hu": "Az alábbi felmérést a terapeutája állította össze. Az eredményeket csak vele osztjuk meg."
    },
    "privacy": {
      "en": "The Menta platform does not store the information you provide here.",
      "hu": "A Menta platform nem tárolja az itt megadott adatokat."
    },
    "expectedTime": {
      "en": "Expected time for completion is around 60 minutes.",
      "hu": "A várható kitöltési idő 60 perc."
    },
    "start": {
      "en": "Start assessment",
      "hu": "Kitöltés elkezdése"
    },
    "completion": {
      "en": "Thank you for completing the assessment!",
      "hu": "Köszönjük, hogy kitöltötte a kérdőívet!"
    },
    "submit": {
      "en": "After clicking submit, your responses will be summarized and shared with your mental health professional only.",
      "hu": "Az alábbi gomb megnyomása után válaszait összegezzük és kizárólag terapeutájával osztjuk meg."
    }
  },
  "navigation": {
    "previous": {
      "en": "Previous",
      "hu": "Előző"
    },
    "next": {
      "en": "Next",
      "hu": "Következő"
    },
    "beginAssessment": {
      "en": "Start test",
      "hu": "Teszt indítása"
    },
    "submitButtonText": {
      "en": "Submit",
      "hu": "Küldés"
    },
    "submitting": {
      "en": "Submitting...",
      "hu": "Küldés..."
    },
    "submitSuccessText": {
      "en": "Submission successful",
      "hu": "Sikeres beküldés"
    },
    "submitFailureText": {
      "en": "Submission unsuccessful",
      "hu": "Sikertelen küldés"
    }
  },
  "personal": {
    "title": {
      "en": "Personal data",
      "hu": "Személyes adatok"
    },
    "subtitle": {
      "en": "By filling in the required data, you are helping in assessing your circumstances better. Data will not be shared with third parties.",
      "hu": "Az adatok kitöltésével az Ön körülményeinek jobb megismerését segíti. Az adatok harmadik féllel nem kerülnek megosztásra."
    },
    "firstName": {
      "en": "First name",
      "hu": "Keresztnév"
    },
    "lastName": {
      "en": "Last name",
      "hu": "Vezetéknév"
    },
    "name": {
      "en": "Full name",
      "hu": "Teljes név"
    },
    "email": {
      "en": "Email address",
      "hu": "Email cím"
    },
    "phone": {
      "en": "Phone number",
      "hu": "Telefonszám"
    },
    "sex": {
      "en": "Sex",
      "hu": "Születési nem"
    },
    "dob": {
      "en": "Date of birth",
      "hu": "Születési idő"
    },
    "nationality": {
      "en": "Nationality",
      "hu": "Nemzetiség"
    },
    "address": {
      "en": "Address",
      "hu": "Lakcím"
    }
  }
}
