import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './user.service';
import { AttributeService } from 'src/app/services/attribute.service';
import { Apollo } from 'apollo-angular';
import { updateUserDetails } from '../graphql/mutations';
import { HttpHeaders } from '@angular/common/http';
import { ApolloError } from '@apollo/client';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})

export class LocalStorageService {
  public languageSubject: BehaviorSubject<any>;
  public tokenSubject: BehaviorSubject<any>;
  private userLanguageSetting;
  // private LANGUAGEOPTIONS;
  private LANGUAGESELECTOR;

  constructor(
    private translate: TranslateService,
    private userService: UserService,
    private attribute: AttributeService,
    private apollo: Apollo,
    // private route: ActivatedRoute,
    private router: Router, private activatedRoute: ActivatedRoute,
    private readonly title: Title,

  ) {
    this.languageSubject = new BehaviorSubject('');
    this.initialGetLanguage();
    this.LANGUAGESELECTOR = this.attribute.LANGUAGESELECTOR;
    // console.log('this.LANGUAGESELECTOR', this.LANGUAGESELECTOR)

  }

  initialGetLanguage = () => {
    // Initial language getting when opening the site and logging in
    const params = this.activatedRoute.snapshot.queryParams;
    const paramLang = params['lang'];

    try {


      (firstValueFrom(this.userService.getUser$())
        .then((response) => {
          for (let i = 0; i < this.LANGUAGESELECTOR.length; i++) {
            const e = this.LANGUAGESELECTOR[i];
            if (e.id == response.language.id) {
              this.userLanguageSetting = e.short;
              break;
            }
          }

          // this.LANGUAGESELECTOR.forEach(e => {
          //   if (e.id == response.language.id) {
          //     this.userLanguageSetting = e.short;
          //   }
          // })

          // if (response.language.id == 120) {
          //   this.userLanguageSetting = 'hu'
          // } else if (response.language.id == 119) {
          //   this.userLanguageSetting = 'en'
          // }
          // If lang parameter used in url - set to value
          // If user logged in - set language to user spec 
          // If not logged in - use local storage
          // If first visit - default to English

          this.language = (paramLang ||
            this.userLanguageSetting ||
            localStorage.getItem('language') ||
            'en')
        })
      );
    } catch (error) {
      console.log(error)
    }
  }

  get language() { return localStorage.getItem('language'); }
  get token() { return localStorage.getItem('token'); }

  set language(value) {
    this.languageSubject.next(value);
    localStorage.setItem('language', value); // add language setting to cookies
    this.translate.use(value)
    let details;
    // Set language in database every time it changes
    // Find language ID corresponding to short code
    this.LANGUAGESELECTOR.forEach(e => {
      if (e.short == value) {
        details = {
          language: e.id
        }
      }
    });

    this.apollo.mutate({
      mutation: updateUserDetails(details),
      context: {
        headers: new HttpHeaders({
          "atk": localStorage.getItem('token') === null ? '' : localStorage.getItem('token')
        })
      }
    }).subscribe(
      {
        next: async (response) => {
          console.log('language changed');
          console.log('reload title!');
          const title = this.title.getTitle().replace('[menta] ','');
          console.log(this.translate.currentLang)
          console.log(`[menta] ` + this.translate.instant(title))
          // console.log('route',await firstValueFrom(this.route.title));
          this.title.setTitle(`[menta] ` + this.translate.instant(title));

        },
        error: (e: ApolloError) => {
          // MISSING: Error feedback via toaster
          console.log(e.networkError['error']['errors'][0]['message'])
        }
      }
    )
  }

  set token(value: string) {
    this.languageSubject.next(value);
    localStorage.setItem('token', value);
  }

  public changeLanguageById(id: number) {
    this.LANGUAGESELECTOR.forEach(e => {
      if (e.id == id) {
        this.language = e.short;
      }
    });
  }
}
