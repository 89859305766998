export const text = {
    "error": { 
        "password": { 
          "en": "Enter a minimum 8 character password.", 
          "hu": "A jelszónak legalább 8 karakterből kell állnia." 
        },
        "nonMatchingPassword": { 
            "en": "Passwords don't match.", 
            "hu": "A megadott jelszavak nem egyeznek." 
          }
    },
    // "occupation": {
    //     "en": [
    //         "mental health professional",
    //         "psychologist",
    //         "psychiatrist",
    //         "other"
    //     ],
    //     "hu": [
    //         "mentalhigiénés szakember",
    //         "pszichológus",
    //         "pszichiáter",
    //         "egyéb"
    //     ]
    // },
    "choose": {
        "en": "Choose...",
        "hu": "Válasszon..."
    },
    "label": {  
        "password": {
            "en": "New password",
            "hu": "Új jelszó"
        },
        "confirmPassword": {
            "en": "Confirm new password",
            "hu": "Új jelszó megerősítés"
        },
        "streetName": {
            "en": "Street name",
            "hu": "Utcanév"
        },
        "houseNumber": {
            "en": "House number",
            "hu": "Házszám"
        },
        "occupation": {
            "en": "Occupation",
            "hu": "Foglalkozás"
        },
        "lastName": {
            "en": "Last name",
            "hu": "Vezetéknév"
        },
        "firstName": {
            "en": "First name",
            "hu": "Keresztnév"
        },
        "sex": {
            "en": "Sex",
            "hu": "Nem"
        },
        "dob": {
            "en": "Date of birth.",
            "hu": "Születési dátum"
        },
        "nationality": {
            "en": "Nationality.",
            "hu": "Nemzetiségét."
        },
        "email": {
            "en": "Email",
            "hu": "Email cím"
        },
        "phone": {
            "en": "Phone number",
            "hu": "Telefonszám"
        },
        "address": {
            "en": "Address line 1",
            "hu": "Lakcím - utcanév, házszám"
        },
        "city": {
            "en": "City",
            "hu": "Város"
        },
        "zipCode": {
            "en": "ZIP code",
            "hu": "Irányítószám"
        },
        "country": {
            "en": "Country",
            "hu": "Ország"
        },
        "language": {
            "en": "Language",
            "hu": "Nyelv"
        }
    }
}