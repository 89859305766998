import { Component, OnInit, ChangeDetectorRef, Output, Input, EventEmitter } from "@angular/core";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, Subject } from 'rxjs';
import { header, data } from './testDetails';

import {
  state, trigger, style, animate, transition,
} from '@angular/animations';
import { TranslateService } from "@ngx-translate/core";
import { TestsService } from '../../../services/tests.service'
import { AttributeService } from "src/app/services/attribute.service";
import { TruncatePipe } from "src/app/pipes/truncate.pipe";

@Component({
  selector: "app-sortable",
  templateUrl: "sortable.component.html",
  styleUrls: ['./sortable.component.scss'],
  animations: [
    trigger('rotateIcon180', [
      state('true', style({ transform: 'rotate(180deg)' })),
      state('false', style({ transform: 'rotate(0deg)' })),
      transition('false <=> true', animate('0.3s ease-out')),
    ]),
    trigger('rotateIcon', [
      state('true', style({ transform: 'rotate(135deg)' })),
      state('false', style({ transform: 'rotate(0deg)' })),
      transition('true <=> false', animate('0.3s ease-out')),
    ]),
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('.5s .2s ease-out', style({ opacity: '1' })),
      ]),
      transition(':leave', [
        style({ opacity: '1' }),
        animate('.2s  ease-out', style({ opacity: '0' })),
      ])
    ]),
    trigger('fadeInTrigger', [
      state('true',
        style({ opacity: '1' })
      ),
      state('false',
        style({ opacity: '0' })
      ),
      transition("false => true", animate('.6s ease-out'))
    ])
  ]
})


export class SortableComponent implements OnInit {
  @Output() onTestsPicked = new EventEmitter<any>();

  constructor(
    private modalService: NgbModal,
    private cd: ChangeDetectorRef,
    private translate: TranslateService,
    private testService: TestsService,
    public attribute: AttributeService
  ) { }


  public focus: any;
  public show = true;
  public data = [];
  public header = [];

  loader = false;
searchField='';

  clearSearchInput() {
    this.searchField = '';
    this.searchFieldChange('');  
  }

  uncheckTest = (id) => {
    this.dataOnPage.forEach(element => {
      if (element.id.toString() == id.toString()) {
        element.checked = false;
      }
    });
  }

  testsChanged = (id) => {
    this.onTestsPicked.emit(id);
  }

  testSubscription: Subscription;
  languageSubscription: Subscription;
  category$;
  categoryOptions$; languageOptions$;

  ngOnInit() {
    this.categoryOptions$ = this.attribute.CATEGORIES;
    this.languageOptions$ = this.attribute.LANGUAGES;
    this.testSubscription = this.testService
      .getTestProperties()
      .subscribe((tests) => {
        // console.log('tests', tests)
        setTimeout(() => {
          this.data = tests;
          this.header = header;
          this.render();
          this.loader = true;
        }, 2000);
      })

    this.languageSubscription = this.translate.onLangChange.subscribe(({ lang }) => {
      this.render();
    })
  }

  search = '';
  languageId = -1;
  categoryId = -1;

  public currentPage = 1;
  public activePage = 1;
  public pageSize = 5;

  public filteredData = [];
  public dataOnPage = [];

  filterData = () => {
    this.filteredData = this.data;
    this.filteredData = this.filterByTestLanguage(this.languageId, this.filteredData);
    this.filteredData = this.filterByCategory(this.categoryId, this.filteredData);
    this.filteredData = this.filterBySearchField(this.search, this.filteredData);
  }

  render = () => {
    this.filterData();
    // console.log(this.dataOnPage)
    this.pageChanged({
      page: this.currentPage,
      itemsPerPage: this.pageSize
    });
  }

  pageSizeChange = (size: number) => {
    // console.log('pageSizeChange');
    this.pageSize = size;
    this.render()
  }

  filterByCategory = (categoryId: number, data: any[]) => {
    // console.log(categoryId)
    if ((categoryId == -1)) {
      return data;
    } else {
      const filteredValues = data
        .filter((element) => {
          // console.log(element['categories'])
          let found = false;
          element['categories'].forEach(element2 => {
            if (element2['attributesid']['id'] == categoryId) found = true;
          })
          return found;
        });
      // console.log('filteredValues', filteredValues)
      return filteredValues;
    }
  }

  letterSort(lang, letters) {
    letters.sort(new Intl.Collator(lang).compare);
    return letters;
  }

  order = (attributes: [any]) => {
    let lang = this.translate.currentLang
    if (attributes) {
      return attributes.sort(function (a: string, b: string) {
        if (a['id'] == -1) { return -1; }
        if (b['id'] == -1) { return 1; }
        return a[lang].localeCompare(b[lang], lang)
      })
    }
  }

  categoryChange = (categoryId: number) => {
    this.categoryId = categoryId;
    this.render();
  }

  filterByTestLanguage = (languageId: any, data: any[]) => {
    if (languageId == -1) {
      return data;
    } else {
      const filteredValues = data
        .filter((element) => {
          return element['language']['id'] == languageId;
        });
      return filteredValues;
    }
  }

  filterBySearchField = (field: string, data: any[]) => {
    if (field == '') {
      return data;
    } else {

      const filteredValues = data
        .filter((row: any) => {
        //this.translate.currentLang
          return row.name['en'].toLowerCase().includes(field.toLowerCase()) ||
          row.name['hu'].toLowerCase().includes(field.toLowerCase()) ||
          row.description['en'].toLowerCase().includes(field.toLowerCase()) ||
          row.description['hu'].toLowerCase().includes(field.toLowerCase()) ||
          row.shortName.toLowerCase().includes(field.toLowerCase()) ||
          row.author.toLowerCase().includes(field.toLowerCase()) ||
          row.publicationYear.toLowerCase().includes(field.toLowerCase())
        }
        );
      return filteredValues;
    }
  }

  languageChange = (languageId: number) => {
    this.languageId = languageId;
    this.render();
  }

  searchFieldChange = (fieldValue: string) => {
    this.search = fieldValue;
    this.render();
  }

  pageChanged(event: { page: any; itemsPerPage: any; }): void {
    // console.log('pageChanged')
    this.show = false;
    this.collapseAllRows();
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    // console.log(this.filteredData)
    this.dataOnPage = this.filteredData.slice(startItem, endItem);
    this.cd.detectChanges();
    this.show = true;
  }

  goToLink(link) {
    window.open(link);
  }

  openModal(content: any) {
    this.modalService.open(content, {
      size: 'xl',
      scrollable:true
    });
  }

  expandRow(id: any) {
    this.data.forEach(element => {
      if (element.id == id) {
        if (element.rowClass == "" || !element.rowClass) {
          element.expand = true
          element.rowClass = "show"
        } else { element.rowClass = ""
                  element.expand = false };
      }
    });
  }
  collapseAllRows = () => {
    this.data.forEach(element => {
      element.rowClass = "";
      element.expand = false
    });
  }

  headerClick(id: any) {
    this.header.forEach(element => {
      if (element.id == id && element.sortEnabled) {
        // console.log(id);
        if (element.sortClass == 'fas fa-sort' || element.sortClass == 'fas fa-caret-down') {
          if (['name', 'category', 'language'].includes(element.name)) {
            this.data.sort((a, b) => {
              // console.log()
              return a[element.name][this.translate.currentLang] > b[element.name][this.translate.currentLang] ? 1 : a[element.name][this.translate.currentLang] < b[element.name][this.translate.currentLang] ? -1 : 0
            }
            )
          } else {
            this.data.sort((a, b) => a[element.name] > b[element.name] ? 1 : a[element.name] < b[element.name] ? -1 : 0)
          }
          element.sortClass = 'fas fa-caret-up'
        } else {
          this.data.sort((a, b) => a[element.name] < b[element.name] ? 1 : a[element.name] > b[element.name] ? -1 : 0)
          element.sortClass = 'fas fa-caret-down'
        }
      } else {
        if (element.sortEnabled) { element.sortClass = 'fas fa-sort' }
      }
    });
    this.render();
  }
  ngOnDestroy() {
    this.testSubscription.unsubscribe();
    this.languageSubscription.unsubscribe();
  }
}
