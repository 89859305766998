import { Component, OnInit } from "@angular/core";
import { AbstractControl, FormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "src/app/services/user.service";
import { text } from './text.constants';
// import { countries } from './countries.constants';
import { ApolloError } from "@apollo/client";
import { HttpHeaders } from "@angular/common/http";
import { Apollo } from "apollo-angular";
import { changeUserPassword, updateUserDetails } from "src/app/graphql/mutations";
import { AttributeService } from "src/app/services/attribute.service";
import { LocalStorageService } from "src/app/services/localstorage.service";
import { SortPipe } from "src/app/pipes/sort.pipe";
@Component({
  selector: "app-profile",
  templateUrl: "profile.component.html",
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  constructor(
    public attribute: AttributeService,
    private userService: UserService,
    public translate: TranslateService,
    private apollo: Apollo,
    private storage: LocalStorageService
    ) { }

  language = 'en';
  text = text;
  // countries = countries;

  COUNTRIES; OCCUPATIONS; LANGUAGEOPTIONS;
  user$; user;
  message = "";

  checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
    let pass = group.get('password').value;
    let confirmPass = group.get('confirmPassword').value
    return pass === confirmPass ? null : { notSame: true }
  }
  // User form getters
  get firstName() { return this.userForm.get('firstName'); }
  get lastName() { return this.userForm.get('lastName'); }
  get email() { return this.userForm.get('email'); }
  get occupation() { return this.userForm.get('occupation'); }
  get streetName() { return this.userForm.get('streetName'); }
  get houseNumber() { return this.userForm.get('houseNumber'); }
  get zipCode() { return this.userForm.get('zipCode'); }
  get city() { return this.userForm.get('city'); }
  get country() { return this.userForm.get('country'); }

  // Password form getters
  get password() { return this.passwordForm.get('password'); }
  get confirmPassword() { return this.passwordForm.get('confirmPassword'); }

  // Language form getter
  get languageSelect() { return this.languageForm.get('languageSelect'); }

  userForm: UntypedFormGroup = new UntypedFormGroup({});
  languageForm: UntypedFormGroup = new UntypedFormGroup({
    "language-select": new UntypedFormControl('', [
      Validators.required
    ])
  });
  passwordForm: UntypedFormGroup = new UntypedFormGroup({
    password: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(8)
    ]),
    confirmPassword: new UntypedFormControl('', [
      Validators.required,
      Validators.minLength(8)
    ])
  }, { validators: this.checkPasswords });
  nameOnScreen;

  getNameOnScreen = () => {
    if (this.translate.currentLang == 'en') {
      this.nameOnScreen = `${this.user.firstName} ${this.user.lastName}`
    } else {
      this.nameOnScreen = `${this.user.lastName} ${this.user.firstName}`
    }
  }

  submitNewPassword() {
    this.apollo.mutate({
      mutation: changeUserPassword(this.password.value),
      context: {
        headers: new HttpHeaders({
          "atk": localStorage.getItem('token') === null ? '' : localStorage.getItem('token')
        })
      }
    }).subscribe(
      {
        next: (response) => {
          console.log(response)
          this.message = "Sikeres jelszócsere!";
          this.passwordForm.setControl('password', new UntypedFormControl('', []));
          this.passwordForm.setControl('confirmPassword', new UntypedFormControl('', []));
          setTimeout(() => {
            this.message = "";
          }, 2500);
        },
        error: (e: ApolloError) => {
          this.message = e.networkError['error']['errors'][0]['message'];
          console.log(e.networkError['error']['errors'][0]['message']);
          setTimeout(() => {
            this.message = "";
          }, 2500);
        }
      }
    );
  }

  ngOnInit() {
    this.LANGUAGEOPTIONS = this.attribute.LANGUAGESELECTOR;
    this.attribute.COUNTRIES.subscribe({
      next: (response) => {
        this.COUNTRIES = response;
        console.log(response)
      },
      error: (error) => {
        console.log(error)
      },
    })
    this.attribute.OCCUPATIONS.subscribe({
      next: (response) => {
        this.OCCUPATIONS = response;
      },
      error: (error) => {
        console.log(error)
      },
    })

    this.translate.onLangChange
      .subscribe(({ lang }) => {
        this.language = lang;
        this.getNameOnScreen();
        // Update platform language selector in profile settings
        this.LANGUAGEOPTIONS.forEach(e => {
          if(e.short == lang){
            this.languageSelect.setValue(e.id);
          }
        });
      })

    this.language = this.translate.currentLang
    this.user$ = this.userService.getUser$()

    this.user$.subscribe({
      next: (response) => {
        this.user = response;
        console.log(response)
        this.userForm.addControl('email', new UntypedFormControl(response.email, [Validators.email]));
        this.userForm.addControl('firstName', new UntypedFormControl(response.firstName, []));
        this.userForm.addControl('lastName', new UntypedFormControl(response.lastName, []));
        this.userForm.addControl('occupation', new UntypedFormControl(response.occupation['id'], []));
        this.userForm.addControl('streetName', new UntypedFormControl(response.streetName, []));
        this.userForm.addControl('houseNumber', new UntypedFormControl(response.houseNumber, []));
        this.userForm.addControl('zipCode', new UntypedFormControl(response.zipCode, []));
        this.userForm.addControl('city', new UntypedFormControl(response.city, []));
        this.userForm.addControl('country', new UntypedFormControl(response.country['id'], []));
        this.languageForm.addControl('languageSelect', new UntypedFormControl(response.language['id'], []));
        this.getNameOnScreen()
      },
      error(error) { console.log(error); }
    });

  }
  submitChanges = () => {
    let details = {
      firstName: this.firstName.value,
      lastName: this.lastName.value,
      streetName: this.streetName.value,
      houseNumber: this.houseNumber.value,
      city: this.city.value,
      zipCode: this.zipCode.value,
      occupation: this.occupation.value,
      country: this.country.value
    };

    this.apollo.mutate({
      mutation: updateUserDetails(details),
      context: {
        headers: new HttpHeaders({
          "atk": localStorage.getItem('token') === null ? '' : localStorage.getItem('token')
        })
      }
    }).subscribe(
      {
        next: (response) => {
          //window.location.reload();
        },
        error: (e: ApolloError) => {
          console.log(e.networkError['error']['errors'][0]['message'])
        }
      }
    );
  }

  submitLanguageChange = () => {
    this.storage.changeLanguageById(this.languageSelect.value)
  }

  discardChanges() {
    this.user$.subscribe({
      next: (response) => {
        console.log(response)
        this.user = response;
        this.userForm.setControl('email', new UntypedFormControl(response.email, [Validators.email]));
        this.userForm.setControl('firstName', new UntypedFormControl(response.firstName, []));
        this.userForm.setControl('lastName', new UntypedFormControl(response.lastName, []));
        this.userForm.setControl('occupation', new UntypedFormControl(response.occupation['id'], []));
        this.userForm.setControl('streetName', new UntypedFormControl(response.streetName, []));
        this.userForm.setControl('houseNumber', new UntypedFormControl(response.houseNumber, []));
        this.userForm.setControl('zipCode', new UntypedFormControl(response.zipCode, []));
        this.userForm.setControl('city', new UntypedFormControl(response.city, []));
        this.userForm.setControl('country', new UntypedFormControl(response.country['id'], []));

        this.getNameOnScreen()
      },
      error(error) { console.log(error); }
    });
  }
}
