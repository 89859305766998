export const text = {
    "autoStep": {
        "en": "Automatically show next question",
        "hu": "Automatikus léptetés"
    },
    "feedbackLine1": {
        "en": "Something not working?",
        "hu": "Valami hibásan működik?"
    },
    "feedbackLine2": {
        "en": "Give us your feedback.",
        "hu": "Jelezd nekünk!"
    },
}