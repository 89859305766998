<!--ENGLISH-->
<div *ngIf="(translate.currentLang=='en')">
    <div class="cookie-popup card mx-2 fixed-bottom bg-white shadow p-2" *ngIf="!isAccepted"
        [@fade]="!isAccepted ? 'hidden' : 'visible'">
        <div class="card-body d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div class="mb-2 mb-md-0">
                <div class="d-flex align-items-center mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="36" height="36">
                        <rect width="256" height="256" fill="none" />
                        <path
                            d="M224,127.4a95.6,95.6,0,0,1-28.2,68.5c-36.9,36.9-97.3,37.3-134.7.9A96,96,0,0,1,128.6,32a8.1,8.1,0,0,1,7.8,9.8,32,32,0,0,0,30.8,39,8,8,0,0,1,8,8,32,32,0,0,0,39,30.8A8.1,8.1,0,0,1,224,127.4Z"
                            fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="12" />
                        <circle cx="156" cy="172" r="10" />
                        <circle cx="92" cy="164" r="10" />
                        <circle cx="84" cy="108" r="10" />
                        <circle cx="136" cy="124" r="10" />
                    </svg>
                <h2 class="h3 ml-2">Cookies on our website</h2>
                </div>
                <p class="mb-0">We rely on cookies to enhance your experience. By using our site, you agree to our
                    <a [routerLink]="['/cookie-policy']">cookie policy</a>.
                </p>
            </div>
            <button class="btn btn-primary mt-3 mt-md-0" (click)="acceptCookies()"
                style="font-family: 'Comfortaa', sans-serif; width: 200px; padding-top: 0.8rem; line-height: 1.6rem; font-size: .8rem;">ACCEPT</button>
        </div>
    </div>
</div>
<!--HUNGARIAN-->

<div *ngIf="(translate.currentLang=='hu')">
    <div class="cookie-popup card mx-3 fixed-bottom bg-white shadow p-3" *ngIf="!isAccepted"
        [@fade]="!isAccepted ? 'hidden' : 'visible'">
        <div class="card-body d-flex flex-column flex-md-row justify-content-between align-items-center">
            <div class="mb-2 mb-md-0">
                <div class="d-flex align-items-center mb-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256" width="36" height="36">
                        <rect width="256" height="256" fill="none" />
                        <path
                            d="M224,127.4a95.6,95.6,0,0,1-28.2,68.5c-36.9,36.9-97.3,37.3-134.7.9A96,96,0,0,1,128.6,32a8.1,8.1,0,0,1,7.8,9.8,32,32,0,0,0,30.8,39,8,8,0,0,1,8,8,32,32,0,0,0,39,30.8A8.1,8.1,0,0,1,224,127.4Z"
                            fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="12" />
                        <circle cx="156" cy="172" r="10" />
                        <circle cx="92" cy="164" r="10" />
                        <circle cx="84" cy="108" r="10" />
                        <circle cx="136" cy="124" r="10" />
                    </svg>
                    <h2 class="h3 ml-2">Sütik (cookie) az oldalon</h2>
                </div>
                <p class="mb-0">Az honlap fejlesztéséhez sütiket használunk. Az oldal böngészésével ezt tudomásul
                    veszed. További informáciot a sütik használatáról ebben a <a
                        [routerLink]="['/cookie-policy']">tájékoztatóban</a> találsz.</p>
            </div>
            <button class="btn btn-primary mt-3 mt-md-0" (click)="acceptCookies()"
                style="font-family: 'Comfortaa', sans-serif; width: 200px; padding-top: 0.8rem; line-height: 1.6rem; font-size: .8rem;">ELFOGADOM</button>
        </div>
    </div>
</div>