import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ReactiveFormsModule } from '@angular/forms';

import { FormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient, HttpHeaders } from "@angular/common/http";
import { RouterModule } from "@angular/router";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";

// import { TagInputModule } from "ngx-chips";
import { CollapseModule } from 'ngx-bootstrap/collapse';
// import { NgToggleModule } from '@nth-cloud/ng-toggle';
import { ClipboardModule } from 'ngx-clipboard';

import { AppComponent } from "./app.component";
import { AdminLayoutComponent } from "./layouts/admin-layout/admin-layout.component";
import { AuthLayoutComponent } from "./layouts/auth-layout/auth-layout.component";
import { PresentationModule } from "./pages/presentation/presentation.module";

import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from "./components/components.module";
import { WizardComponent } from './pages/wizard/wizard.component';
import { TablesModule } from './pages/tables/tables.module';
import { HistoryComponent } from './pages/history/history.component';
import { ProfileComponent } from './pages/examples/profile/profile.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { TutorialComponent } from './pages/tutorial/tutorial.component';
import { environment } from '../environments/environment';
import { AssessmentModule } from './pages/assessment/assessment.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ReleaseNotesComponent } from './pages/release-notes/release-notes.component';
// import { CardComponentComponent } from './pages/card-component/card-component.component';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { TermsComponent } from './pages/terms/terms.component';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoaderComponent } from './components/loader/loader.component';
import { SortPipe } from './pipes/sort.pipe';
import { SortPipeModule } from './modules/sort.pipe/sort.pipe.module';
import { FeedbackComponent } from './pages/feedback/feedback.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    WizardComponent,
    HistoryComponent,
    ProfileComponent,
    TutorialComponent,
    ReleaseNotesComponent,
    // CardComponentComponent,
    TermsComponent,
    FeedbackComponent,
    
  ],
  imports: [
    SortablejsModule.forRoot({ animation: 150 }),
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    ComponentsModule,
    TablesModule,
    AssessmentModule,
    BsDropdownModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'hu',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      }
    }),
    CollapseModule.forRoot(),
    // NgToggleModule,
    // TagInputModule,
    PresentationModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ClipboardModule,
    BsDatepickerModule.forRoot(),
    // DatepickerModule.forRoot(),
    BsDropdownModule,
    DragDropModule,
    MatSnackBarModule,
    ApolloModule,
    SortPipeModule
 
  ],
  providers: [
    BsModalService,
    BsLocaleService,
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.apiUrl,
            // uri: 'https://mentahealth.app/api/gql',
            // headers: new HttpHeaders({
            //   "atk": localStorage.getItem('token') === null ? '' : localStorage.getItem('token') 
            // })
            // withCredentials: true,
          }),
        };
      },
      deps: [HttpLink],
    },
    // DragulaService
  ],
  exports: [
    // TruncatePipe
  ],
  bootstrap: [
    AppComponent,
  ]
})
export class AppModule { }
