import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
// import { ToastrService } from 'ngx-toastr';
import { header, data } from './historyDetails';
import {
  state,
  trigger,
  style,
  animate,
  transition,
} from '@angular/animations';

@Component({
  selector: "app-table-history",
  templateUrl: "historytable.component.html",
  styleUrls: ['./historytable.component.scss'],
  animations: [
    trigger('fadeInTrigger', [
      state('true',
        style({ opacity: '1' })
      ),
      state('false',
        style({ opacity: '0' })
      ),
      transition("false => true", animate('.6s  ease-out'))
    ])
  ]
})

export class HistoryTableComponent implements OnInit {
  constructor(
    // public toastr: ToastrService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {

    this.data = data;
    this.header = header;
    this.render();

  }

  public data = [];
  public header = [];

  public rowClass;
  public pageSize = 5;
  public currentPage = 1;
  public show = true;
  public search = '';

  public filteredData = [];
  public dataOnPage = [];

  expandRow(id): void {
    this.data.forEach(element => {
      if (element.id == id) {
        if (element.rowClass == "" || !element.rowClass) {
          element.rowClass = "show"
        } else { element.rowClass = "" };
      }
    });
  }

  filterData = () => {
    this.filteredData = this.filterBySearchField(this.search, this.data);
  }

  searchFieldChange = (fieldValue: string) => {
    // console.log('searchFieldChange')
    this.search = fieldValue;
    this.render();
  }

  filterBySearchField = (field: string, data: any[]) => {
    if (field == '') {
      return data;
    } else {
      const filteredValues = data
        .filter((dataItem: any) =>
          dataItem.name.toLowerCase().includes(field.toLowerCase())
        );
      return filteredValues;
    }
  }

  render = () => {
    this.filterData();
    this.pageChanged({
      page: this.currentPage,
      itemsPerPage: this.pageSize
    });
  }

  pageSizeChange = (size) => {
    // console.log('pageSizeChange');
    this.pageSize = size;
    this.render()
  }

  collapseAllRows = () => {
    this.data.forEach(element => {
      element.rowClass = "";
    });
  }

  pageChanged(event): void {
    this.show = false;
    this.collapseAllRows();
    const startItem = (event.page - 1) * event.itemsPerPage;
    const endItem = event.page * event.itemsPerPage;
    this.dataOnPage = this.filteredData.slice(startItem, endItem);
    this.cd.detectChanges();
    this.show = true;
  }

  headerClick(id): void {
    this.header.forEach(element => {
      if (element.id == id && element.sortEnabled) {
        // console.log(id);
        if (element.sortClass == 'fas fa-sort' || element.sortClass == 'fas fa-caret-down') {
          this.data.sort((a, b) => a[element.name] > b[element.name] ? 1 : a[element.name] < b[element.name] ? -1 : 0)
          element.sortClass = 'fas fa-caret-up'
        } else {
          this.data.sort((a, b) => a[element.name] < b[element.name] ? 1 : a[element.name] > b[element.name] ? -1 : 0)
          element.sortClass = 'fas fa-caret-down'
        }
      } else {
        if (element.sortEnabled) { element.sortClass = 'fas fa-sort' }
      }
      if (element.id == id && element.sortEnabled) {console.log('render');this.render();}
    });
    
  }
}
