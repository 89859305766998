<div class=" header bg-primary pb-6">
</div>
<div class="container-fluid mt--6 ">

    <div class="card m-2">
        <div class="card-header">
            <h1>Teszt előzmények</h1>
        </div>
        <div class="card-body">
            <app-table-history></app-table-history>
        </div>
    </div>
</div>