<!-- <div class="container-fluid">
  <footer class="footer">
    <div class="row align-items-center justify-content-xl-between">
      <div class="col-xl-6">
        <div class="copyright text-center text-xl-left text-muted">
          &copy; {{ test | date: "yyyy" }} menta
          <a
            href="https://www.creative-tim.com?ref=adpa-footer-admin-layout"
            class="font-weight-bold ml-1"
            target="_blank"
            >Creative Tim</a
          >
        </div>
      </div>
      <div class="col-xl-6">
        <ul class="nav nav-footer justify-content-center justify-content-xl-end">
          <li class="nav-item">
            <a [routerLink]="['/presentation']" class="nav-link"
              target="_blank">{{"Rólunk"|translate}}</a>
          </li>
          <li class="nav-item">
            <a
              href="http://blog.creative-tim.com?ref=adpa-footer-admin-layout"
              class="nav-link"
              target="_blank"
              >Blog</a
            >
          </li>
          <li class="nav-item">
            <a
              href="https://www.creative-tim.com/license"
              class="nav-link"
              target="_blank"
              >License</a
            >
          </li>
        </ul>
      </div>
    </div>
  </footer>
</div> -->

<footer style="background-color: rgb(69,31,85); filter: brightness(75%);">
  <div class="container">
    <div class="row py-3">
      <div class="col-3 col-sm-3">
        <p class="mb-0 text-light">&copy; {{ test | date: "yyyy" }} menta</p>
      </div>
      <div class="col-9 col-sm-9 text-center text-md-end">
        <ul class="nav justify-content-end">
          <li class="nav-item d-flex align-items-center">
            <a (click)="scrollTo('learn-more')" class="nav-link text-light"
              >{{"Rólunk"|translate}}</a>
          </li>
          <span class="mx-1 text-light text-md" style="margin-top: 4px;" >&bull;</span>

          <li class="nav-item d-flex align-items-center">
            <a (click)="scrollTo('contact')" class="nav-link text-light"
              >{{"Kapcsolat"|translate}}</a>
          </li>
          <span class="mx-1 text-light text-md" style="margin-top: 4px;" >&bull;</span>

          <!-- <li class="nav-item d-flex align-items-center">
            <a [routerLink]="['/presentation']" class="nav-link text-light"
              >{{"Feltételek"|translate}}</a>
          </li>
          <span class="mx-1 text-light text-md" style="margin-top: 4px;" >&bull;</span> -->

          <li class="nav-item">
            <a [routerLink]="['/privacy']" class="nav-link text-light"
              >{{"Adatvédelem"|translate}}</a>
          </li>
          <span class="mx-1 text-light text-md" style="margin-top: 4px;" >&bull;</span>

          <li class="nav-item">
            <a [routerLink]="['/cookie-policy']" class="nav-link text-light"
              >{{"Sütik"|translate}}</a>
          </li>
          
        </ul>
      </div>
    </div>
  </div>
</footer>
