<div class=" container-fluid mt-4 ">
    <div class="row">
        <div class="col-md-12 col-lg-3 col-xl-4">
            <div class="card">
                <div class="card-header">
                    <h2>Release notes</h2>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <ul class="list-group">
                                <li class="list-group-item" *ngFor="let release of releases.slice().reverse()">
                                    
                                        <div class="row " style="justify-content: space-between;">
                                            <div class="col6 px-3" ><h3>{{ release.version }}</h3></div>
                                            <div class="col6 px-3"><p>{{ release.date }}</p></div>
                                        </div>
                                    <p [innerHTML]="release.description"></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>