export const header = [
    {
        id: 1,
        name: 'name',
        displayName: 'Tesztsorozat neve',
        class: '',
        sortClass: 'fas fa-sort',
        sortEnabled: true
    },
    {
        id: 2,
        name: 'createdAt',
        displayName: 'Létrehozás',
        class: '',
        sortClass: 'fas fa-sort',
        sortEnabled: true
    },
    {
        id: 3,
        name: 'numberOfItems',
        displayName: 'Tesztek száma',
        class: '',
        sortClass: 'fas fa-sort',
        sortEnabled: true
    },
    {
        id: 4,
        name: 'language',
        displayName: 'Nyelve',
        class: '',
        sortClass: 'fas fa-sort',
        sortEnabled: true
    },
    {
        id: 5,
        name: '',
        displayName: 'Kitöltések száma',
        class: '',
        sortClass: 'fas fa-sort',
        sortEnabled: true
    },
    {
        id: 6,
        name: '',
        displayName: 'Részletek',
        class: 'text-center',
        sortClass: '',
        sortEnabled: false
    },
]

export const data =
    [{
        id: 1,
        name: 'Tesztsorozat #1',
        createdAt: '2021.12.01',
        testCount: '4',
        language: 'magyar',
        fillOutCount: '12',
        link: 'https://menta.com/#/assessment/er5fsR6zH2',
        testsSelected: [
            'DUDIT - Extended',
            'WHO Jóllét Index - 1998',
            'BDI I.',
            'BDI II.'],
        personalFields: [
            'Név',
            'Nem',
            'Születési dátum',
            'Telefonszám'],
    },
    {
        id: 2,
        name: 'Tesztsorozat #2',
        createdAt: '2021.12.03',
        testCount: '3',
        language: 'magyar',
        fillOutCount: '3',
        link: 'https://menta.com/#/assessment/Xc5fc5VW6z',
        testsSelected: [
            'DUDIT - Extended',
            'WHO Jóllét Index - 1998',
            'BDI I.'
        ],
        personalFields: [
            'Név',
            'Nem',
            'Születési dátum'],
    },
    {
        id: 3,
        name: 'Tesztsorozat #3',
        createdAt: '2021.12.04',
        testCount: '2',
        language: 'magyar',
        fillOutCount: '18',
        link: 'https://menta.com/#/assessment/Jg60sRLkXo',
        testsSelected: [
            'WHO Jóllét Index - 1998',
            'BDI I.'
        ],
        personalFields: [
            'Név',
            'Nem',
            'Születési dátum',
            'Telefonszám'
        ],
    },
    ]