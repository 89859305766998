import { Component, OnDestroy, OnInit, Pipe } from "@angular/core";
import { Router } from "@angular/router";
// import { LocalStorageService } from "src/app/services/local-storage.service";
import { TranslateModule, TranslateService } from "@ngx-translate/core";

var misc: any = {
  sidebar_mini_active: true
};

export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  isCollapsed?: boolean;
  isCollapsing?: any;
  children?: ChildrenItems[];
}

export interface ChildrenItems {
  path: string;
  title: string;
  type?: string;
  collapse?: string;
  children?: ChildrenItems2[];
  isCollapsed?: boolean;
}
export interface ChildrenItems2 {
  path?: string;
  title?: string;
  type?: string;
}
//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/wizard",
    title: `Felmérő összeállítása`,
    type: "link",
    icontype: "ni-check-bold text-blue"
  },
  // {
  //   path: "/history",
  //   title: "Előzmények",
  //   type: "link",
  //   icontype: "ni-archive-2 text-red"
  // },
  {
    path: "/profile",
    title: "Fiókom",
    type: "link",
    icontype: "ni-badge text-orange"
  },
];

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  // providers: [TranslatePipe]
})

export class SidebarComponent implements OnInit {
  public menuItems: any[];
  public isCollapsed = true;

  constructor(
    public translate: TranslateService,
    // translate: TranslatePipe,
    // private localStorageService: LocalStorageService,
    private router: Router,
    // shared: SharedModule
  ) { }


  ngOnInit() {
    // this.translate.get(['']).subscribe(translations => {
    //   console.info(this.translate.instant('Előző'));
    //   console.info(`${'key2'|translate})`);
    // console.info(this.translate.instant('key3'));
    // })

    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe(event => {
      this.isCollapsed = true;
    });

  }

  onMouseEnterSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.add("g-sidenav-show");
    }
  }
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-show");
    }
  }

  minimizeSidebar() {
    const sidenavToggler = document.getElementsByClassName(
      "sidenav-toggler"
    )[0];
    const body = document.getElementsByTagName("body")[0];
    if (body.classList.contains("g-sidenav-pinned")) {
      misc.sidebar_mini_active = true;
    } else {
      misc.sidebar_mini_active = false;
    }
    if (misc.sidebar_mini_active === true) {
      body.classList.remove("g-sidenav-pinned");
      body.classList.add("g-sidenav-hidden");
      sidenavToggler.classList.remove("active");
      misc.sidebar_mini_active = false;
    } else {
      body.classList.add("g-sidenav-pinned");
      body.classList.remove("g-sidenav-hidden");
      sidenavToggler.classList.add("active");
      misc.sidebar_mini_active = true;
    }
  }
}
