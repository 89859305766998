<div class="container-fluid d-none d-md-block">
  <app-loader [hidden]="loader" style="width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;">
  </app-loader>

  <div class="wrapper" *ngIf="loader">
    <div class=" d-flex flex-wrap justify-content-end">
      <div class="d-flex form-group ml-3">
        <label class=" form-control-label mx-2 my-auto">
          {{"Nyelv"|translate}}
        </label>
        <select (change)="languageChange($event.target.value)" class="form-control form-control-sm w-auto">
          <!-- <option> {{"Összes"|translate}} </option> -->
          <option *ngFor="let lang of order(languageOptions$|async)" [value]="lang['id']">
            {{lang[translate.currentLang]}}
          </option>

        </select>
      </div>
      <div class="d-flex form-group ml-3">
        <label class=" form-control-label mx-2 my-auto ">
          {{"Kategória"|translate}}
        </label>
        <select (change)="categoryChange($event.target.value)"
          class="text-capitalize selectpicker form-control form-control-sm w-auto">
          <!-- <option> {{"Összes"|translate}} </option> -->
          <option class="text-capitalize" *ngFor="let cat of order(categoryOptions$|async)" [value]="cat['id']">
            {{cat[translate.currentLang]}} </option>
        </select>
      </div>
      <div class="d-flex form-group ml-3">
        <label class=" form-control-label mx-2 my-auto">
          {{"Sorok egyszerre"|translate}}
        </label>
        <select (change)="pageSizeChange($event.target.value)" name="datatable_length" aria-controls="datatable"
          class="form-control form-control-sm w-auto selectpicker" data-style="btn-default">
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="20">20</option>
        </select>
      </div>
      <div id="datatable_filter" class="d-flex form-group  ml-3">
        <div class="input-group">
          <input #search type="search" class="form-control form-control-sm" placeholder="{{'Keresés'|translate}}"
            aria-controls="datatable" (input)="searchFieldChange($event.target.value)" [(ngModel)]="searchField" />
          <span class="clear-icon" (click)="clearSearchInput()">
            <i class="fas fa-times"></i>
          </span>
        </div>
      </div>
    </div>


    <div class="table-responsive">
      <table class="table align-items-center table-flush">
        <thead class="thead-light">
          <tr>
            <th [ngClass]="col.class" *ngFor="let col of header" (click)="headerClick(col.id)">
              {{col.displayName | translate }} <i [ngClass]="col.sortClass"></i>
            </th>
          </tr>
        </thead>
        <tbody [@fadeInTrigger]="show">
          <tr *ngIf="dataOnPage.length == 0">
            <td colspan=8>
              <caption class="mx-auto d-block ">
                <p class="mx-auto d-block text-center mt-3">{{"Nincs a keresésnek megfelelő találat."| translate }}</p>
                <img src="../../../../assets/img/vector/not-found.png" alt="not found"
                  class="mx-auto mt-5 mb-3 d-block">
              </caption>
            </td>
          </tr>
          <ng-container *ngFor="let row of dataOnPage">
            <tr>
              <td class="font-weight-bold">{{row.name[translate.currentLang] }} </td>
              <td>
                <div class="d-flex m-auto">
                  <span *ngFor="let cat of row.categories"
                    class="mx-1 py-1 px-2 bg-custom font-weight-bold text-white rounded-pill text-capitalize">{{cat['attributesid'][translate.currentLang]}}</span>
                </div>
              </td>
              <td>{{row.numberOfItems}} {{"kérdés"| translate }}</td>
              <td>{{row.language[translate.currentLang]}}</td>
              <td class="text-center">
                <div class="text-center">
                  <input [ngClass]="translate.currentLang" type="checkbox" class="btn-check" id="btn-check-{{row.id}}"
                    [(ngModel)]="row.checked" autocomplete="off" (click)=testsChanged(row.id)>
                  <label class="btn btn-primary m-auto" for="btn-check-{{row.id}}"></label>
                </div>
              </td>
              <td class="text-center"><a (click)="expandRow(row.id)" aria-controls="dropdown-basic"
                  class="mx-auto btn btn-sm btn-icon-only text-white bg-primary" role="button">
                  <i [ngClass]="row.rowClass" class="fa fa-2x fa-caret-down" style="padding-top:.1em"> </i>
                </a></td>
            </tr>
            <tr>
              <td colspan=8 class="p-0 m-0 border-0">
                <div [ngClass]="row.rowClass" class="detail-row px-4">
                  <div class="py-4">
                    <div class="row gx-1">
                      <div class="col-5 ">
                        <label class="font-weight-bold">{{"Szerző(k)"| translate }}</label>
                        <p class="text-sm">{{row.author}}</p>
                      </div>
                      <div class="col-2 ">
                        <label class="font-weight-bold">{{"Megjelenés éve"| translate }}</label>
                        <p class="text-sm">{{row.publicationYear}}</p>
                      </div>
                      <div class="col-2 ">
                        <label class="font-weight-bold">{{"Rövidítés" | translate }}</label>
                        <p class="text-sm">{{row.shortName}}</p>
                      </div>
                      <div class="col-2 ">
                        <label class="font-weight-bold">{{"Kitöltési idő"| translate }}</label>
                        <p class="text-sm">{{row.fillOutTime}} {{"perc" | translate }}</p>
                      </div>
                    </div>
                    <div class="row gx-1">
                      <div class="col-10 ">
                        <label class="font-weight-bold ">{{"Leírás"| translate }}</label>
                        <p class="text-wrap text-sm">
                          {{row.description[translate.currentLang]}}
                        </p>
                      </div>
                      <div class="col-2 m-auto px-5 text-wrap ">
                        <div class="btn btn-primary my-2 p-0 d-flex align-items-center justify-content"
                          (click)="testId=row.id;openModal(previewModal)"
                          *ngIf="row.shortName!='SUI' && row.shortName!='BMI'" style="width:48px;height:48px;">
                          <!-- {{"Megtekintés"|
                        translate }} -->
                          <i class="fas fa-eye m-auto" style="font-size: 1.5rem;"></i>
                        </div>
                        <div *ngIf="row.link" class="btn btn-primary my-2 p-0 d-flex align-items-center justify-content"
                          (click)="goToLink(row.link)" style="width:48px;height:48px;">
                          <i class="fas fa-file-download m-auto" style="font-size: 1.5rem;"></i>
                          <!-- {{"Letöltés"| translate }} -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

    <div class=" card-footer py-4">
      <nav aria-label="...">
        <pagination [itemsPerPage]="pageSize" class="justify-content-end mb-0" [customPreviousTemplate]="prevTemplate"
          [customNextTemplate]="nextTemplate" [totalItems]="filteredData.length" (pageChanged)="pageChanged($event)"
          [(ngModel)]="currentPage">
        </pagination>

        <ng-template #nextTemplate let-currentPage="currentPage">
          <ng-container *ngIf="!disabled">
            <i class='fas fa-angle-right'></i>
          </ng-container>
        </ng-template>

        <ng-template #prevTemplate let-currentPage="currentPage">
          <ng-container *ngIf="!disabled">
            <i class='fas fa-angle-left'></i>
          </ng-container>
        </ng-template>
      </nav>
    </div>
  </div>

  <ng-template #previewModal let-modal>
    <div class="modal-header bg-info">
      <h4 class=" align-middle modal-title text-white my-auto"
        style="text-transform: uppercase;font-family: 'Comfortaa, sans-serif';" id="modal-basic-title">{{"Teszt
        előnézet"|translate}}</h4>
      <button type="button" class="close btn-close btn-close-white " (click)="modal.close()" data-dismiss="modal"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <app-preview [testId]="testId"></app-preview>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-info" (click)="modal.close()"
        style="text-transform: uppercase;font-family: 'Comfortaa, sans-serif';">{{"Bezárás"|translate}}</button>
    </div>
  </ng-template>
</div>
<!-- Small screen test selection -->
<app-loader [hidden]="loader" class="d-md-none" style="width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;">
</app-loader>

<div class="container-fluid d-block d-md-none mb-4 px-0" [@fadeInTrigger]="show" *ngIf="loader">
  <div class="md-form form-sm form-2 pl-0 mb-2">
    <div id="datatable_filter" style="width: 100%; margin-bottom:.5rem;">
      <div class="input-group">
        <input #search type="search" class="form-control my-0 py-1" placeholder="{{'Keresés'|translate}}"
          aria-controls="datatable" (input)="searchFieldChange($event.target.value)" />
        <span class="clear-icon" (click)="search.value='';searchFieldChange(search.value);">
          <i class="fas fa-times"></i>
        </span>
        <!-- <div class="input-group-append ">
          <span (click)="search.value='';searchFieldChange(search.value);"
            class="btn-square input-group-text bg-primary text-white" id="basic-text1"><i class="px-1 fa fa-times"
              style="font-size:18px" aria-hidden="true"></i></span>
        </div> -->
      </div>
    </div>
    <div class="d-flex form-group w-100" style="margin-bottom:.5rem">
      <div class="input-group">
        <div class="input-group-prepend">
          <label class="text-sm input-group-text" style="color:#495057; width:100px">
            {{"Kategória"|translate}}</label>
        </div>
        <select (change)="categoryChange($event.target.value)" style="height: 3.125rem;"
          class="text-capitalize selectpicker form-control form-control-sm w-auto">
          <option class="text-capitalize" *ngFor="let cat of order(categoryOptions$|async)" [value]="cat['id']">
            {{cat[translate.currentLang]}} </option>
        </select>
      </div>
    </div>
    <div class="d-flex form-group w-100">
      <div class="input-group">
        <div class="input-group-prepend">
          <label class="text-sm input-group-text" style="color:#495057; width:100px">
            {{"Nyelv"|translate}}</label>
        </div>
        <select (change)="languageChange($event.target.value)" style="height: 3.125rem;"
          class="form-control form-control-sm w-auto">
          <option *ngFor="let lang of order(languageOptions$|async)" [value]="lang['id']">
            {{lang[translate.currentLang]}}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div *ngIf="dataOnPage.length == 0">
    <div>
      <caption class="mx-auto d-block ">
        <p class="mx-auto d-block text-center mt-3">{{"Nincs a keresésnek megfelelő találat."| translate }}</p>
        <img src="../../../../assets/img/vector/not-found.png" alt="not found" class="mx-auto mt-5 mb-3 d-block">
      </caption>
    </div>
  </div>
  <ng-container *ngFor="let row of dataOnPage">
    <div class="card-outer">
      <div class="card-top">
        <div class="align-self-center text-sm font-weight-bold" style="max-width: 140px;">
          {{row.name[translate.currentLang]}}</div>

        <div class="align-self-center text-sm">{{row.language[translate.currentLang]}}</div>
        <div class="d-flex">
          <div class="m-2">
            <input type="checkbox" id="btn-check-{{row.id}}" [(ngModel)]="row.checked" autocomplete="off"
              (click)=testsChanged(row.id)>
            <label style="width:48px;height:48px;display: flex;
            justify-content: center;align-items: center;" class="btn btn-sm btn-primary btn-check m-auto"
              for="btn-check-{{row.id}}">
              <i class="fa fa-2x fa-plus" [@rotateIcon]="row.checked"></i>
            </label>
          </div>
          <div class="m-2">
            <a (click)="expandRow(row.id)" aria-controls="dropdown-basic" style="width:48px;
                   height:48px;
                   display: flex;
                   justify-content: center;
                   align-items: center;" class="mx-auto btn btn-sm btn-icon-only text-white bg-primary" role="button">
              <i [ngClass]="row.rowClass" class="fa fa-caret-down" style="font-size: 28px;display: flex;
              justify-content: center;
              align-items: center;"> </i>
            </a>
          </div>
        </div>

      </div>
      <div [ngClass]="row.rowClass" class="detail-row">
        <div style="padding: 0.625rem 0.75rem; line-height: 1.4rem;" class="text-sm">
          <div class="py-1">
            <div class="text-sm font-weight-bold">{{"Szerző(k)"|translate}}</div>
            <div>{{row.author}}</div>
          </div>
          <div class="py-1">
            <div class="text-sm font-weight-bold">{{"Megjelenés éve"|translate}}</div>
            <div>{{row.publicationYear}}</div>
          </div>
          <div class="py-1">
            <div class="text-sm font-weight-bold">{{"Kérdések száma"|translate}}</div>
            <div>{{row.numberOfItems}} {{"kérdés"| translate }}</div>
          </div>
          <div class="py-1">
            <div class="text-sm font-weight-bold">{{"Leírás"|translate}}</div>
            <div>{{row.description[translate.currentLang]}}</div>
          </div>
          <div class="py-1 mb-1">
            <div class="text-sm font-weight-bold">{{"Kategória"|translate}}</div>
            <div class="d-flex m-auto">
              <span *ngFor="let cat of row.categories"
                class="my-1 mx-1 py-1 px-2 bg-custom font-weight-bold text-white rounded-pill text-capitalize">{{cat['attributesid'][translate.currentLang]}}</span>
            </div>
          </div>
          <div class="row m-auto text-wrap ">
            <div class="btn btn-check mr-4 btn-primary my-2 p-0 d-flex align-items-center justify-content"
              (click)="testId=row.id;openModal(previewModal)" *ngIf="row.shortName!='SUI' && row.shortName!='BMI'"
              style="width:48px;height:48px;" role="button">
              <i class="fas fa-eye m-auto" style="font-size: 1.5rem;"></i>
          </div>
            <div *ngIf="row.link" class="btn btn-primary my-2 p-0 d-flex align-items-center justify-content"
              (click)="goToLink(row.link)" style="width:48px;height:48px;">
              <i class="fas fa-file-download m-auto" style="font-size: 1.5rem;"></i>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-container>
  <div class=" card-footer py-4" style="border-top: none;">
    <nav aria-label="...">
      <pagination [itemsPerPage]="pageSize" class="justify-content-end mb-0" [customPreviousTemplate]="prevTemplate"
        [customNextTemplate]="nextTemplate" [totalItems]="filteredData.length" (pageChanged)="pageChanged($event)"
        [(ngModel)]="currentPage">
      </pagination>

      <ng-template #nextTemplate let-currentPage="currentPage">
        <ng-container *ngIf="!disabled">
          <i class='fas fa-angle-right'></i>
        </ng-container>
      </ng-template>

      <ng-template #prevTemplate let-currentPage="currentPage">
        <ng-container *ngIf="!disabled">
          <i class='fas fa-angle-left'></i>
        </ng-container>
      </ng-template>
    </nav>
  </div>
</div>