<app-cookie-consent></app-cookie-consent>

<!------ ENGLISH ------>
<ng-container *ngIf="translate.currentLang=='en'">
  <div class="header pt-5 pb-7" style="background-color: rgb(244, 244, 244);">
    <div class="container">
      <div class="header-body">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="row pt-5 mb-5 mx-auto">
              <img id="hero-img" src="../../../assets/img/landing-img/hero-img.png" width="90%" alt="">
            </div>
          </div>
          <div class="col-lg-6">
            <div>
              <div style="line-height:2.8rem;font-family: 'Comfortaa';"
                class="display-1 font-weight-bold mb-3 text-black">
                menta
              </div>
              <div style="font-family: 'Comfortaa'" class="display-3 font-weight-light">
                A <span class="emphasis-green text-white">digital assistant</span>
                for mental health professionals
              </div>
              <h2 class="mt-5 font-weight-bold" style="font-size:1.5rem;">
                We help your practice grow
              </h2>
              <h2 class="mt-3 font-weight-light">
                We focus on the tasks that keep you away
                from your clients so you can spend more
                time with them.
              </h2>
              <div id="hero-buttons" class="mt-5">
                <a [routerLink]="['/signup']" class="btn btn-primary text-white my-3 mx-2 btn-join"
                  style="width:200px;padding-top:0.8rem; line-height: 1.6rem; font-size: .8rem;font-family: 'Comfortaa';">SIGN
                  UP
                </a>
                <a class="btn btn-outline-primary text-primary my-3 mx-2 learn-btn" (click)="learnMore()"
                  style="width:200px;padding-top:0.8rem; line-height: 1.6rem;font-size: .8rem;font-family: 'Comfortaa';">LEARN
                  MORE
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section style="background-color: rgb(69,31,85);">
    <div class="container" id="learn-more">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-header-container">
            <div class="px-2 emphasis-red display-3" style="font-weight:400;font-family: Comfortaa;">
              assistant features</div>
          </div>
        </div>
      </div>
      <div class="row section-content-lead">
        In this initial version of the application our main goal was to make
        client assessment easier for psychology clinicians and researchers.
      </div>
      <div class="row pb-5">
        <div class="col-lg-6">
          <ul class="text-white custom-bullet red">
            <li>
              Set up an account for your practice</li>
            <li>
              Browse a curated list of well-known
              psychological tests, review and download them</li>
            <li>
              Create a client assessment by selecting the types
              of personal information you wish to gather from
              your clients and pick the tests you would like them to
              take</li>
          </ul>
        </div>
        <div class="col-lg-6">
          <ul class="text-white custom-bullet red">
            <li>
              Generate reusable links to the assessments you create</li>
            <li>
              Have your clients take the assessment at a time and
              in a place convenient to them at their own pace
              using any device</li>
            <li>
              Receive a summary and also the detailed results instantly in
              an email </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section style="position: relative; background-color: rgb(244, 244, 244);">
    <div class="container">
      <div class="row pb-5">
        <div class="col-lg-6">
          <div class="section-header-container">
            <div class="px-2 emphasis-green display-3" style="font-weight:400;font-family: Comfortaa;">
              client assessment</div>
          </div>
          <div style="padding-right: 130px;padding-bottom:3rem">
            <div class="section-content-lead" style="color: black;font-weight: 500;">
              Assessing clients has never been easier.
              Here's&nbsp;why:
            </div>
            <ul class="text-white custom-bullet green">
              <li style="color: black;">
                Set up an assessment and send it out in less than a minute</li>
              <li style="color: black;">
                You receive an email every time a client takes an assessment</li>
              <li style="color: black;">
                Results get archived in your inbox for future reference</li>
              <li style="color: black;">
                You get a birds-eye view of your client and also a detailed breakdown</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 py-5 upgrade" style=" background-color:#00C8AB">
          <div class="section-header-container d-none d-lg-block" style="visibility: hidden;">
            x
          </div>
          <div style="padding-left: 130px;padding-bottom:3rem;">
            <div class="section-content-lead" style="color: white;font-weight: 500;">
              Upgrade to menta and don't&nbsp;look&nbsp;back.
            </div>
            <ul class="text-white custom-bullet white">
              <li>
                Forget about sending emails back and forth between your client</li>
              <li>
                No accidental sharing of confidential client information between clients</li>
              <li>
                No more time spent on evaluating and scoring tests manually</li>
              <li>
                Instant and automatic evaluation means you won't be putting
                off assessments because of the extra work involved</li>
            </ul>
          </div>
        </div>
        <img id="left" src="../../../assets/img/landing-img/phone-left.png" alt="phone left half"
          style="z-index:5;position: absolute;top: 30%;right: 50%; height:400px;">
        <img id="right" src="../../../assets/img/landing-img/phone-right.png" alt="phone right half"
          style="z-index:5;position: absolute;top: 30%;left: 50%; height:400px;">

      </div>
    </div>
  </section>
  <section style="background-color: rgb(244, 244, 244);">
    <div class="container">
      <div class="row ">
        <div class="col-lg-6 ">
          <div class="section-header-container">
            <div class="px-2 emphasis-purple display-3" style="font-weight:400;font-family: Comfortaa;">
              psychological tests</div>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-lg-6">
            <ul style="list-style:none;padding-right: 20px;">
              <li class="my-5" style="font-size: 1.2rem; font-weight: lighter;">
                The following is an ever-expanding list of the
                psychological tests we have available in the
                application</li>
              <li class="my-5" style="font-size: 1.2rem; font-weight: lighter;">
                Let us know if you're missing a particular test and
                we'll reach out to the owner to obtain a license for
                it. </li>
              <li class="my-5" style="font-size: 1.2rem; font-weight: lighter;">
                Are you the copyright or license holder and would like to
                make your test available to other mental health
                professionals? Get in touch with us and we will include
                your test here.
              </li>
            </ul>
          </div>
          <div class="col-lg-6 stretch">
            <ngb-carousel>
              <ng-template ngbSlide *ngFor="let page of pages" [id]="page">
                <div class="d-flex mb-4 px-5">
                  <div class="_carousel">
                    <div *ngFor="let test of tests.slice(page*3, page*3+3)" class="test">
                      <div class="test-preview">
                        <h6></h6>
                        <h2 style="white-space: nowrap;">{{test.shortName}}</h2>
                        <p>{{test.category}}</p>
                      </div>
                      <div class="test-info">
                        <h6>{{test.author}}</h6>
                        <h3>{{trimEllipses(test.name, 20)}}</h3>
                        <p style="font-size: 1rem;">{{trimEllipses(test.description, 45)}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section style="background-color: rgb(244,244,244);" class="pb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-header-container">
            <div class="px-2 emphasis-green display-3" style="font-weight:400;font-family: Comfortaa;">
              future features</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 ">
          <ul class="text-white custom-bullet green">
            <li style="color: black;">
              Integration with scheduling tools
              like Google Calendar, MS Outlook - let
              your clients book a session online and
              have it show up in your calendar
            </li>
            <li style="color: black;">
              Make your business cashless -
              request electronic payment online
              for in-person sessions
            </li>
            <li style="color: black;">
              Automatic electronic invoicing - invoicing
              happens based on your calendar and settings
            </li>
          </ul>
        </div>
        <div class="col-lg-6">
          <ul class="text-white custom-bullet green">
            <li style="color: black;">
              Integration with national health
              service databases for client records
              retrieval and upload
            </li>
            <li style="color: black;">
              Track the progress of your
              clients - we will store your clients
              test results to create charts to
              instantly identify issues
            </li>
            <li style="color: black;">
              Integration with electronic prescription
              systems - generate drug prescriptions
              in app
            </li>
          </ul>
        </div>
      </div>
      <div class="d-table mx-auto px-2 my-3 emphasis-green display-4 font-weight-light text-white"
        style="font-family: 'Comfortaa';">
        Come along with us on this journey!</div>
      <!-- <div class="pb-5"></div> -->
    </div>
  </section>
  <section style="background-color: rgb(69,31,85);" class="pb-5">
    <div class="container" id="contact">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-header-container">
            <div class="px-2 emphasis-red display-3" style="font-weight:400;font-family: Comfortaa;">
              contact us</div>
          </div>
        </div>
      </div>
      <!-- <div class="row section-content-lead d-block">
        Please, get in touch with us at <span style="font-weight: 600;"> hello&nbsp;aț&nbsp;mentahealth.app</span> if
        you have any feedback regarding our platform or have ideas for improvement.
      </div> -->
      <app-contact-form></app-contact-form>
    </div>
  </section>
</ng-container>

<!------ HUNGARIAN ------>
<ng-container *ngIf="translate.currentLang=='hu'">
  <div class="header pt-5 pb-7" style="background-color: rgb(244, 244, 244);">
    <div class="container">
      <div class="header-body">
        <div class="row align-items-center">
          <div class="col-lg-6">
            <div class="row pt-5 mb-5 mx-auto">
              <img id="hero-img" src="../../../assets/img/landing-img/hero-img.png" width="90%" alt="">
            </div>
          </div>
          <div class="col-lg-6">
            <div>
              <div style="line-height:2.8rem;font-family: 'Comfortaa';"
                class="display-1 font-weight-bold mb-3 text-black">
                menta
              </div>
              <div style="font-family: 'Comfortaa'" class="display-3 font-weight-light">
                <span class="emphasis-green text-white">digitális asszisztens</span> mentális egészséggel foglalkozóknak
              </div>
              <h2 class="mt-5 font-weight-bold" style="font-size:1.5rem;">
                Segítünk tovább bővíteni a praxisodat
              </h2>
              <h2 class="mt-3 font-weight-light">
                Elvégezzük helyetted az adminisztratív feladatokat, hogy több időd jusson az ügyfeleidre
              </h2>
              <div id="hero-buttons" class="mt-5">
                <a [routerLink]="['/signup']" class="btn btn-primary text-white my-3 mx-2 btn-join"
                  style="width:200px;padding-top:0.8rem; line-height: 1.6rem; font-size: .8rem;font-family: 'Comfortaa';">
                  CSATLAKOZZ
                </a>
                <a class="btn btn-outline-primary text-primary my-3 mx-2" (click)="learnMore()"
                  style="width:200px;padding-top:0.8rem; line-height: 1.6rem;font-size: .8rem;font-family: 'Comfortaa';">
                  TUDJ MEG TÖBBET
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section style="background-color: rgb(69,31,85);">
    <div class="container" id="learn-more">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-header-container">
            <div class="px-2 emphasis-red display-3" 
            style="font-weight:400;font-family: Comfortaa;
                    ">
              asszisztens funkciók</div>
          </div>
        </div>
      </div>
      <div class="row section-content-lead">
        Az applikáció első verziójával az volt a célunk, hogy megkönnyítsük a
        kutatók és klinikai dolgozók munkáját az ügyfelek, kutatási alanyok
        mentális egészségi állapotának felmérése során
      </div>
      <div class="row pb-5">
        <div class="col-lg-6">
          <ul class="text-white custom-bullet red">
            <li>
              Készíts felhasználói fiókot a praxisodnak</li>
            <li>
              Válogass a pszichológiai tesztek között</li>
            <li>
              Készíts egy felmérőt - válaszd ki, hogy milyen személyes adatokat
              kérsz az ügyfeledtől és milyen teszteket kell kitöltenie</li>
          </ul>
        </div>
        <div class="col-lg-6">
          <ul class="text-white custom-bullet red">
            <li>
              Egy linken keresztül bárkinek elérhető a felmérő, amit készítettél
            </li>
            <li>
              Az ügyfeled a megadott link segítségével kitölthetik a
              felmérőt egy nekik alkalmas időpontban és helyen bármilyen
              eszközön keresztül</li>
            <li>
              A felmérő kitöltését követően azonnal értesülni fogsz az
              eredményről emailben</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section style="position: relative; background-color: rgb(244, 244, 244);">
    <div class="container">
      <div class="row pb-5">
        <div class="col-lg-6">
          <div class="section-header-container">
            <div class="px-2 emphasis-green display-3" style="font-weight:400;font-family: Comfortaa;">
              ügyfelek felmérése</div>
          </div>
          <div style="padding-right: 130px;padding-bottom:3rem">
            <div class="section-content-lead" style="color: black;font-weight: 500;">
              Az ügyfelek tesztelése sose volt ennél egyszerűbb.
            </div>
            <ul class="text-white custom-bullet green">
              <li style="color: black;">
                Állíts össze egy felmérőt kevesebb mint egy perc alatt</li>
              <li style="color: black;">
                Emailben értesítést küldünk minden egyes kitöltés után</li>
              <li style="color: black;">
                Az eredmények mentésre kerülnek a postaládádban</li>
              <li style="color: black;">
                A kiértékelés tartalmaz egy általános összesítést és a részletes válaszokat is
              </li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 pb-5 upgrade" style=" background-color:#00C8AB">
          <div class="section-header-container" style="visibility: hidden;">
            <div class="px-2 emphasis-green display-3" style="font-weight:400;font-family: Comfortaa;">
              ügyfelek felmérése</div>
          </div>
          <div style="padding-left: 130px;padding-bottom:3rem;">
            <div class="section-content-lead" style="color: white;font-weight: 500;">
              Lépj szintet a mentával és&nbsp;ne&nbsp;nézz&nbsp;vissza.
            </div>
            <ul class="text-white custom-bullet white">
              <li>
                A teszteléshez nem kell emaileket küldözni az ügyfeleknek</li>
              <li>
                Nem áll fenn veszélye a bizalmas adatok megosztásának más ügyfelekkel </li>
              <li>
                Ezentúl nem kell egyesével kiértékelni a kitöltött teszteket</li>
              <li>
                Hála az azonnali és automatikus kiértékelésnek, többé nem fogod halogatni
                az ügyfelek felmérését
              </li>
            </ul>
          </div>
        </div>
        <img id="left" src="../../../assets/img/landing-img/phone-left.png" alt=""
          style="z-index:5;position: absolute;top: 30%;right: 50%; height:400px;">
        <img id="right" src="../../../assets/img/landing-img/phone-right.png" alt=""
          style="z-index:5;position: absolute;top: 30%;left: 50%; height:400px;">
      </div>
    </div>
  </section>
  <section style="background-color: rgb(244, 244, 244);">
    <div class="container">
      <div class="row ">
        <div class="col-lg-6 ">
          <div class="section-header-container">
            <div class="px-2 emphasis-purple display-3" style="font-weight:400;font-family: Comfortaa;">
              pszichológiai tesztek</div>
          </div>
        </div>
        <div class="row mx-0">
          <div class="col-lg-6">
            <ul style="list-style:none;padding-right: 20px;">
              <li class="my-5" style="font-size: 1.2rem; font-weight: lighter;">
                Többek között az alábbi tesztek elérhetőek az alkalmazáson keresztül</li>
              <li class="my-5" style="font-size: 1.2rem; font-weight: lighter;">
                Ha szeretnéd, hogy kibővítsük a tesztek listáját, akkor keress meg minket
                és megpróbáljuk megszerezni a teszt közzétételéhez szükséges licenszet.</li>
              <li class="my-5" style="font-size: 1.2rem; font-weight: lighter;">
                Szerzői jog tulajdonosa vagy és szeretnéd az oldalon keresztül
                hozzáférhetővé tenni a tesztedet? Lépj kapcsolatba velünk
                és közzétesszük a felhasználóink számára.
              </li>
            </ul>
          </div>
          <div class="col-lg-6 stretch">
            <ngb-carousel>
              <ng-template ngbSlide *ngFor="let page of pages" [id]="page">
                <div class="d-flex mb-4 px-5">
                  <div class="_carousel">
                    <div *ngFor="let test of tests.slice(page*3, page*3+3)" class="test">
                      <div class="test-preview">
                        <h6></h6>
                        <h2 style="white-space: nowrap;">{{test.shortName}}</h2>
                        <p>{{test.category}}</p>
                      </div>
                      <div class="test-info">
                        <h6>{{test.author}}</h6>
                        <h3>{{trimEllipses(test.name, 20)}}</h3>
                        <p style="font-size: 1rem;">{{trimEllipses(test.description, 45)}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section style="background-color: rgb(244,244,244);" class="pb-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-header-container">
            <div class="px-2 emphasis-green display-3" style="font-weight:400;font-family: Comfortaa;">
              fejlesztési tervek</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 ">
          <ul class="text-white custom-bullet green">
            <li style="color: black;">
              Integráció a különböző naptár alkalmazásokkal, például
              Google Calendar, MS Outlook - az ügyfelek előjegyeznek
              egy időpontot online és az megjelenik a naptáradban
            </li>
            <li style="color: black;">
              Készpénzmentes praxis -
              a személyes konzultációk díját
              kérd elektronikusan
            </li>
            <li style="color: black;">
              Automatikus számlázás a naptárod alapján
            </li>
          </ul>
        </div>
        <div class="col-lg-6">
          <ul class="text-white custom-bullet green">
            <li style="color: black;">
              Integráció az EESZT rendszerével -
              automatikus elégtétel az állami
              adatszolgáltatási kötelezettségeknek
            </li>
            <li style="color: black;">
              Az ügyfelek állapota nyomon követhetővé
              válik az applikáción keresztül -
              grafikonon látszik a terápiás módszerek
              hatása
            </li>
            <li style="color: black;">
              Applikáción belüli receptírás
              lehetősége
            </li>
          </ul>
        </div>
      </div>
      <!-- <div class="d-table mx-auto px-2 my-3 emphasis-green display-4 font-weight-light text-white"
          style="font-family: 'Comfortaa';">
          Gyere velünk ezen az úton!</div> -->
      <!-- <div class="pb-5"></div> -->
    </div>
  </section>
  <section style="background-color: rgb(69,31,85);" class="pb-5">
    <div class="container" id="contact">
      <div class="row">
        <div class="col-lg-6">
          <div class="section-header-container">
            <div class="px-2 emphasis-red display-3" style="font-weight:400;font-family: Comfortaa;">
              kapcsolat</div>
          </div>
        </div>
      </div>
      <!-- <div class="row section-content-lead d-block">
        Küldj nekünk üzenetet, ha bármilyen a platformmal kapcsolatos visszajelzésed vagy fejlesztési ötleted van!
      </div> -->
      <app-contact-form></app-contact-form>

    </div>
  </section>
</ng-container>

<app-footer></app-footer>