import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import {text} from './text.constants'
import { Router } from '@angular/router';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit{
  @Input() language!: string;
  @Output() optionChangeEvent = new EventEmitter<Object>();

  constructor(private router:Router){
    
  }

  ngOnInit(){
    this.optionChangeEvent.emit({"autoNext":this.autoNext});
    console.log('options language:', this.language)
    this.routerLink=`/feedback?lang=${this.language}`
    console.log('routerLink',this.routerLink)
  }

  updateOptions() {
    this.optionChangeEvent.emit({"autoNext":this.autoNext});
  }

  goToFeedback(){
    this.router.navigate(
      ['/feedback'],
      { queryParams: { lang: this.language } }
    );
    
  }

  isOpen = false;
  autoNext = true;
  text=text;
routerLink ;

  togglePanel() {
    this.isOpen = !this.isOpen;
    const panel = document.querySelector('.slide-out-panel');
    panel.classList.toggle('open');

    const pullTab = document.querySelector('.pull-tab');
    pullTab.classList.toggle('open');

    const overlay = document.querySelector('.overlay');
    overlay.classList.toggle('open');
  }
}
