import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Apollo } from "apollo-angular";
import { QUERY_USER } from '../graphql/queries'
import { HttpHeaders } from '@angular/common/http';

export interface User {
    id?: number;
    email?: string;
    firstName?: string;
    lastName?: string;
    nameHu?: string;
    nameEn?: string;
    city?: string;
    address?: string;
    zipCode?: string;
    streetName?: string;
    houseNumber?: string;
    language?: any;
    uRoleId?: number;
    tRoleId?: number;
    occupation?: any;
    country?: any;
}

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private apollo: Apollo) { }
        
    public getUser$(): Observable<User> {
    
        let token =localStorage.getItem('token') === null ? '' : localStorage.getItem('token')

        return this.apollo
            .query({
                fetchPolicy: 'no-cache',
                query: QUERY_USER,
                context: {
                    headers: new HttpHeaders({
                        "atk":token
                    })
                }
            })
            .pipe(map(({ data, loading }) => {
                // console.log('data',data)
                return this.mapResult(data)
            }))
            .pipe(
                catchError(error => {
                    // console.log('user.service.ts',error)
                    return of(
                        {
                            id: null,
                            email: null,
                            firstName: null,
                            lastName: null,
                            city: null,
                            address: null,
                            zipCode: null,
                            streetName: null,
                            houseNumber: null,
                            occupationid: {
                                id: null,
                                en: null,
                                hu: null,
                            },
                            country: {
                                id: null,
                                en: null,
                                hu: null,
                            },
                            language: {
                                id: null,
                                en: null,
                                hu: null,
                            },
                            uRoleId: null,
                            tRoleId: null,
                        }
                    );
                })

            )
    }

    private mapResult(response): User {
        let user: User;
        let userData = response['userInf'];
        user = {
            id: Number(userData['id']),
            email: userData['email'],
            firstName: userData['firstName'],
            lastName: userData['lastName'],
            nameEn: `${userData['firstName']} ${userData['lastName']}`,
            nameHu: `${userData['lastName']} ${userData['firstName']}`,
            city: userData['city'],
            address: userData['address'],
            zipCode: userData['zipCode'],
            streetName: userData['streetName'],
            houseNumber: userData['houseNumber'],
            uRoleId: Number(userData['uRoleId']),
            tRoleId: Number(userData['tRoleId']),
            occupation: {
                id: Number(userData['occupationid']['id']),
                en: userData['occupationid']['en'],
                hu: userData['occupationid']['hu']
            },
            country: {
                id: Number(userData['countryid']['id']),
                en: userData['countryid']['en'],
                hu: userData['countryid']['hu']
            },
            language: {
                id: Number(userData['languageid']['id']),
                en: userData['languageid']['en'],
                hu: userData['languageid']['hu']
            },
        }
        return user;
    }
}
