<div class="container">
    <div [@fadeIn]="show"  class="card">
        <div class="card-header bg-primary">
            <div class="text-center title_ text-white mb-0 font-weight-bold text-uppercase">
                {{ text.title[ language ] }}
            </div>
        </div>
        <div class="card-body" style="padding-left: 2rem !important; padding-right: 2rem !important;">
            <div class="py-5 ">
                <p>{{ text.firstParagraph[ language ] }}</p>
                <!-- <p>{{ text.secondParagraph[ language ] }}</p> -->
                <p>{{ text.thirdParagraph[ language ] }}</p>

            </div>

            <form [formGroup]="feedbackForm" (ngSubmit)="submitForm()">
                <div class="form-group ">
                    <div class="d-flex w-100 justify-content-between">
                        <label class="my-auto" style="margin-top: 10px;" for="rating">{{ text.starRating[ language ] }}</label>
                        <div class="rating my-auto" >
                            <span *ngFor="let star of stars; let i = index" (click)="setRating(i + 1)"
                                [ngClass]="{'active': i < selectedRating, 'rated': i + 1 <= selectedRating} "
                                >
                                &#9733;
                            </span>
                          </div>
                          <div style="display: inline;margin-top: 2.5rem; width: unset;" [hidden] ="!feedbackForm.get('rating').invalid || !feedbackForm.get('rating').touched"
                           class="invalid-feedback position-absolute">{{ text.starRatingError[language] }}</div>      
                          
                    </div>
                    
                </div>
                <div class="form-group">
                    <label for="comments">{{ text.freeText[ language ] }}
                    </label>
                    <textarea class="form-control" id="comments" formControlName="comments"></textarea>
                    

                </div>
                <!-- <div class="form-group custom-checkbox">
                    <label class="form-check-label">
                      <input
                      class="d-none"
                        type="checkbox"
                        class="form-check-input"
                        formControlName="privacyPolicy"
                        required
                      />
                      
                      <span class="custom-checkbox-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="white" class="bi bi-check" viewBox="0 0 16 16">
                              <path d="M4.293 9.293a1 1 0 0 1 0-1.414L6.88 5.293a1 1 0 1 1 1.414 1.414l-3.586 3.586a1 1 0 0 1-1.414 0L1.293 8.293a1 1 0 1 1 1.414-1.414L4.293 9.293z"/>
                            </svg>                  
                      </span>
                      I agree to the privacy policy

                    </label>
                  </div> -->
                <!-- <button type="submit" class="btn btn-primary d-block mx-auto" [disabled]="feedbackForm.invalid">Submit</button> -->
                <button class="btn m-5 px-5 d-block mx-auto" type="submit" 
                [ngClass] = "submitBtnClass" [disabled]="submitted"
                style="font-family: 'Comfortaa', sans-serif; text-transform: uppercase;">
                <div>{{submitBtnText}}
                  <svg *ngIf="!spinnerHidden" class="spinner" viewBox="0 0 50 50">
                    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                  </svg>
                </div>
              </button>
            </form>
        </div>
    </div>
</div>