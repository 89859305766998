import { gql } from 'apollo-angular';

export const queryAttributes = (type: string) => {
  return gql`
  query {
    attr(data: { type: "${type}" }) {
        id
        type
        en
        hu
    }
  }
`
};


export const EVENT_LOG = gql`{
  eventLog{
    id
    eventData
    createdAt
  }
}`;


export const QUERY_CATEGORIES = gql`{
    uniqueCatMeta{name, testid{
     metaLanguage
      }
    }
  }`;

export const QUERY_PERSONAL_FIELDS = gql`{
	personalFields{
		id
		name
		en
		hu
		orderNumber
		isOptional
	}
}`;

export const QUERY_USER = gql`{
  userInf {
		id
		email
		firstName
		lastName
		city
		address
		zipCode
		streetName
		houseNumber
    occupationid{
			id
			en
			hu
		}
		countryid{
			id
			en
			hu
		}
    languageid{
			id
			en
			hu
		}
		uRoleId
		tRoleId
	}
}`
// console.log(`{
//   userInf {
// 		id
// 		email
// 		firstName
// 		lastName
// 		city
// 		address
// 		zipCode
// 		streetName
// 		houseNumber
//     occupationid{
// 			id
// 			en
// 			hu
// 		}
// 		countryid{
// 			id
// 			en
// 			hu
// 		}
//     languageid{
// 			id
// 			en
// 			hu
// 		}
// 		uRoleId
// 		tRoleId
// 	}
// }`)
;

export function queryTestsByIdArray(ids: number[]) {
  console.log(`{
    testsById(data: {
      id: [ ${ids} ]
    })
  {
    id
    nameId 
    nameid { en hu }
    shortName
    instructions
    questions
    }
  }`)

  return gql`{
    testsById(data: {
      id: [ ${ids} ]
    })
  {
    id
    nameId 
    nameid { en hu }
    shortName
    instructions
    questions
    }
  }`
}

export function fetchAssessmentByLink(link: string) {
  // console.log(
  //   `query {
  //     assessmentsByLink(data: {
  //       link: [ "${link}" ] 
  //     }) {
  //       id
  //       language
  //       testsSetsId {
  //         testsid {
  //           id
  //           nameid{
  //             id
  //             en
  //             hu
  //           }
  //           instructions
  //           questions
  //         }
  //       },
  //       personalFields {
  //           name
  //       }
  //     } 
  //   }`
  // )
  return gql`query {
    assessmentsByLink(data: {
      link: [ "${link}" ] 
    }) {
      id
      language
      testsSetsId {
        testsid {
          id
          nameid{
            id
            en
            hu
          }
          instructions
          questions
        }
      },
      personalFields {
          name
      }
    } 
  }`
}

export const QUERY_TESTS = gql`{
  tests{
    id
    questions
    nameid{
      id
      en
      hu				
    }
    languageid{
      id
      en
      hu
    }
    descriptionid{
      id
      en
      hu
    }
    testsCategorySetsId{
      attributesid {
        id
        type
        en
        hu
      }
    }
    instructions
    author
    shortName
    publicationYear
    link
  }
  }`;

export const QUERY_TESTS_QUESTIONS = gql`{
  tests{
    id
    name
    testInstructions
    questions
  }
}`;

