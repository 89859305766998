export const text = {
    "title": {
        "en": "Feedback",
        "hu": "Visszajelzés küldése"
    },
    "firstParagraph": {
        "en": "Your feedback is vital for improving the site, finding bugs and enhancing the user experience in general.",
        "hu": "Visszajelzése nagyon fontos az oldal fejlesztéséhez, a hibák kijavításához és a felhasználói élmény javításához."
    },
    "secondParagraph": {
        "en": "Whether it's pointing out an error, suggesting a feature or telling us about annoying site behavior - thanks for sharing your insights.",
        "hu": "Köszönjük, hogy megosztja a honlap működésével, használhatóságával, a felmerülő hibákkal kapcsolatos észrevételeit"
    },
    "thirdParagraph": {
        "en": "Any information you submit here will be sent directly to the Menta development team and won't be shared with your therapist.",
        "hu": "Az itt megadott információkat közvetlenül a Menta fejlesztőcsapatának küldjük el, nem osztjuk meg terapeutájával."
    },
    "starRating": {
        "en": "Overall rating",
        "hu": "Általános értékelés"
    },
    "freeText": {
        "en": "Notes",
        "hu": "Észrevételek"
    },
    "starRatingError": {
        "en": "Please, give an overall rating.",
        "hu": "Kérjük, adjon egy általános értékelést."
    },
    "submit": {
        "en": "Submit",
        "hu": "Küldés"
    },
    "submitting": {
        "en": "Submitting",
        "hu": "Küldés..."
    },
    "submissionSuccessful": {
        "en": "Submission successful",
        "hu": "Sikeres beküldés"
    },
    "submissionFailed": {
        "en": "Submission failed",
        "hu": "Sikertelen beküldés"
    },
}