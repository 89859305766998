import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Apollo } from "apollo-angular";
import { QUERY_TESTS } from "src/app/graphql/queries";
import { HttpHeaders } from '@angular/common/http';

interface TestProperties {
  id?: number;
  name?: string;
  categories?: string[];
  language?: any;
  alphaCoeff: number;
  author?: string;
  publicationYear?: number;
  fillOutTime?: number;
  numberOfItems: number,
  link?: string;
  description?: any;
  checked?: boolean;
  rowClass?: string;
  shortName?: string;
}

@Injectable({
  providedIn: 'root'
})
export class TestsService {

  constructor(private apollo: Apollo) {
    // console.log('testproperties service')
    this.test$ = this.getTestProperties();
  }

  public test$: Observable<TestProperties[]>;

  public getTestProperties(): Observable<TestProperties[]> {
    // console.log('getTestProperties')
    return this.apollo
      .watchQuery({
        query: QUERY_TESTS,
        context: {
          headers: new HttpHeaders({
            "atk": localStorage.getItem('token') === null ? '' : localStorage.getItem('token')
          })
        }
      })
      .valueChanges
      .pipe(map(({ data, loading }) => this.mapResult(data['tests'])));
  }

  private mapResult(response): TestProperties[] {
    const testPropertiesArray: TestProperties[] = [];
    // console.log(response);
    response.forEach((element, index) => {
      // let categories = [];
      // element.categories.forEach((element) => {
      //   categories.push(element.name)
      // })
      let count = 0
      element.questions.forEach(element => {
        element.subQuestions.forEach(element => {
          count++
        });
      });
      
      // if ([70].includes(Number(element.id))) {
      //     console.log(element)
      //   }

      testPropertiesArray.push({
        id: Number(element.id),
        name: element.nameid,
        categories: element.testsCategorySetsId,
        language: element.languageid,
        author: element.author,
        publicationYear: element.publicationYear,
        fillOutTime: Math.ceil(count * .2 + .001),
        numberOfItems: [68,69].includes(Number(element.id))? 1 : count,
        alphaCoeff: 0,
        link: element.link,
        description: element.descriptionid,
        shortName: element.shortName,
        checked: false,
        rowClass: ''
      });
    })
    // console.log('tests',testPropertiesArray)
    return testPropertiesArray;
  }
}
