import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sort'
})
export class SortPipe implements PipeTransform {
  transform(array: any[], property:any): any[] {
    if (!Array.isArray(array)) {
      return array;
    }
    if(property || array.hasOwnProperty(property)){
    return array.sort((a, b) => {
      const valueA = a[property].toLocaleString('hu', { sensitivity: 'base' });
      const valueB = b[property].toLocaleString('hu', { sensitivity: 'base' });

      return valueA.localeCompare(valueB, 'hu');
    });
  }else{
    return array.sort((a, b) => {
      const valueA = a.toLocaleString('hu', { sensitivity: 'base' });
      const valueB = b.toLocaleString('hu', { sensitivity: 'base' });

      return valueA.localeCompare(valueB, 'hu');
    });
  }
}
}
