import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { CollapseModule } from 'ngx-bootstrap/collapse';

import { RouterModule } from "@angular/router";
import { PresentationComponent } from "./presentation.component";
// import { PerfectScrollbarModule } from "ngx-perfect-scrollbar";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { ComponentsModule } from "src/app/components/components.module";

@NgModule({
  declarations: [PresentationComponent],
  imports: [
    NgbModule,
    // PerfectScrollbarModule, 
    CommonModule, 
    RouterModule, 
    TooltipModule.forRoot(), BsDropdownModule.forRoot(), CollapseModule.forRoot(),
    ComponentsModule
  ]
})
export class PresentationModule {}
