<nav id="navbar-main" class="navbar navbar-horizontal navbar-transparent navbar-main navbar-expand-lg navbar-light">
  <div class="container">
    <a class="navbar-brand" [routerLink]="['/wizard']">
      <img src="assets/img/brand/menta-white-no-bg.png" />
    </a>
    <app-lang-switch class="d-lg-none"></app-lang-switch>
  
    <!-- <div class="nav-item dropdown d-inline-block d-lg-none"  [ngClass]="toggle">
      <a class="hitbox dropdown-toggle mr-md-2 text-sm text-white" id="lang-toggle" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="true" role="button" (click)="showHide()">
        {{currentLanguage}}
      </a>
      <div class="dropdown-menu  mt-3" [ngClass]="toggle" (click)="toggleLanguage(this.languageOption)">
          <a class="dropdown-item active text-center">
            {{displayLanguage}}
          </a>
      </div>
    </div> -->
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar-collapse"
      aria-expanded="false" aria-label="Toggle navigation" (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed" aria-controls="collapseBasic">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="navbar-collapse navbar-custom-collapse collapse" id="collapseBasic" [collapse]="isCollapsed">
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a [routerLink]="['/wizard']">
              <img src="assets/img/brand/menta.png" />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button type="button" class="navbar-toggler" data-toggle="collapse" data-target="#navbar-collapse"
              aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation"
              (click)="isCollapsed = !isCollapsed">
              <span></span> <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/login']"
          (click)="isCollapsed = !isCollapsed">
            <span class="nav-link-inner--text">{{"Bejelentkezés"|translate}}</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/signup']"
          (click)="isCollapsed = !isCollapsed">
            <span class="nav-link-inner--text">{{"Regisztráció"|translate}}</span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" [routerLink]="['/presentation']"
          (click)="isCollapsed = !isCollapsed">
            <span class="nav-link-inner--text">{{"Bemutatkozás"|translate}}</span>
          </a>
        </li>
      </ul>
      <hr class="d-lg-none" />
      <app-lang-switch></app-lang-switch>

      <!-- <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item dropdown " [ngClass]="toggle">
          <div class="hitbox dropdown-toggle mr-md-2 text-sm" id="lang-toggle" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="true" role="button" (click)="showHide()">
            {{currentLanguage}}
        </div>
          <div class="dropdown-menu  mt-3" [ngClass]="toggle" (click)="toggleLanguage(this.languageOption)">
              <a class="dropdown-item active text-center">
                {{displayLanguage}}
              </a>
          </div>
        </li>
      </ul> -->
    </div>
  </div>
</nav>
<router-outlet></router-outlet>
<!-- <footer class="py-5">
  <div class="container">
    <div class="row align-items-center justify-content-xl-between">
      <div class="col-xl-6">
        <div class="copyright text-center text-xl-left text-muted">
          &copy; {{ test | date: "yyyy" }}
          <a [routerLink]="['/presentation']" class="font-weight-bold ml-1"
            target="_blank">menta</a>
        </div>
      </div>
      <div class="col-xl-6">
        <ul class="nav nav-footer justify-content-center justify-content-xl-end">
          <li class="nav-item">
            <a [routerLink]="['/presentation']" class="nav-link"
              target="_blank">{{"Rólunk"|translate}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer> -->