<!-- Screen when loading assessment runs into an error -->
<div *ngIf="getAssessmentError" class="container" style="min-height: 100vh;">
  <div class="card" style="margin:0 auto;" style="min-height: 100vh !important; ">
    <div class="card-header bg-primary">
      <div class="text-center title_ text-white mb-0 font-weight-bold text-uppercase">Assessment error
      </div>
    </div>
    <div class="card-body mx-auto" style="max-width:600px;min-height:100vh;">
      <div class="my-auto">
        <div class="welcome">
          We can't seem to find your assessment. Please, try again or get in touch with your therapist to get a new
          link.
        </div>
        <br>
        <div class="welcome">
          Nem találjuk a felmérőt, amit keresel. Kérjük, próbáld újra, vagy keresd fel a terapeutádat egy új linkért.
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Assessment loading screen -->
<div *ngIf="!(store.assessmentLoaded)" class="container">
  <div class="card m-auto " style="height: 96vh;">
    <div class="m-auto">
      <app-loader style="width: 120px; display: block;"></app-loader>
    </div>
  </div>
</div>
<!-- Assessment successfully loaded -->
<ng-container *ngIf="(store.assessmentLoaded) && !getAssessmentError">
  <app-options [language]="store.language" (optionChangeEvent)="optionsChanged($event)"></app-options>
</ng-container>
<div *ngIf="(store.assessmentLoaded) && !getAssessmentError " class="container" style="min-height: 100vh;">
  <form (ngSubmit)="store.submitAssessment()">
    <div id="stage" class="card" style="margin:0 auto;min-height: 96vh; margin-bottom: 16px;">
      <ng-container *ngIf="isThisOn('welcome')">
        <div class="card-header bg-primary">
          <div class="text-center title_ text-white mb-0 font-weight-bold text-uppercase">
            {{text.title.welcome[store.language]}}
          </div>
        </div>
        <div class="card-body mx-auto" style="max-width:600px;background-color:white;">
          <img class="card-img-top px-5" src="../../../assets/img/tutorial/welcome2.png">
          <!-- <hr> -->
          <div class="my-auto">
            <p class="welcome">{{ text.stage.welcome[store.language] }}</p>
            <p class="welcome">{{ text.stage.privacy[store.language] }}</p>
            <p class="welcome" *ngIf="completionText" [innerHTML]="completionText[store.language]"></p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isThisOn('personal')">
        <div class="card-header bg-primary">
          <div class="title_ text-center text-white mb-0 font-weight-bold text-uppercase">
            {{text.title.personal[store.language]}}
          </div>
        </div>
        <div [@fadeIn]="show" class="card-body mx-auto h-100" style="max-width: 600px;min-height:100%; width:90%;">
          <div class="p-1" *ngIf="store.anonymousCode">
            <p class="mb-0">{{ text.anon[store.language] }}</p>
            <div class="d-flex justify-content-center">
              <input class="round" maxlength="10" class="my-4" [disabled]="true" [(ngModel)]="store.anonymousCode"
                [ngModelOptions]="{standalone: true}" style="text-align: center ;
                padding-top:5px;
                font-weight: bold;
                font-family: Comfortaa;
                border-radius: 3px; border-width:1px;width:120px;height:2.5rem">
            </div>
          </div>
          <div class="p-1" *ngIf="store.personal.length==0">
            <p> {{ text.noPersonal[store.language] }}</p>
          </div>

          <fieldset [formGroup]="store.userForm">
            <div class="d-flex flex-sm-row flex-column justify-content-between" [ngClass]="language"
              *ngIf="store.personal.includes('name')">
              <div class="form-label-group w-100 p-1" style="margin-bottom: 1rem;">
                <input formControlName="firstName" type="text" id="firstName" class="form-control default-shadow"
                  [ngClass]="!firstName.touched ? '' : firstName.invalid ? 'is-invalid':''"
                  placeholder="{{ text.label.firstName[store.language] }}" style="height: 46px;" required>
                <label for="firstName">{{ text.label.firstName[store.language] }}</label>
                <div class="invalid-feedback position-absolute">{{ text.error.firstName[store.language] }}</div>
              </div>
              <div class="form-label-group  w-100 p-1" style="margin-bottom: 1rem;">
                <input formControlName="lastName" type="text" id="lastName" style="height: 46px;"
                  class="form-control default-shadow"
                  [ngClass]="!lastName.touched ? '' : lastName.invalid ? 'is-invalid':''"
                  placeholder="{{text.label.lastName[store.language]}}" required>
                <label for="lastName">{{ text.label.lastName[store.language] }}</label>
                <div class="invalid-feedback position-absolute">{{ text.error.lastName[store.language] }}</div>
              </div>
            </div>

            <div class="d-flex flex-sm-row flex-column justify-content-between w-100"
              *ngIf="store.personal.includes('sex')">
              <div class="input-group w-100 p-1" style="margin-bottom: 1rem;">
                <div class="input-group-prepend default-shadow" style="height: calc(1.5em + 1.25rem + 2px );">
                  <label class="input-group-text bg-secondary" style="color:#495057; " for="sex">{{
                    text.label.sex[store.language]
                    }}</label>
                </div>
                <select formControlName="sex" class="form-control default-shadow"
                  style="border-radius: 0 .25rem .25rem 0;"
                  [ngClass]="!sex.touched ? '' : sex.invalid ? 'is-invalid':''" id="sex" required="">
                  <option selected disabled value="">{{ text.choose[store.language] }}</option>
                  <option *ngFor="let sex of sexes[store.language];" [ngValue]="sex">{{sex}}
                  </option>
                </select>
                <div class="invalid-feedback position-absolute" style="margin-top: 4em;">{{
                  text.error.sex[store.language] }}</div>
              </div>

              <div class="input-group w-100 p-1">
                <!-- <ng-container *ngIf="sex.value=='Egyéb...'"> -->
                <div class="stretchy w-100" style="display: flex;"
                  *ngIf="sex.value=='Egyéb...' || sex.value=='Other...'">
                  <div class="form-label-group default-shadow w-100">
                    <input formControlName="specify" type="text" id="specify" class="form-control"
                      style="border-radius: .25rem; height: 46px;"
                      [ngClass]="!specify.touched ? '' : specify.invalid ? 'is-invalid':''">
                    <label for="specify">{{text.specify[store.language]}}</label>
                  </div>
                </div>
                <!-- </ng-container> -->
              </div>
            </div>
            <div class="form-label-group d-inline-block w-100 p-1" *ngIf="store.personal.includes('dob')"
              style="margin-bottom: 1rem;">
              <input formControlName="dob" type="text" id="dob" class="form-control default-shadow" autocomplete="off"
                [bsConfig]="{containerClass:'theme-default'}"
                [ngClass]="!dob.touched ? '' : dob.invalid ? 'is-invalid':''"
                placeholder="{{ text.personal.dob[language] }}" required bsDatepicker>
              <div class="invalid-feedback position-absolute">{{ text.error.dob[store.language] }}</div>
              <label for="dob">{{ text.label.dob[store.language] }}</label>
            </div>
            <div class=" d-inline-block w-100 p-1" *ngIf="store.personal.includes('nationality')">
              <div class="input-group" style="margin-bottom: 1rem;border-radius: 0 .25rem .25rem 0;">
                <div class="input-group-prepend default-shadow">
                  <label class="input-group-text bg-secondary" style="color:#495057;" for="nationality">{{
                    text.label.nationality[store.language] }}</label>
                </div>
                <select formControlName="nationality" class="form-control default-shadow"
                  [ngClass]="!nationality.touched ? '' : nationality.invalid ? 'is-invalid':''"
                  style="height: 3.125rem; border-radius: 0 .25rem .25rem 0;" id="nationality" required="">
                  <option value="" selected disabled>{{text.choose[store.language]}}</option>
                  <option *ngFor="let nationality of nationalities[store.language]| sort;" [ngValue]="nationality">
                    {{nationality}}
                  </option>
                </select>
                <div class="invalid-feedback position-absolute" style="margin-top: 4.25em;">{{
                  text.error.nationality[store.language] }}
                </div>
              </div>
            </div>
            <div class="form-label-group d-inline-block w-100 p-1" *ngIf="store.personal.includes('email')"
              style="margin-bottom: 1rem;">
              <input formControlName="email" type="email" id="email" class="form-control default-shadow"
                [ngClass]="!email.touched ? '' : email.invalid ? 'is-invalid':''"
                placeholder="{{ text.label.email[store.language] }}" required>
              <div class="invalid-feedback position-absolute">{{ text.error.email[store.language] }}</div>
              <label for="email">{{ text.label.email[store.language] }}</label>
            </div>
            <div class="form-label-group d-inline-block w-100 p-1" *ngIf="store.personal.includes('phone')"
              style="margin-bottom: 1rem;">
              <input formControlName="phone" type="text" pattern="[0-9]*" id="phone" class="form-control default-shadow"
                [ngClass]="!phone.touched ? '' : phone.invalid ? 'is-invalid':''"
                placeholder="{{ text.label.phone[store.language] }}" (keyup)="onlyNumbersAllowed()"
                (change)="onlyNumbersAllowed()" required>
              <div class="invalid-feedback position-absolute">{{ text.error.phone[store.language] }}</div>
              <label for="phone">{{ text.label.phone[store.language] }}</label>
            </div>
            <ng-container *ngIf="store.personal.includes('address')">
              <div class="form-label-group p-1" style="margin-bottom: 1rem;">
                <input formControlName="address" type="text" id="address" class="form-control default-shadow"
                  [ngClass]="!address.touched ? '' : address.invalid ? 'is-invalid':''"
                  placeholder="{{ text.label.address[store.language] }}" required>
                <div class="invalid-feedback position-absolute">{{ text.error.address[store.language] }}</div>
                <label for="address">{{ text.label.address[store.language] }}</label>
              </div>
              <div class="d-flex justify-content-between">
                <div class="form-label-group p-1" style="flex-basis: 95%; margin-bottom: 1rem;">
                  <input formControlName="city" type="text" id="city" class="form-control  default-shadow"
                    [ngClass]="!city.touched ? '' : city.invalid ? 'is-invalid':''"
                    placeholder="{{ text.label.city[store.language] }}" required>
                  <div class="invalid-feedback position-absolute">{{ text.error.city[store.language] }}</div>
                  <label for="city">{{ text.label.city[store.language] }}</label>
                </div>
                <div class="form-label-group p-1" style="margin-bottom: 1rem;">
                  <input formControlName="zip" type="text" id="zip" class="form-control default-shadow"
                    [ngClass]="!zip.touched ? '' : zip.invalid ? 'is-invalid':''"
                    placeholder="{{ text.label.zip[store.language] }}" required>
                  <div class="invalid-feedback position-absolute">{{ text.error.zip[store.language] }}</div>
                  <label for="zip">{{ text.label.zip[store.language] }}</label>
                </div>
              </div>
              <div class="input-group mb-3 p-1" style="margin-bottom: 1rem;">
                <div class="input-group-prepend default-shadow ">
                  <label class="input-group-text bg-secondary" style="color:#495057; " for="country">{{
                    text.label.country[store.language] }}</label>
                </div>
                <select formControlName="country" class="form-control default-shadow"
                  [ngClass]="!country.touched ? '' : country.invalid ? 'is-invalid':''"
                  style="height: 3.125rem;border-radius: 0 .25rem .25rem 0;" id="country" required="">
                  <option value="" selected disabled>{{ text.choose[store.language] }}</option>
                  <option *ngFor="let country of countries[store.language] | sort;" [ngValue]="country">{{country}}
                  </option>
                </select>
                <div class="invalid-feedback position-absolute" style="margin-top: 4.25em;">{{
                  text.error.country[store.language] }}</div>
              </div>
            </ng-container>
          </fieldset>
        </div>
        <div class="h-100"></div>

      </ng-container>
      <ng-container *ngIf="isThisOn('question')">
        <div style="min-height: 100%;" *ngIf="!store.isCustomTest"><!--  min-height: 90.7vh; -->
          <div [@fadeIn]="show" class="card-body question-body mx-auto" style=" max-width: 600px; padding:0;">
            <div class="instructions mt-4 mx-4" *ngIf="store.test.instructions">
              <div [innerHTML]="store.test.instructions"></div>
            </div>
            <div class="px-4 my-4">
              <div style="display: flex;" class="question" *ngIf="store.question.questionText">
                {{store.questionIndex+1}}.
                <div style="margin-left:.8rem"
                  [innerHTML]="sanitizer.bypassSecurityTrustHtml(store.question.questionText)">
                </div>
              </div>
              <div style="display: flex;" class="sub-question" *ngIf="store.subQuestion.subQuestionText">
                {{store.subQuestionIndex+1}}.
                <div style="margin-left:.8rem"
                  [innerHTML]="sanitizer.bypassSecurityTrustHtml(store.subQuestion.subQuestionText)"></div>
              </div>
            </div>
            <div class="row m-0 jusify-items-center">
              <div class="col-12 col-md-8  m-auto align-items-center p-0" *ngIf="store.testDataForm">
                <fieldset [formGroup]="store.testDataForm.controls[store.test.id].controls[store.question.id]">
                  <div *ngFor="let option of store.options; let i = index;" class="option">
                    <input #linkRef id="{{option.id}}" type="radio" value="{{option.id}}"
                      formControlName={{store.subQuestion.id}}>
                    <label for="{{option.id}}" (click)="optionClicked(linkRef)">
                      <p>{{option.optionText}}</p>
                    </label>
                    <div class="check" style="cursor: pointer;" (click)="optionClicked(linkRef)"></div>
                  </div>
                </fieldset>
                <!-- <div style="background-color:white;height: 80px;"></div> -->
              </div>
            </div>
          </div>
        </div>
        <!-- English BMI -->
        <div style="min-height: 100%;" *ngIf="store.isCustomTest && store.test['id']=='68'">
          <div [@fadeIn]="show" class="card-body question-body mx-auto" style=" max-width: 600px; padding:0;">
            <form [formGroup]="store.bmiForm" class="mt-4 p-4">
              <div class="row mt-4 justify-items-center">
                <h2 class="col-12">Body Mass Index</h2>
                <p class="col-12"> Please, enter your personal weight and height measurements.</p>
              </div>
              <div class="row p-4">
                <div class="col-12 pb-4">
                  <label for="weight">Weight (kg):</label>
                  <div class="position-relative">
                    <input type="number" id="weight" formControlName="weight" class="default-shadow form-control col-12"
                      [ngClass]="!store.bmiForm.get('weight').touched ? '' : store.bmiForm.get('weight').invalid ? 'is-invalid':''"
                      placeholder="Enter weight in kilograms">
                    <span class="unit">kg</span>
                  </div>
                </div>
              </div>
              <div class="row pl-4 mb-4 position-absolute"
                *ngIf="store.bmiForm.get('weight').invalid && store.bmiForm.get('weight').touched">
                <div class="col-12 text-sm text-danger" *ngIf="store.bmiForm.get('weight').hasError('required')">Weight
                  is
                  required.</div>
                <div class="col-12 text-sm text-danger"
                  *ngIf="store.bmiForm.get('weight').hasError('min') || store.bmiForm.get('weight').hasError('max')">
                  Weight must be
                  a positive number under 300.</div>
              </div>

              <div class="row p-4">
                <div class="col-12 pb-4">
                  <label for="height">Height (cm):</label>
                  <div class="position-relative">
                    <input type="number" id="height" formControlName="height" class="default-shadow form-control col-12"
                      [ngClass]="!store.bmiForm.get('height').touched ? '' : store.bmiForm.get('height').invalid ? 'is-invalid':''"
                      placeholder="Enter height in centimeters">
                    <span class="unit">cm</span>
                  </div>
                </div>
              </div>
              <div class="row pl-4 mb-4 position-absolute"
                *ngIf="store.bmiForm.get('height').invalid && store.bmiForm.get('height').touched">
                <div class="col-12 text-sm text-danger" *ngIf="store.bmiForm.get('height').hasError('required')">Height
                  is
                  required.</div>
                <div class="col-12 text-sm text-danger"
                  *ngIf="store.bmiForm.get('height').hasError('min') || store.bmiForm.get('height').hasError('max')">
                  Height must be
                  a positive number under 500.</div>
              </div>
            </form>
          </div>
        </div>
        <!-- Hungarian BMI -->
        <div style="min-height: 100%;" *ngIf="store.isCustomTest && store.test['id']=='69'">
          <div [@fadeIn]="show" class="card-body question-body mx-auto" style=" max-width: 600px; padding:0;">
            <form [formGroup]="store.bmiForm" class="mt-4 p-4">
              <div class="row mt-4 justify-items-center">
                <h2 class="col-12">Testtömeg Index</h2>
                <p class="col-12">Kérjük, adja meg az adatait.</p>
              </div>
              <div class="row p-4">
                <div class="col-12 pb-4">
                  <label for="weight">Testsúly (kg):</label>
                  <div class="position-relative">
                    <input type="number" id="weight"
                      [ngClass]="!store.bmiForm.get('weight').touched ? '' : store.bmiForm.get('weight').invalid ? 'is-invalid':''"
                      formControlName="weight" class="default-shadow form-control col-12"
                      placeholder="Testúly megadása kilogramban">
                    <span class="unit">kg</span>
                  </div>
                </div>
              </div>
              <div class="row pl-4 mb-4 position-absolute"
                *ngIf="store.bmiForm.get('weight').invalid && store.bmiForm.get('weight').touched">
                <div class="col-12 text-sm text-danger" *ngIf="store.bmiForm.get('weight').hasError('required')">
                  Testsúly
                  megadása kötelező.</div>
                <div class="col-12 text-sm text-danger"
                  *ngIf="store.bmiForm.get('weight').hasError('min') || store.bmiForm.get('weight').hasError('max')">A
                  testsúlynak
                  0-500 közötti pozitív számnak kell lennie.</div>
              </div>

              <div class="row p-4">
                <div class="col-12 pb-4">
                  <label for="height">Testmagasság (cm):</label>
                  <div class="position-relative">
                    <input type="number" id="height" formControlName="height" class="default-shadow form-control col-12"
                      placeholder="Testmagasság megadása centiméterben">
                    <span class="unit">cm</span>
                  </div>
                </div>
              </div>
              <div class="row pl-4 mb-4 position-absolute"
                *ngIf="store.bmiForm.get('height').invalid && store.bmiForm.get('height').touched">
                <div class="col-12 text-sm text-danger" *ngIf="store.bmiForm.get('height').hasError('required')">
                  Testmagasság
                  megadása kötelező.</div>
                <div class="col-12 text-sm text-danger"
                  *ngIf="store.bmiForm.get('height').hasError('min') || store.bmiForm.get('height').hasError('max')">A
                  testmagasságnak 0-300 közötti pozitív számnak kell lennie.</div>
              </div>
            </form>
          </div>
        </div>
        <!-- <div class="h-100"></div> -->
      </ng-container>
      <ng-container *ngIf="isThisOn('submit')">
        <div class="card-header title_ text-center bg-primary text-white font-weight-bold text-uppercase">
          {{text.title.submit[store.language]}}
        </div>
        <div class="card-body mx-auto d-block" style="max-width: 600px; padding:0; ">
          <div class="d-flex flex-column align-items-center justify-content-center" style="height: 75vh;">
            <h1 class="mx-auto p-4">{{ text.stage.completion[ store.language ] }}
            </h1>
            <p class="mx-auto p-4">{{ text.stage.submit[ store.language ] }}
            </p>
            <button class="btn m-5 px-5" type="submit" [ngClass]="store.submitBtnClass"
              [disabled]="store.assessmentSubmitted"
              style="font-family: 'Comfortaa', sans-serif; text-transform: uppercase;">
              <div>{{store.submitBtnText}}
                <svg *ngIf="!store.spinnerHidden" class="spinner" viewBox="0 0 50 50">
                  <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="5"></circle>
                </svg>
              </div>
            </button>
            <p class="mx-auto feedback" (click)="goToFeedback()" >
              {{ text.feedback[ store.language ] }}
            </p>
          </div>
        </div>
      </ng-container>
      <!-- <div class="mt-auto pt-5"> -->
      <div class="d-flex "
        style="background-color:white;border-bottom-left-radius:0.375rem;border-bottom-right-radius:0.375rem;  ">
        <div [style.visibility]="store.step==0?'hidden':'visible'" (click)="previous()" class="back p-3 w-50">
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left"
            class="svg-inline--fa fa-chevron-left fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512">
            <path fill="currentColor"
              d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z" />
          </svg>
          <div class="my-auto mx-2 title_ text-uppercase">{{text.navigation.previous[store.language]}}</div>
        </div>
        <div class="page text-center">{{store.currentPage}}/{{store.totalPages}}</div>
        <div [style.visibility]="store.step==3?'hidden':'visible'" (click)="next()"
          class="next p-3 w-50 justify-content-end">
          <div class="my-auto mx-2 title_ text-uppercase">{{text.navigation.next[store.language]}}</div>
          <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right"
            class="svg-inline--fa fa-chevron-right fa-w-10" role="img" xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320 512">
            <path fill="currentColor"
              d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
          </svg>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </form>
</div>