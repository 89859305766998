import { Injectable } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AssessmentService } from 'src/app/services/assessment.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { huLocale, enGbLocale } from 'ngx-bootstrap/locale';
import { BsLocaleService } from 'ngx-bootstrap/datepicker';
import { ApolloError } from '@apollo/client';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class ProgressService {

  constructor(
    private fb: UntypedFormBuilder,
    private a: AssessmentService,
    private localeService: BsLocaleService,
    private apollo: Apollo,
  ) {
    console.log('progress service constructor!')
    defineLocale('hu-hu', huLocale);
    defineLocale('en-gb', enGbLocale);

  }
  // *** ASSESSMENT *** //
  id: string;
  anonymousCode: string;
  completionTime: number;
  completionText: { en: string; hu: string; };
  submitBtnText; submitBtnClass;
  assessmentSubmitted;
  assessment$; assessment;
  public personal;
  getAssessmentError = false;
  spinnerHidden = true;

  // *** LANGUAGE *** //
  language: string;
  // *** FORMS *** //
  userForm: UntypedFormGroup;
  testDataForm: UntypedFormGroup;
  bmiForm: UntypedFormGroup;
  // *** TEST *** //
  test; tests;
  testIndex: number;
  questionIndex: number;
  subQuestionIndex: number;
  isCustomTest = false;
  question; subQuestion; options;
  index: number[];
  // *** PAGINATION *** //
  currentPage: number;
  step: number;
  pageNum: number;
  pages; totalPages;

  assessmentLoaded;

  private _errors: BehaviorSubject<string> = new BehaviorSubject(null);
  public readonly errors: Observable<string> = this._errors.asObservable();

  labels = {
    "submitButtonText": {
      "en": "Submit",
      "hu": "Küldés"
    },
    "submitting": {
      "en": "Submitting...",
      "hu": "Küldés..."
    },
    "submitSuccessText": {
      "en": "Submission successful",
      "hu": "Sikeres beküldés"
    },
    "submitFailureText": {
      "en": "Submission unsuccessful",
      "hu": "Sikertelen küldés"
    }
  }

  get firstName() { return this.userForm.get('firstName'); }
  get lastName() { return this.userForm.get('lastName'); }
  get email() { return this.userForm.get('email'); }
  get phone() { return this.userForm.get('phone'); }
  get dob() { return this.userForm.get('dob'); }
  get sex() { return this.userForm.get('sex'); }
  get specify() { return this.userForm.get('specify'); }
  get address() { return this.userForm.get('address'); }
  get zip() { return this.userForm.get('zip'); }
  get city() { return this.userForm.get('city'); }
  get country() { return this.userForm.get('country'); }
  get nationality() { return this.userForm.get('nationality'); }

  initializeForms() {
    this.testDataForm = new UntypedFormGroup({});
    this.userForm = this.fb.group({});
    this.bmiForm = this.fb.group({
      weight: ['', [Validators.required, Validators.min(0), Validators.max(500)]],
      height: ['', [Validators.required, Validators.min(0), Validators.max(300)]]
    });
  };

  loadVariables() {
    // ** PAGINATION
    this.pageNum = 0;
    this.currentPage = 1; this.totalPages = 0;
    this.step = 0;
    // ** TEST
    this.isCustomTest = false;
    this.testIndex = 0;
    this.questionIndex = 0;
    this.subQuestionIndex = 0;
    this.index = [0, 0, 0];

    this.test = { questions: [] };
    this.question = { subQuestions: [] };
    this.subQuestion = { options: [] };
    this.options = [];
    // ** ASSESSMENT
    this.completionTime = Math.ceil(this.a.assessment.totalCount / 6);

    this.completionText = {
      en: `<div>Expected time for completion is around <span class="font-weight-bold text-nowrap">${this.completionTime} minute${this.completionTime != 1 ? 's' : ''}</span></div>`,
      hu: `<div>A várható kitöltési idő: <span class="font-weight-bold text-nowrap">${this.completionTime} perc</span></div>`
    };
    this.assessmentSubmitted = false;
    this.language = this.a.assessment.language;
    this.localeService.use(this.language == 'en' ? 'en-gb' : 'hu-hu');

    this.tests = this.a.assessment.tests;
    this.pages = this.a.assessment.pages;
    this.totalPages = this.pages.length + 3;

    this.getCurrentQuestion(this.pageNum)

    // this.submitBtnText = this.text.navigation.submitButtonText[this.language];
    this.submitBtnClass = "btn-outline-primary"
    this.submitBtnText = this.labels.submitButtonText[this.language];

  };

  async loadAssessment(id: string, anon: string) {
    console.log('id:', id)
    console.log('anon:', anon)
    this.assessmentLoaded = false;

    if (id != this.id || this.anonymousCode != anon) {
      this.id = id;
      this.anonymousCode = anon;

      console.log('new assessment id or anonymous id!')
      console.log('reset progress!')

      try {
        this.a.load(id,
          () => {
            console.log('callback after assessment loaded')
            this.loadVariables();
            this.initializeForms();
            this.testDataForm = this.generateTestForm();
            this.generatePersonalForm();
            this.assessmentLoaded = true;
          });
      } catch (error) {
        console.log('progress.service error', error);
        this.getAssessmentError = true;
        this._errors.next(error);
      }
    } else {
      console.log('keep progress!')
      this.assessmentLoaded = true;
    }
  }

  private generateTestForm(): UntypedFormGroup {
    let testGroup = {}
    this.tests.forEach(test => {
      // check if custom test not fitting the template
      if (![68, 69].includes(Number(test.id))) {
        let questionGroup = {}
        test.questions.forEach(question => {
          let subquestionGroup = {}
          question.subQuestions.forEach(subquestion => {
            subquestionGroup[subquestion.id] = new UntypedFormControl('', [Validators.required]);
          })
          questionGroup[question.id] = new UntypedFormGroup(subquestionGroup);
        })
        testGroup[test.id] = new UntypedFormGroup(questionGroup);
      }
    })
    return new UntypedFormGroup(testGroup)
  }

  generatePersonalForm() {
    this.personal = this.a.assessment.personalFields;
    if (this.personal.includes('name')) {
      this.userForm.addControl('firstName', new UntypedFormControl('', [Validators.required]));
      this.userForm.addControl('lastName', new UntypedFormControl('', [Validators.required]));
    }
    if (this.personal.includes('sex')) {
      this.userForm.addControl('sex', new UntypedFormControl('', [Validators.required]));
      this.userForm.addControl('specify', new UntypedFormControl('', this.requiredIfValidator()));
      this.sex.valueChanges
        .subscribe(value => {
          this.specify.updateValueAndValidity();
        });
    }
    if (this.personal.includes('dob')) {
      this.userForm.addControl('dob', new UntypedFormControl('', [Validators.required]));
    }
    if (this.personal.includes('nationality')) {
      this.userForm.addControl('nationality', new UntypedFormControl('', [Validators.required]));
    }
    if (this.personal.includes('phone')) {
      this.userForm.addControl('phone', new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]));
    }
    if (this.personal.includes('email')) {
      this.userForm.addControl('email', new UntypedFormControl('', [Validators.required, Validators.email]));
    }
    if (this.personal.includes('address')) {
      this.userForm.addControl('address', new UntypedFormControl('', [Validators.required]));
      this.userForm.addControl('zip', new UntypedFormControl('', [Validators.required]));
      this.userForm.addControl('city', new UntypedFormControl('', [Validators.required]));
      this.userForm.addControl('country', new UntypedFormControl('', [Validators.required]));
    }
  }

  requiredIfValidator() {
    return (formControl => {
      // console.log(this.sex.value)
      if (!formControl.parent) {
        return null;
      }
      if (this.sex.value == 'Other...' || this.sex.value == 'Egyéb...') {
        return Validators.required(formControl);
      }
      return null;
    })
  }

  getCurrentQuestion(page) {
    this.testIndex = this.pages[page][0]
    this.test = this.tests[this.testIndex]
    this.isCustomTest = [68, 69].includes(Number(this.test['id']));

    this.questionIndex = this.pages[page][1]
    this.subQuestionIndex = this.pages[page][2]
    this.test.questions[this.questionIndex] ? this.question = this.test.questions[this.questionIndex] : null;
    this.question.subQuestions[this.subQuestionIndex] ? this.subQuestion = this.question.subQuestions[this.subQuestionIndex] : null;
    this.subQuestion.options ? this.options = this.subQuestion.options : null
  }

  private getScoreForOptionId = (testId, questionId, subQuestionId, optionId) => {
    let test = this.tests.find(test => test.id.toString() === testId.toString());
    let question = test.questions.find(question => question.id.toString() === questionId.toString());
    let subQuestion = question.subQuestions.find(subQuestion => subQuestion.id.toString() === subQuestionId.toString());
    let option = subQuestion.options.find(option => option.id.toString() === optionId.toString());
    return parseInt(option.score);
  }

  private transformPersonalForm = (personalData) => {
    let personal = {};
    if (personalData.hasOwnProperty('firstName') && personalData.hasOwnProperty('lastName')) {
      personal['name'] = this.language == 'en' ? `${personalData.firstName} ${personalData.lastName}` : `${personalData.lastName} ${personalData.firstName}`
    }
    if (personalData.hasOwnProperty('dob')) personal['dob'] = new Date(personalData.dob).toLocaleDateString(this.language == 'en' ? 'en-GB' : 'hu-HU')
    if (personalData.hasOwnProperty('email')) personal['email'] = personalData.email
    if (personalData.hasOwnProperty('nationality')) personal['nationality'] = personalData.nationality
    if (personalData.hasOwnProperty('phone')) personal['phone'] = personalData.phone
    if (personalData.hasOwnProperty('sex')) {
      if (personalData.sex == 'Other...') {
        personal['sex'] = 'Other - ' + personalData.specify
      } else if (personalData.sex == 'Egyéb...') {
        personal['sex'] = 'Egyéb - ' + personalData.specify
      } else { personal['sex'] = personalData.sex }
    }
    if (personalData.hasOwnProperty('address') &&
      personalData.hasOwnProperty('city') &&
      personalData.hasOwnProperty('zip') &&
      personalData.hasOwnProperty('country')) {
      personal['address'] = `${personalData.zip} ${personalData.city}, ${personalData.address} ${personalData.country.toUpperCase()}`
    }
    if (this.anonymousCode) personal['anonymous'] = this.anonymousCode;
    return personal
  }
  private translateResults = (testData) => {
    // Translate the Form result structure to the format required by submitAssessment.

    let tests = [];
    Object.keys(testData).forEach(testId => {

      let questions = [];
      Object.keys(testData[testId]).forEach(questionId => {

        let subQuestions = [];
        Object.keys(testData[testId][questionId]).forEach(subQuestionId => {
          subQuestions.push({
            subQuestionId: parseInt(subQuestionId),
            optionId: parseInt(testData[testId][questionId][subQuestionId]),
            score: this.getScoreForOptionId(testId, questionId, subQuestionId, testData[testId][questionId][subQuestionId])
          })
        })
        questions.push({
          questionId: parseInt(questionId),
          subQuestions: subQuestions
        })
      })
      tests.push({
        testId: parseInt(testId),
        questions: questions
      })
    })
    return tests
  }

  private removeEmptyStrings = (personalData) => {
    let nonEmpties = {};
    for (const [key, value] of Object.entries(personalData)) {
      if (value != "") nonEmpties[key] = value;
    }
    return nonEmpties
  }

  submitAssessment = () => {
    console.log('submit!')
    this.spinnerHidden = false;
    this.assessmentSubmitted = true;
    this.submitBtnText = this.labels.submitting[this.language];

    let testData = this.translateResults(this.testDataForm.value);
    if (this.bmiForm.value['weight'] != '') {
      let bmiData = {
        testId: 68,
        weight: this.bmiForm.value['weight'],
        height: this.bmiForm.value['height']
      }
      testData = [...testData, bmiData]
    }
    let personalData = this.removeEmptyStrings(this.transformPersonalForm(this.userForm.value));
    // console.log()
    console.log(`
mutation {
  submitMail(
    assaId: ${this.a.assessment['id']}
    lang: "en"
    data: {
      personalData: ${JSON.stringify(personalData).replace(/"([^"]+)":/g, '$1:').replace(/\uFFFF/g, '\\\"')}
      testData: ${JSON.stringify(testData).replace(/"([^"]+)":/g, '$1:').replace(/\uFFFF/g, '\\\"')}
  })
  }`)

    this.apollo.mutate({
      mutation: gql`
      mutation {
        submitMail(
          assaId: ${this.a.assessment['id']}
          lang: "en"
          data: {
            personalData: ${JSON.stringify(personalData).replace(/"([^"]+)":/g, '$1:').replace(/\uFFFF/g, '\\\"')}
            testData: ${JSON.stringify(testData).replace(/"([^"]+)":/g, '$1:').replace(/\uFFFF/g, '\\\"')}
        })
        }`
    }).subscribe(
      {
        next: (response) => {
          this.spinnerHidden = true;
          this.submitBtnClass = 'btn-outline-primary'
          this.submitBtnText = this.labels.submitSuccessText[this.language];
        },
        error: (e: ApolloError) => {
          this.spinnerHidden = true;
          this.submitBtnClass = 'btn-primary'
          this.submitBtnText = this.labels.submitButtonText[this.language];
          this.assessmentSubmitted = false;
          if (e.networkError['error'].hasOwnProperty('errors')) {
            console.log(e.networkError['error']['errors'][0]['message']);
            this._errors.next(e.networkError['error']['errors'][0]['message']);
          }
        }
      });
  }
}
