import { Inject, Injectable, NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule, Title } from '@angular/platform-browser';
import { Routes, RouterModule, RouteReuseStrategy, ActivatedRouteSnapshot, DetachedRouteHandle, TitleStrategy, RouterStateSnapshot  } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { PreviewComponent } from '../app/pages/preview/preview.component';
import { AssessmentComponent } from './pages/assessment/assessment.component';
import { FeedbackComponent } from './pages/feedback/feedback.component';
import { TranslateService } from '@ngx-translate/core';

@Injectable({providedIn: 'root'})
export class TemplatePageTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    public translate : TranslateService) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    console.log('routerState', routerState)
    if (title !== undefined) {
      this.title.setTitle(`[menta] ${this.translate.instant(title)}`);
    }
  }
}

const routes: Routes = [
  {
    path: '',
    redirectTo: 'wizard',
    pathMatch: 'full',

  },
  {
    path: 'assessment',
    component: AssessmentComponent,
    title: 'Online felémérés'
  },
  {
    path: 'feedback',
    component: FeedbackComponent,
    title: 'Visszajelzés'
  },
  {
    path: 'preview',
    component: PreviewComponent,
    title: 'Előnézet'
  },
  // {
  //   path: 'loader',
  //   component: LoaderComponent
  // },
  {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/layouts/admin-layout/admin-layout.module').then(m => m.AdminLayoutModule)
      }
    ]
  }, {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('src/app/layouts/auth-layout/auth-layout.module').then(m => m.AuthLayoutModule)
      }
    ]
  }, {
    path: '**',
    redirectTo: 'wizard'
  }
];

@NgModule({
  declarations:[],
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [],
  providers: [
  {provide: TitleStrategy, useClass: TemplatePageTitleStrategy},  
]
  
})

export class AppRoutingModule { 

}



