<div class="container-fluid" style="max-width: 1050px;">
    <div class="card my-4 mx-auto">
        <div class="card-header">
            <h2>{{"Ügyfelek felmérése a platform segítségével"|translate}}</h2>
        </div>
        <div class="card-body">
            <div style="max-width: 750px;" class="m-auto">
                <div class="row">
                    <div class="col-lg-6 col-12 my-lg-auto my-2 px-4">
                        <div class="d-flex justify-content-center mx-auto" style="max-width:400px">
                            <span class="font-weight-bold " style="font-size: 2em;">1.</span>
                            <p class="text-left pl-2 my-auto">{{"Készíts egy ingyenes fiókot"|translate}}</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12 text-center my-2 mx-auto">
                        <img src="../../../assets/img/tutorial/set-up-account.png" alt="" class="m-auto"
                            style="width: 250px; height: auto;">
                    </div>
                </div>
                <div class="d-flex my-5 justify-content-center">
                    <span class="font-weight-bold px-2" style="font-size: 2em;">2.</span>
                    <h2 class="my-auto" style="font-weight: 200;">{{"Ügyfél felmérés összeállítása 3 lépésben"|translate}}</h2>
                </div>
                <div class="row my-4">
                    <div class="col-lg-6 col-12 text-center my-2 mx-auto">
                        <img src="../../../assets/img/tutorial/personal-details.png" alt=""
                            style="width: 250px; height: auto;">
                    </div>
                    <div class="col-lg-6 col-12 my-lg-auto my-2 px-4 reverse">
                        <div class="d-flex justify-content-center mx-auto" style="max-width:400px">
                            <span class="font-weight-bold " style="font-size: 2em;">2a.</span>
                            <p class="text-left pl-2 my-auto">
                                {{"Válaszd ki, milyen személyes adatokat vársz az ügyféltől"|translate}}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12 text-center my-2 mx-auto">
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col-lg-6 col-12 text-center my-lg-auto my-2 mx-auto px-4">
                        <div class="d-flex justify-content-center mx-auto" style="max-width:400px">
                            <span class="font-weight-bold" style="font-size: 2em;">2b.</span>
                            <p class="text-left pl-2 my-auto">
                                {{"Jelöld meg, hogy milyen teszteket töltsön ki a ügyfél"|translate}}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12 text-center my-2 mx-auto">
                        <img src="../../../assets/img/tutorial/select-tests.png" alt="" class="m-auto"
                            style="width: 250px; height: auto;">
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col-lg-6 col-12 text-center my-2 mx-auto">
                        <img src="../../../assets/img/tutorial/generate-link.png" alt=""
                            style="width: 250px; height: auto;">
                    </div>
                    <div class="col-lg-6 col-12 my-lg-auto my-2 px-4 reverse">
                        <div class="d-flex justify-content-center mx-auto" style="max-width:400px">
                            <span class="font-weight-bold " style="font-size: 2em;">2c.</span>
                            <p class="text-left pl-2 my-auto">
                                {{"Generálj linket a felméréshez, amit összeállítottál"|translate}}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12 text-center my-2 mx-auto">
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col-lg-6 col-12 text-center my-lg-auto my-2 mx-auto px-4">
                        <div class="d-flex justify-content-center mx-auto" style="max-width:400px">
                            <span class="font-weight-bold" style="font-size: 2em;">3.</span>
                            <p class="text-left pl-2 my-auto">
                                {{"Küldd el a linket a ügyfélnek"|translate}}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12 text-center my-2 mx-auto">
                        <img src="../../../assets/img/tutorial/share-link.png" alt="" class="m-auto"
                            style="width: 250px; height: auto;">
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col-lg-6 col-12 text-center my-2 mx-auto">
                        <img src="../../../assets/img/tutorial/clients-take-assessment.png" alt=""
                            style="width: 250px; height: auto;">
                    </div>
                    <div class="col-lg-6 col-12 my-lg-auto my-2 px-4 mx-auto text-center reverse"
                        style="max-width:400px">
                        <div class="d-flex justify-content-center mx-auto" style="max-width:400px">
                            <span class="font-weight-bold " style="font-size: 2em;">4.</span>
                            <p class="text-left pl-2 ">
                                {{"Az ügyfeled a linkre kattintva a nekik megfelelő időben, a saját eszközükkel ki tudják tölteni a teszteket"|translate}}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12 text-center my-2 mx-auto">
                    </div>
                </div>
                <div class="row my-4">
                    <div class="col-lg-6 col-12 text-center my-lg-auto my-2 mx-auto px-4">
                        <div class="d-flex justify-content-center mx-auto" style="max-width:400px">
                            <span class="font-weight-bold" style="font-size: 2em;">5.</span>
                            <p class="text-left pl-2 my-auto">{{"Az eredmények összesítője azonnal megérkezik a postafiókodba"|translate}}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-12 text-center my-2 mx-auto">
                        <img src="../../../assets/img/tutorial/receive-results.png" alt="" class="m-auto"
                            style="width: 250px; height: auto;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>