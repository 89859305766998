<nav class="navbar navbar-top navbar-expand navbar-dark bg-primary border-bottom" id="navbar-main" *ngIf="translate.currentLang;">
  <div class="container-fluid" >
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Language selector -->
      <div class=" container d-flex justify-content-end align-items-center">
      <app-lang-switch></app-lang-switch>
    </div>
      <!-- <div class=" container d-flex justify-content-end align-items-center">
        <select (change)="changeLang($event.target.value)"
          class=" lang-control lang-select text-sm">
          <option value="hu" [selected]="lang=='hu'" class="color-white">hu</option>
          <option value="en" [selected]="lang=='en'" class="color-white">en</option>
        </select> 
      </div> -->
      <!-- <div class="container d-flex justify-content-center align-items-center">
        <div class="dropdown">
          <button class="btn btn-outline-danger dropdown-toggle" type="button" id="dropdownMenuButton"
            data-toggle="dropdown" aria-expanded="false"> <span>Countries</span> <i class="fa fa-caret-down"></i>
          </button>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
            <li><a class="dropdown-item" href="#">Australia</a></li>
            <li><a class="dropdown-item" href="#">India</a></li>
            <li><a class="dropdown-item" href="#">United States</a></li>
          </ul>
        </div>
      </div> -->
      <!-- Search form -->
      <!-- <form class="navbar-search navbar-search-light form-inline mr-sm-3" id="navbar-search-main">
        <div class="form-group mb-0" [ngClass]="{ focused: focus === true }">
          <div class="input-group input-group-alternative input-group-merge">
            <div class="input-group-prepend">
              <span class="input-group-text"><i class="fas fa-search"></i></span>
            </div>
            <input class="form-control" placeholder="Search" type="text" (focus)="focus = true"
              (blur)="focus = false" />
          </div>
        </div>
        <button type="button" class="close" (click)="closeSearch()" data-action="search-close"
          data-target="#navbar-search-main" aria-label="Close">
          <span aria-hidden="true">×</span>
        </button>
      </form> -->
      <!-- Navbar links -->
      <ul class="navbar-nav align-items-center ml-md-auto">
        <li class="nav-item d-xl-none">
          <!-- Sidenav toggler -->
          <div class="pr-3 sidenav-toggler sidenav-toggler-dark" data-action="sidenav-pin" data-target="#sidenav-main"
            (click)="openSidebar()">
            <div class="sidenav-toggler-inner">
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
              <i class="sidenav-toggler-line"></i>
            </div>
          </div>
        </li>
        <li class="nav-item d-sm-none">
          <!-- <a class="nav-link" (click)="openSearch()">
            <i class="ni ni-zoom-split-in"></i>
          </a> -->
        </li>
        <!-- <li class="nav-item dropdown" dropdown placement="bottom-right">
          <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" dropdownToggle>
            <i class="ni ni-ungroup"></i>
          </a>
          <div class="dropdown-menu dropdown-menu-lg dropdown-menu-dark bg-default dropdown-menu-right" *dropdownMenu>
            <div class="row shortcuts px-4">
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span class="shortcut-media avatar rounded-circle bg-gradient-red">
                  <i class="ni ni-calendar-grid-58"></i>
                </span>
                <small>Calendar</small>
              </a>
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span class="shortcut-media avatar rounded-circle bg-gradient-orange">
                  <i class="ni ni-email-83"></i>
                </span>
                <small>Email</small>
              </a>
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span class="shortcut-media avatar rounded-circle bg-gradient-info">
                  <i class="ni ni-credit-card"></i>
                </span>
                <small>Payments</small>
              </a>
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span class="shortcut-media avatar rounded-circle bg-gradient-green">
                  <i class="ni ni-books"></i>
                </span>
                <small>Reports</small>
              </a>
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span class="shortcut-media avatar rounded-circle bg-gradient-purple">
                  <i class="ni ni-pin-3"></i>
                </span>
                <small>Maps</small>
              </a>
              <a href="javascript:void()" class="col-4 shortcut-item">
                <span class="shortcut-media avatar rounded-circle bg-gradient-yellow">
                  <i class="ni ni-basket"></i>
                </span>
                <small>Shop</small>
              </a>
            </div>
          </div>
        </li> -->
      </ul>
      <!-- User -->
      <ul class="navbar-nav align-items-center ml-auto ml-md-0">
        <li class="nav-item dropdown" dropdown placement="bottom-right">
          <a class="nav-link pr-0 dropdown-toggle" role="button" dropdownToggle>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" src="assets/img/avatar.png" />
              </span>
              <div class="media-body ml-2 d-lg-block">
                <span class="mb-0 text-sm  font-weight-bold">{{nameOnScreen}}</span>
              </div>
            </div>
          </a>
          <div class="dropdown-menu dropdown-menu-arrow dropdown-menu-right" *dropdownMenu>
            <a routerLinkActive="active" [routerLink]="['/profile']" class="dropdown-item">
              <i class="ni ni-single-02"></i> <span>{{"Fiókom"|translate}}</span>
            </a>
            <div class="dropdown-divider"></div>
            <a style="cursor: pointer;;" (click)="logout();" class="dropdown-item">
              <i class="ni ni-user-run"></i> <span>{{"Kijelentkezés"|translate}}</span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
<div *ngIf="sidenavOpen === true" class="backdrop d-xl-none" (click)="toggleSidenav()"></div>