export const nationalities = {
    en: [
        "Afghan",
        "Algerian",
        "American",
        "Angolan",
        "Argentine",
        "Australian",
        "Austrian",
        "Bangladeshi",
        "Belarusian",
        "Belgian",
        "Bolivian",
        "Bosnian/Herzegovinian",
        "Brazilian",
        "British",
        "Bulgarian",
        "Cambodian",
        "Cameroonian",
        "Canadian",
        "Central African",
        "Chadian",
        "Chinese",
        "Colombian",
        "Congolese",
        "Congolese",
        "Costa Rican",
        "Croatian",
        "Czech",
        "Danish",
        "Dutch",
        "Ecuadorian",
        "Egyptian",
        "Emirati",
        "Estonian",
        "Ethiopian",
        "Finnish",
        "French",
        "German",
        "Ghanaian",
        "Greek",
        "Guatemalan",
        "Honduran",
        "Hungarian",
        "Icelandic",
        "Indian",
        "Indonesian",
        "Iranian",
        "Iraqi",
        "Irish",
        "Israeli",
        "Italian",
        "Ivorian",
        "Jamaican",
        "Japanese",
        "Jordanian",
        "Kazakh",
        "Kenyan",
        "Lao",
        "Latvian",
        "Libyan",
        "Lebanese",
        "Lithuanian",
        "Malagasy",
        "Malaysian",
        "Malian",
        "Mauritanian",
        "Mexican",
        "Moroccan",
        "Namibian",
        "New Zealand",
        "Nicaraguan",
        "Nigerian",
        "Nigerien",
        "Norwegian",
        "Omani",
        "Pakistani",
        "Panamanian",
        "Paraguayan",
        "Peruvian",
        "Philippine",
        "Polish",
        "Portuguese",
        "Romanian",
        "Russian",
        "Salvadoran",
        "Saudi",
        "Senegalese",
        "Serbian",
        "Singaporean",
        "Slovak",
        "Somalian",
        "South African",
        "Spanish",
        "Sudanese",
        "Swedish",
        "Swiss",
        "Syrian",
        "Thai",
        "Tunisian",
        "Turkish",
        "Turkmen",
        "Ukranian",
        "Uruguayan",
        "Vietnamese",
        "Zambian",
        "Zimbabwean",        
    ],
    hu: [
        "afgán",
        "algériai",
        "amerikai",
        "angol",
        "angolai",
        "argentín",
        "ausztrál",
        "bangladeshi",
        "belga",
        "bolgár",
        "bolíviai",
        "bosnyák/hercegovinai",
        "brazil",
        "costa ricai",
        "csádi",
        "cseh",
        "dán",
        "dél-afrikai",
        "ecuadori",
        "egyiptomi",
        "elefántcsontparti",
        "emirátusi",
        "észt",
        "etióp",
        "fehérorosz",
        "finn",
        "francia",
        "fülöp-szigeteki",
        "ghánai",
        "görög",
        "guatemalai",
        "holland",
        "hondurasi",
        "horvát",
        "indián",
        "indonéz",
        "ír",
        "iraki",
        "iráni",
        "izlandi",
        "izraeli",
        "jamaicai",
        "japán",
        "jordán",
        "kambodzsai",
        "kameruni",
        "kanadai",
        "kazah",
        "kenyai",
        "kínai",
        "kolumbiai",
        "kongói",
        "közép-afrikai",
        "lao",
        "lengyel",
        "lett",
        "líbiai",
        "libanoni",
        "litván",
        "madagaszkári",
        "magyar",
        "maláj",
        "mali",
        "marokkói",
        "mauritiusi",
        "mexikói",
        "namíbiai",
        "nicaraguai",
        "nigériai",
        "norvég",
        "olasz",
        "ománi",
        "orosz",
        "osztrák",
        "pakisztáni",
        "panamai",
        "paraguayi",
        "perui",
        "portugál",
        "román",
        "salvadori",
        "spanyol",
        "svájci",
        "svéd",
        "szaúdi",
        "szenegáli",
        "szerb",
        "szingapúri",
        "szír",
        "szlovák",
        "szomáliai",
        "szudáni",
        "thai",
        "török",
        "tunéziai",
        "türkmén",
        "új zélandi",
        "ukrán",
        "uruguayi",
        "vietnámi",
        "zambiai",
        "zimbabwei"
    ]
}