<div class=" container-fluid">
  <!-- <div class=" row"> -->
  <!-- <div class=" col"> -->
  <!-- <div class=" card"> -->
  <!-- <div class=" card-header border-0 row"> -->
  <h2 class=" mb-0 col my-auto"></h2>
  <div class="d-flex  flex-wrap justify-content-end">
    <div class="d-flex form-group  ml-3">
      <div class="tooltip">Hover over me
        <span class="tooltiptext">Tooltip text</span>
      </div>
      <label class=" form-control-label mx-2 my-auto">
        Sorok egyszerre
      </label>
      <select (change)="pageSizeChange($event.target.value)" name="datatable_length" aria-controls="datatable"
        class="form-control form-control-sm w-auto">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="20">20</option>
      </select>
    </div>
    <div id="datatable_filter" class=" ml-3">
      <label>
        <input (input)="searchFieldChange($event.target.value)" type="search" class="form-control form-control-sm" placeholder="Keresés" aria-controls="datatable" />
      </label>
    </div>
  </div>



  <div class=" table-responsive" id="first-list">
    <table class=" table align-items-center table-flush">
      <thead class="thead-light">
        <tr>
          <th [ngClass]="col.class" *ngFor="let col of header" (click)="headerClick(col.id)">
            {{col.displayName}} <i [ngClass]="col.sortClass"></i>
          </th>
        </tr>
      </thead>
      <tbody [@fadeInTrigger]="show">
        <tr *ngIf="dataOnPage.length == 0" >
          <td colspan=8>
            <caption class="mx-auto d-block ">
              <p class="mx-auto d-block text-center mt-3">Nincs a keresésnek megfelelő találat.</p>
              <img style="height:300px" src="../../../../assets/img/vector/not-found.png" alt="not found" class="mx-auto mt-5 mb-3 d-block">
            </caption>
          </td>
        </tr>        
      <ng-container *ngFor="let row of dataOnPage">
        <tr>
          <td>{{row.name}}</td>
          <td>{{row.createdAt}}</td>
          <td>{{row.testCount}} teszt</td>
          <td>{{row.language}}</td>
          <td>{{row.fillOutCount}} kitöltés</td>
          <td class=" text-center">
            <a (click)="expandRow(row.id)" aria-controls="dropdown-basic"
              class=" mx-auto btn btn-sm btn-icon-only text-white bg-primary" role="button">
              <i class=" fa fa-plus"> </i>
            </a>
          </td>
        </tr>
        <tr>
          <td colspan=8 class="p-0 m-0 border-0">
            <div [ngClass]='row.rowClass' class="detail-row px-4">
              <div class="py-4">
                <div class="row gx-1">
                  <div class="col-3 ">
                    <label class="font-weight-bold">Név</label>
                    <div class="d-flex">
                      <input type="text" class="form-control form-control-sm mx-2 w-75" placeholder=""
                        value="{{row.name}}" />
                    </div>
                  </div>
                  <div class="col-3 ">
                    <label class="font-weight-bold">Link</label>
                    <div class="d-flex">
                      <input disabled type="text" class="form-control form-control-sm mx-2 w-75" placeholder=""
                        value="{{row.link}}" />
                      <a class="btn btn-sm btn-icon-only text-white bg-primary" role="button">
                        <i class="fa fa-clone"> </i>
                      </a>
                    </div>
                  </div>
                  <div class="col-3 ">
                    <div>
                      <label class="font-weight-bold">Kiválasztott tesztek</label>
                      <ul class="list-group w-75">
                        <li class="list-group-item p-1" *ngFor="let test of row.testsSelected">{{test}}</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-3 ">

                    <label class="font-weight-bold">Kötelező mezők</label>
                    <perfect-scrollbar style="max-height: 113px;" class=" w-75">
                      <div class="scrollbar-inner">
                        <ul class="list-group">
                          <li class="list-group-item p-1" *ngFor="let field of row.personalFields">{{field}}</li>
                        </ul>
                      </div>
                    </perfect-scrollbar>
                  </div>
                </div>
                <div class="row gx-1">
                  <div class="col-3 ">
                    <label class="font-weight-bold">Ügyfél neve</label>
                    <div class="d-flex">
                      <input type="text" class="form-control form-control-sm mx-2 w-75" placeholder="" value="" />

                    </div>
                  </div>
                  <div class="col-3 ">

                    <label class="font-weight-bold">Ügyfél email címe</label>

                    <div class="d-flex">
                      <input type="text" class="form-control form-control-sm mx-2 w-75" placeholder="" value="" />
                      <div>


                        <a class="btn btn-sm btn-icon-only text-white bg-info" role="button">
                          <i class="fa fa-paper-plane">

                          </i>

                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </ng-container>
    </tbody>
    </table>

    <div class=" card-footer py-4">
      <nav aria-label="...">
        <pagination [totalItems]="pageSize" class="justify-content-end mb-0" [customPreviousTemplate]="prevTemplate"
          [customNextTemplate]="nextTemplate" [totalItems]="filteredData.length" (pageChanged)="pageChanged($event)"
          [(ngModel)]="currentPage">
        </pagination>

        <ng-template #nextTemplate let-currentPage="currentPage">
          <ng-container *ngIf="!disabled">
            <i class='fas fa-angle-right'></i>
          </ng-container>
        </ng-template>

        <ng-template #prevTemplate let-currentPage="currentPage">
          <ng-container *ngIf="!disabled">
            <i class='fas fa-angle-left'></i>
          </ng-container>
        </ng-template>
      </nav>

    </div>