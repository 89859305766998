
import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProgressbarModule } from "ngx-bootstrap/progressbar";
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { PaginationModule } from "ngx-bootstrap/pagination";
import { TooltipModule } from "ngx-bootstrap/tooltip";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

// import { NgxDatatablesComponent } from "./ngxdatatables/ngxdatatables.component";
import { SortableComponent } from "./sortable/sortable.component";
import { HistoryTableComponent } from "./historytable/historytable.component";
import { ComponentsModule } from "src/app/components/components.module";

// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
// import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true
// };

import { RouterModule } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import { PreviewComponent } from "../preview/preview.component";
import { TruncatePipe } from "src/app/pipes/truncate.pipe";

@NgModule({
  declarations: [
    // NgxDatatablesComponent, 
    SortableComponent, 
    HistoryTableComponent,
    PreviewComponent,
    TruncatePipe
    ],
  imports: [
    // AssessmentModule,
    CommonModule,
    TranslateModule,
    // RouterModule.forChild(TablesRoutes),
    // NgxDatatableModule,
    ProgressbarModule.forRoot(),
    BsDropdownModule.forRoot(),
    PaginationModule.forRoot(),
    TooltipModule.forRoot(),
    // PerfectScrollbarModule,
    FormsModule,
    // NgxPrintModule
    ComponentsModule
  ],
  exports: [
    // NgxDatatablesComponent, 
    SortableComponent, 
    HistoryTableComponent,
    
  ],
  providers: [
    // {
    //   provide: PERFECT_SCROLLBAR_CONFIG,
    //   useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    // }
  ]

})
export class TablesModule { }
