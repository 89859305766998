export const header = [
  {
    id: 1,
    name: 'name',
    displayName: 'Teszt neve',
    class: '',
    sortClass: 'fas fa-sort',
    sortEnabled: true
  },
  {
    id: 2,
    name: 'categories',
    displayName: 'Kategória',
    class: '',
    sortClass: '',
    sortEnabled: false
  },
  {
    id: 3,
    name: 'numberOfItems',
    displayName: 'Kérdések száma',
    class: '',
    sortClass: 'fas fa-sort',
    sortEnabled: true
  },
  {
    id: 4,
    name: 'language',
    displayName: 'Nyelve',
    class: '',
    sortClass: 'fas fa-sort',
    sortEnabled: true
  },
  {
    id: 5,
    name: '',
    displayName: 'Hozzáadás a felméréshez',
    class: 'text-center',
    sortClass: '',
    sortEnabled: false
  },
  {
    id: 6,
    name: '',
    displayName: 'Részletek',
    class: 'text-center',
    sortClass: '',
    sortEnabled: false
  },
]

export const data =
[
    {
      id: 1,
      name: 'Drug Use Disorder Identification Test - Extended - DUDIT',
      categories: ['Szerhasználat', 'Függőség'],
      language: 'angol',
      author: 'Berman, A. H., Bergman, H., Palmstierna, T., & Schlyter, F.',
      publicationYear: 2005,
      fillOutTime: 5,
      numberOfItems: 11,
      alphaCoeff: .86,
      shortDesc: 'The DUDIT is an 11-item self-administered screening instrument for drug-related problems, giving information on the level of drug intake and selected criteria for substance abuse/harmful use and dependence according to the ICD-10 and DSM-4 diagnostic systems.&#13;The DUDIT screens effectively for drug-related problems in clinically selected groups and may prove useful in the context of public health surveys. It was developed to complement the AUDIT, both instruments yield scores on a continuous interval scale and can easily be used in criminal justice, addiction treatment, and psychiatric settings.',
      checked: false
    },
    {
      id: 2,
      name: 'Szerhasználati zavart azonosító teszt - Bővített - DUDIT',
      categories: ['Szerhasználat', 'Függőség'],
      language: 'magyar',
      author: 'Berman, A. H., Bergman, H., Palmstierna, T., & Schlyter, F.',
      publicationYear: 2005,
      fillOutTime: 5,
      numberOfItems: 11,
      alphaCoeff: .86,
      shortDesc: 'The DUDIT is an 11-item self-administered screening instrument for drug-related problems, giving information on the level of drug intake and selected criteria for substance abuse/harmful use and dependence according to the ICD-10 and DSM-4 diagnostic systems.<br>The DUDIT screens effectively for drug-related problems in clinically selected groups and may prove useful in the context of public health surveys. It was developed to complement the AUDIT, both instruments yield scores on a continuous interval scale and can easily be used in criminal justice, addiction treatment, and psychiatric settings.',
      checked: false
    },
    {
      id: 3,
      name: `Beck's Depression Inventory I. - BDI I.`,
      categories: ['Depresszió', 'Öngyilkosság'],
      language: 'angol',
      author: 'Beck, A.T., Ward, C. H., Mendelson, M., Mock, J., & Erbaugh, J.',
      publicationYear: 1961,
      fillOutTime: 10,
      numberOfItems: 21,
      alphaCoeff: -1,
      shortDesc: 'The Beck Depression Inventory (BDI) is a 21-item, self-report rating inventory that measures characteristic attitudes and symptoms of depression (Beck, et al., 1961). The BDI has been developed in different forms, including several computerized forms, a card form (May, Urquhart, Tarran, 1969, cited in Groth-Marnat, 1990), the 13-item short form and the more recent BDI-II by Beck, Steer & Brown, 1996. (See Steer, Rissmiller & Beck , 2000 for information on the clinical utility of the BDI-II.) The BDI takes approximately 10 minutes to complete, although clients require a fifth – sixth grade reading level to adequately understand the questions (Groth-Marnat, 1990).',
      checked: false
    },
    {
      id: 4,
      name: `Beck's Depression Inventory I. - BDI I.`,
      categories: ['Depresszió', 'Öngyilkosság'],
      language: 'magyar',
      author: 'Beck, A.T., Ward, C. H., Mendelson, M., Mock, J., & Erbaugh, J.',
      publicationYear: 1961,
      fillOutTime: 10,
      numberOfItems: 21,
      alphaCoeff: -1,
      shortDesc: ' The Beck Depression Inventory (BDI) is a 21-item, self-report rating inventory that measures characteristic attitudes and symptoms of depression (Beck, et al., 1961). The BDI has been developed in different forms, including several computerized forms, a card form (May, Urquhart, Tarran, 1969, cited in Groth-Marnat, 1990), the 13-item short form and the more recent BDI-II by Beck, Steer & Brown, 1996. (See Steer, Rissmiller & Beck , 2000 for information on the clinical utility of the BDI-II.) The BDI takes approximately 10 minutes to complete, although clients require a fifth – sixth grade reading level to adequately understand the questions (Groth-Marnat, 1990).',
      checked: false
    },
    {
      id: 5,
      name: `WHO (Five) Well-Being Index - WHO-5`,
      categories: ['Depresszió'],
      language: 'angol',
      author: 'Topp C.W., Østergaard S.D., Søndergaard S., Bech P.',
      publicationYear: 1998,
      fillOutTime: 3,
      numberOfItems: 5,
      alphaCoeff: -1,
      shortDesc: `A  WHO-5  Jóllét  Indexet  1998-ban  mutatta  be  a  WHO  Európai  Igazgatósága.  A  rövid,  öt 
      kérdéses önkitöltős teszt  alkalmas  a  pillanatnyi  mentális  jóllét  mérésére.  A  cél  egy  olyan 
      eszköz létrehozása volt, amelyet a háziorvosok tudnak használni a mindennapi gyakorlatban.`,
      checked: false
    },
    {
      id: 6,
      name: `WHO Jóllét Index - WHO-5`,
      categories: ['Depresszió'],
      language: 'magyar',
      author: 'Topp C.W., Østergaard S.D., Søndergaard S., Bech P.',
      publicationYear: 1998,
      fillOutTime: 3,
      numberOfItems: 5,
      alphaCoeff: -1,
      shortDesc: `A  WHO-5  Jóllét  Indexet  1998-ban  mutatta  be  a  WHO  Európai  Igazgatósága.  A  rövid,  öt 
      kérdéses önkitöltős teszt  alkalmas  a  pillanatnyi  mentális  jóllét  mérésére.  A  cél  egy  olyan 
      eszköz létrehozása volt, amelyet a háziorvosok tudnak használni a mindennapi gyakorlatban.`,
      checked: false
    },
    {
      id: 7,
      name: `Edinburgh Postnatal Depression Scale - EPDS`,
      categories: ['Depresszió'],
      language: 'angol',
      author: 'J. L. Cox, J. M. Holden, R. Sagovsky',
      publicationYear: 1987,
      fillOutTime: 5,
      numberOfItems: 10,
      alphaCoeff: -1,
      shortDesc: `Postpartum depression is the most common complication of childbearing. The 10-question Edinburgh 
      Postnatal Depression Scale (EPDS) is a valuable and efficient way of identifying patients at risk for “perinatal” 
      depression. The EPDS is easy to administer and has proven to be an effective screening tool.`,
      checked: false
    },
    {
      id: 8,
      name: `Edinburg Posztnatális Depresszió Skála - EPDS`,
      categories: ['Depresszió'],
      language: 'magyar',
      author: 'J. L. Cox, J. M. Holden, R. Sagovsky',
      publicationYear: 1987,
      fillOutTime: 5,
      numberOfItems: 10,
      alphaCoeff: -1,
      shortDesc: `Postpartum depression is the most common complication of childbearing. The 10-question Edinburgh 
      Postnatal Depression Scale (EPDS) is a valuable and efficient way of identifying patients at risk for “perinatal” 
      depression. The EPDS is easy to administer and has proven to be an effective screening tool.`,
      checked: false
    },
    {
      id: 9,
      name: `Young Schema Questionnaire - YSQ-3`,
      categories: ['Személyiségzavar'],
      language: 'angol',
      author: 'J. Young',
      publicationYear: 2005,
      fillOutTime: 45,
      numberOfItems: 90,
      alphaCoeff: -1,
      shortDesc: `Schema Therapy (ST) is a well-known approach for the treatment of personality disorders. This therapy integrates different theories and techniques into an original and systematic treatment model. The Young Schema Questionnaire L-3 (YSQ-L3) is a self-report instrument, based on the ST model, designed to assess 18 Early Maladaptive Schemas (EMSs).`,
      checked: false
    },
    {
      id: 10,
      name: `Young Sémakérdőív - YSQ-3`,
      categories: ['Személyiségzavar'],
      language: 'magyar',
      author: 'J. Young',
      publicationYear: 2005,
      fillOutTime: 45,
      numberOfItems: 90,
      alphaCoeff: -1,
      shortDesc: `Schema Therapy (ST) is a well-known approach for the treatment of personality disorders. This therapy integrates different theories and techniques into an original and systematic treatment model. The Young Schema Questionnaire L-3 (YSQ-L3) is a self-report instrument, based on the ST model, designed to assess 18 Early Maladaptive Schemas (EMSs).`,
      checked: false
    },
    {
      id: 11,
      name: `Schema Mode Inventory - SMI`,
      categories: ['Általános'],
      language: 'angol',
      author: `J. Young, A. Arntz, T. Atkinson, J. Lobbestael, M. Weishaar, M. van Vreeswijk, J. Klokman`,
      publicationYear: 2013,
      fillOutTime: 45,
      numberOfItems: 124,
      alphaCoeff: -1,
      shortDesc: ``,
      checked: false
    },
    {
      id: 12,
      name: `Sémamód kérdőv - SMI`,
      categories: ['Általános'],
      language: 'magyar',
      author: `J. Young, A. Arntz, T. Atkinson, J. Lobbestael, M. Weishaar, M. van Vreeswijk, J. Klokman`,
      publicationYear: 2013,
      fillOutTime: 45,
      numberOfItems: 124,
      alphaCoeff: -1,
      shortDesc: ``,
      checked: false
    },
    {
      id: 13,
      name: `Generalized Anxiety Disorder Scale - GAD-7`,
      categories: ['Szorongás', 'Stressz', 'Pánik'],
      language: 'angol',
      author: `R. L. Spitzer, J. B. W. Williams, K. Kroenke et al`,
      publicationYear: 2006,
      fillOutTime: 6,
      numberOfItems: 13,
      alphaCoeff: -1,
      shortDesc: `Although designed as a screening tool for generalized anxiety, the GAD-7 is also performs reasonably well as a screening tool for three other common anxiety disorders—Panic Disorder, Social Anxiety Disorder, and Posttraumatic Stress Disorder.`,
      checked: false
    },
    {
      id: 14,
      name: `Generalizált Szorongásos Zavar Kérdőív - GAD-7`,
      categories: ['Szorongás', 'Stressz', 'Pánik'],
      language: 'magyar',
      author: `R. L. Spitzer, J. B. W. Williams, K. Kroenke et al`,
      publicationYear: 2006,
      fillOutTime: 6,
      numberOfItems: 13,
      alphaCoeff: -1,
      shortDesc: `Although designed as a screening tool for generalized anxiety, the GAD-7 is also performs reasonably well as a screening tool for three other common anxiety disorders—Panic Disorder, Social Anxiety Disorder, and Posttraumatic Stress Disorder.`,
      checked: false
    }
  ]