import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss']
})
export class ReleaseNotesComponent implements OnInit {


  releases: any[] = [
    { version: '1.0.0', date:'2023.01.01', description: 'Initial release' },
    { version: '1.0.1', 
      date:'2023.03.02', 
      description: 
      `<ul>
        <li>Contact form added to '/presentation'</li>
        <li>Release notes page added, link added to sidebar</li>
        <li>Globe icon added to language selector for clarity</li>
        <li>Introduction page link added to sidebar</li>
        <li>Footer added to introduction page with links and placeholder links to legal docs</li>
        <li>View test bug fixed</li>
        <li>View and download test buttons added for small screens</li>
        <li>Automatically redirect from login page when user is logged in</li>
        <li>Logging out bug fixed</li>
        <li>'Páciens' changed to 'ügyfél' in Hungarian version</li>
      </ul>` },
      { version: '1.0.2', 
      date:'2023.03.12', 
      description: 
      `<ul>
        <li>Mobile friendly version for test reordering added</li>
        <li>English language YSQ-R added to test array</li>
        <li>Language parameter can now be added to url, e.g. https://mentahealth.app/#/presentation?lang=hu</li>
        <li>Angular 15 upgrade</li>
      </ul>` },
      { version: '1.0.3', 
      date:'2023.03.21', 
      description: 
      `<ul>
        <li>Options sliding panel on assesssment page</li>
        <li>User can select option to automatically show next question after picking an answer</li>
      </ul>` },
      { version: '1.0.4', 
      date:'2023.03.30', 
      description: 
      `<ul>
        <li>Newsletter signup form</li>
        <li>Legal docs with sidescrolling tables</li>
      </ul>` },
      { version: '1.1.0', 
      date:'2023.06.16', 
      description: 
      `<ul>
        <li>BMI and Suicide risk tests added</li>
        <li>Clicking on logo reloads wizard route</li>
        <li>Date of birth not obligatory</li>
        <li>Categories assigned to several tests</li>
        <li>Step bug fixed</li>
      </ul>` },
      { version: '1.2.0', 
      date:'2023.10.05', 
      description: 
      `<ul>
        <li>Feedback form</li>
      </ul>` },
  ];


  constructor() { }

  ngOnInit(): void {
  }

}
