<div class="container px-0">
    <div class="shadow-none card news py-4 px-2 mx-auto" style="max-width: 675px;">
        <div class="row justify-content-between align-items-center mb-3 px-4">
            <div class="col-3 text-center">
                <i class="fa fa-2x fa-paper-plane bg-danger"></i>
            </div>
            <div class="col-9">
                <div class="text-white"> {{ translations[1][translate.currentLang] }}</div>
            </div>
        </div>
        <div class="row px-4">
            <div class="col-lg-12">
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                    <div class="form-group mb-4 mt-3">
                        <label for="name" class="text-light">
                            {{ translations[2][translate.currentLang] }}</label>
                        <input type="text" formControlName="name" id="name"
                            [ngClass]="{'is-invalid': contactForm.get('name').invalid && contactForm.get('name').touched}"
                            class="form-control dark-grey-input">
                        <div class="invalid-feedback position-absolute w-50"
                            *ngIf="contactForm.get('name').errors && contactForm.get('name').touched">
                            {{ translations[3][translate.currentLang] }}
                        </div>
                    </div>
                    <div class="form-group mb-4 mt-4">
                        <label for="email" class="text-light">
                            {{ translations[4][translate.currentLang] }}
                        </label>
                        <input type="email" formControlName="email" id="email"
                            [ngClass]="{'is-invalid': contactForm.get('email').invalid && contactForm.get('email').touched}"
                            class="form-control dark-grey-input">
                        <div class="invalid-feedback position-absolute w-50"
                            *ngIf="contactForm.get('email').errors && contactForm.get('email').touched">
                            {{ translations[5][translate.currentLang] }}
                        </div>
                    </div>
                    <div class="form-group mb-4 mt-4">
                        <label for="message" class="text-light">
                            {{ translations[6][translate.currentLang] }}
                        </label>
                        <textarea formControlName="message" id="message"
                            [ngClass]="{'is-invalid': contactForm.get('message').invalid && contactForm.get('message').touched}"
                            class="form-control dark-grey-input"></textarea>
                        <div class="invalid-feedback position-absolute w-50"
                            *ngIf="contactForm.get('message').errors && contactForm.get('message').touched">
                            {{ translations[7][translate.currentLang] }}
                        </div>
                    </div>
                    <div class="form-group mt-4 mt-md-0" style="margin-bottom:0px;">
                        <button #submitButton type="submit" [disabled]=" isSubmitting || submitted"
                            class="btn btn-block btn-danger mx-auto"
                            style="font-family: 'Comfortaa', sans-serif; padding-top: 0.8rem; line-height: 1.5; 
                                   font-size: .8rem; position: relative; max-width: 183px;">
                            <span>{{ translations[8][translate.currentLang] }}</span>
                            <div *ngIf="isSubmitting" class="position-absolute start-50 translate-middle"
                                style="top:25%">
                                <div class="spinner-border text-white" style="width: 1.5rem; height: 1.5rem;"
                                    role="status">
                                </div>
                            </div>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <div class="shadow-none card news py-4 px-2 mx-auto" style="max-width: 675px;">
        <div class="row justify-content-between align-items-center mb-3 px-4">
            <div class="col-3 px-2 text-center">
                <i class="fa fa-2x fa-envelope bg-danger"></i>
            </div>
            <div class="col-9 px-2">
                <div class="text-white "> {{ translations[9][translate.currentLang] }}</div>
                <div class="text-light text-sm"> {{ translations[10][translate.currentLang] }} </div>
            </div>
        </div>
        <form [formGroup]="newsLetterForm" (ngSubmit)="onNewsLetterSubmit()">
            <div class="row px-4">
                <div class="col-12">
                    <label class="text-light">Email</label>
                </div>
            </div>
            <div class="row px-4">
                <div class="col-md-8 col-12">
                    <div class="form-group">
                        <input type="email" formControlName="email" 
                        [ngClass]="{'is-invalid': newsLetterForm.get('email').invalid && newsLetterForm.get('email').touched}"
                        class="form-control dark-grey-input">
                    <div class="invalid-feedback position-absolute w-75"
                        *ngIf="newsLetterForm.get('email').errors && newsLetterForm.get('email').touched">
                        {{ translations[5][translate.currentLang] }}
                    </div>
                    </div>
                </div>
                <div class="col-md-4 col-12">
                    <button #submitNewsButton type="submit" [disabled]=" isNewsSubmitting || newsSubmitted"
                    class="btn btn-block btn-danger mx-auto"
                        style="font-family: 'Comfortaa', sans-serif; padding-top: 0.8rem; 
                        line-height: 1.5; font-size: .8rem; position: relative; max-width: 183px;">
                        <span> {{ translations[11][translate.currentLang] }} </span>
                        <div *ngIf="isNewsSubmitting" class="position-absolute start-50 translate-middle" style="top:20%;left:5%;">
                            <div class="spinner-border text-white" style="width: 1.5rem; height: 1.5rem;" role="status">
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>