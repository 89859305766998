import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(
    private router: Router
    , private titleService: Title
    , private translate: TranslateService
  ){
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        // Show loading indicator
        window.scrollTo(0, 0);
      }

      if (event instanceof NavigationEnd) {
        // Hide loading indicator
      }

      if (event instanceof NavigationError) {
        // Hide loading indicator

        // Present error to user
        console.log(event.error);
      }

      // if(this.translate.currentLang=='hu'){
      //   this.titleService.setTitle('menta - Mentális egészség platform')
      // }else{
      //   this.titleService.setTitle('menta - Mental health platform')
      // }
    });
  }
}
