import { Component, OnInit } from "@angular/core";
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: "app-presentation",
  templateUrl: "./presentation.component.html",
  styleUrls: ["./presentation.component.scss"]
})

export class PresentationComponent implements OnInit {
  test: Date = new Date();
  isCollapsed = true;
  constructor(
    config: NgbCarouselConfig,
    public translate: TranslateService,
  ) {
    config.interval = 3500;
    config.wrap = true;
    config.keyboard = false;
    config.pauseOnHover = true;
  }
  tests; pages;
learnMore = () => {
  const element = document.querySelector("#learn-more")
  element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
};

  trimEllipses = function (original, length) {
    return original.length > length ? original.substring(0, length).trim() + "..." : original;
  }

  ngOnInit() {
    this.tests = [
      {
        name: "WHO (Five) Well-Being Index",
        shortName: "WHO-5",
        description: "The World Health Organisation - Five Well-Being Index (WHO-5) is a short self-reported measure of current mental wellbeing. The measure was first introduced in its present form in 1998 by the WHO Regional Office in Europe.",
        author: "C.W. Topp et al.",
        category: "General"
      },
      {
        name: "Edinburgh Postnatal Depression Scale",
        shortName: "EPDS",
        description: "Postpartum depression is the most common complication of childbearing. The 10-question Edinburgh Postnatal Depression Scale (EPDS) is a valuable and efficient way of identifying patients at risk for “perinatal” depression.",
        author: "Cox et al.",
        category: "Depression"
      },
      {
        name: "Alcohol Use Disorders Identification Test",
        shortName: "AUDIT",
        description: "The AUDIT (Alcohol Use Disorders Identification Test) is a simple and effective method of screening for unhealthy alcohol use, defined as risky or hazardous consumption or any alcohol use disorder.",
        author: "Saunders et al.",
        category: "Substance use disorder"
      },
      {
        name: "Schema Mode Inventory",
        shortName: "SMI",
        description: "The SMI is a brief self-administered",
        author: "Young et al.",
        category: "General"
      },
      {
        name: "Drug Use Disorders Identification Test - Extended",
        shortName: "DUDIT-E",
        description: "Self-administered screening instrument for drug-related problems, giving information on the level of drug intake and selected criteria for substance abuse/harmful use and dependence according to the ICD-10 and DSM-4 diagnostic systems.",
        author: "Berman et al.",
        category: "Substance use disorder"
      },
      {
        name: "Adult ADHD Self-Report Scale Symptom Checklist",
        shortName: "ASRS",
        description: "The Symptom Checklist is an instrument consisting of the eighteen DSM-IV-TR criteria. Six of the eighteen questions were found to be the most predictive of symptoms consistent with ADHD.",
        author: "Biederman et al.",
        category: "Substance use disorder"
      },
      {
        name: "Young Schema Questionnaire 3 - Short Form",
        shortName: "YSQ-SF",
        description: "The Young Schema Questionnaire-Short Form (YSQ-SF) is a 75-item self-report questionnaire that measures 15 EMS (early maladaptive schemas). EMS are grouped in five broad domains.",
        author: "Young et al",
        category: "General"
      },
      {
        name: "Drug Use Disorders Identification Test",
        shortName: "DUDIT",
        description: "Self-administered screening instrument for drug-related problems, giving information on the level of drug intake and selected criteria for substance abuse/harmful use and dependence according to the ICD-10 and DSM-4 diagnostic systems.",
        author: "Berman et al.",
        category: "Substance use disorder"
      },
      {
        name: "Beck Depression Inventory - Short Form",
        shortName: "BDI-9",
        description: "The Beck Depression Inventory (BDI) is a 21-item, self-rated scale that evaluates key symptoms of depression including mood, pessimism, sense of failure, self-dissatisfaction, guilt, punishment, self-dislike, self-accusation, suicidal ideas, crying, irritability, social withdrawal, indecisiveness, body image change, work difficulty, insomnia, fatigability, loss of appetite, weight loss, somatic preoccupation, and loss of libido.",
        author: "Beck et al.",
        category: "Depression"
      },
      {
        name: "Young Schema Questionnaire - Long Form",
        shortName: "YSQ-LF",
        description: "Social Undesirability is missing from the Short Form because it did not emerge as a separate factor in two different research studies. Social Undesirability is no longer considered a separate Early Maladaptive Schema, even though it still appears on the Long Form. Social Undesirability is probably a subset of the Defectiveness / Shame schema. Please note that, on both forms of the YSQ, only 15 of the current 18 Early Maladaptive Schemas are measured.  This is because the listing of 18 schemas has been updated and expanded more recently than the YSQ.  We do not want to alter the YSQ at this time,  because so much research is already ongoing with the current versions. The three schemas that do not appear on the YSQ are: Approval-Seeking/Recognition-Seeking, Negativity/Pessimism, and Punitiveness.",
        author: "Younh et al.",
        category: "General"
      },
      {
        name: "State-Trait Anxiety Inventory",
        shortName: "STAI",
        description: "The State-Trait Anxiety Inventory (STAI) is a psychological inventory consisting of 40 self-report items on a 4-point Likert scale. The STAI measures two types of anxiety: state anxiety and trait anxiety. Higher scores are positively correlated with higher levels of anxiety.",
        author: "Spielberger et al.",
        category: "Anxiety"
      },
    ]


    this.pages = Array.from(Array(Math.round(this.tests.length / 3)).keys())
    

  }
}
