import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Apollo } from "apollo-angular";
import { queryAttributes } from "src/app/graphql/queries";
import { HttpHeaders } from '@angular/common/http';

interface Attribute {
    id: number,
    en: string,
    hu: string
}

@Injectable({
    providedIn: 'root'
})
export class AttributeService {

    constructor(private apollo: Apollo) {
        this.LANGUAGES = this.getAttributes('language');
        this.COUNTRIES = this.getAttributes('country');
        this.CATEGORIES = this.getAttributes('category');
        this.OCCUPATIONS = this.getAttributes('occupation');
    }

    public LANGUAGES: Observable<Attribute[]>;
    public COUNTRIES: Observable<Attribute[]>;
    public CATEGORIES: Observable<Attribute[]>;
    public OCCUPATIONS: Observable<Attribute[]>;
    public LANGUAGESELECTOR = [
        { "id": 119, "en": "English", "hu": "angol","short": "en" },
        { "id": 120, "en": "Hungarian", "hu": "magyar", "short": "hu" }
      ];
    
    public type;

    public getAttributes(type: string): Observable<Attribute[]> {
        this.type = type;
        return this.apollo
            .watchQuery({
                query: queryAttributes(type),
                context: {
                    headers: new HttpHeaders({
                        "atk": localStorage.getItem('token') === null ? '' : localStorage.getItem('token')
                    })
                }
            })
            .valueChanges
            .pipe(map(({ data, loading }) => this.mapAttributes(data['attr'], type)));
    }

    private mapAttributes(response, type): Attribute[] {
        let attribute: Attribute[] = [];
        // console.log('type', type)
        if(['category','language'].includes(type))attribute.push({
            id: -1,
            en: 'All',
            hu: 'Összes'
        })
        response.forEach(element => {
            attribute.push({
                id: Number(element['id']),
                en: element['en'],
                hu: element['hu']
            })
        });
        return attribute;
    }
}
