 <div [@fade] [@languageChanged]="languageTrigger" *ngIf="translate.currentLang;">
 <!-- *ngIf="translate.onLangChange |async" -->
    <div class="container-fluid mt-4">
        <div class="card">
            <div class="card-header">
                <h2 class="mt-3 py-0" style="text-transform: uppercase; font-family: 'Comfortaa', sans-serif;">{{"Ügyfél tesztelése" | translate}}</h2>
            </div>
            <div class="card-body no-side-padding" style="min-height: 90vh">
                <div class="px-4">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/#/"><i class="fas fa-home"></i></a></li>
                        <li class="breadcrumb-item"><a href="/#/wizard">{{"Felmérő összeállítása" | translate}}</a></li>
                        <li class="breadcrumb-item active" aria-current="page" [innerHtml]="currentPage|translate"></li>
                    </ol>
                    <div class="tab" [@fadeInTrigger]="currentTab==0">
                        <div class="row">
                            <div class="col-lg-6 col-md-12">
                                <p>{{"Ebben a menüpontban lehetséges a tesztek összeállítása és kiküldése."|translate}}
                                </p>
                                <h2>{{"Kérdőív beállítások"|translate}}</h2>
                                <h3>{{"Kérdőív nyelve"|translate}}</h3>
                                <p>{{"A kérdőív és a kimenő email nyelvének beállítása - a tesztek nyelve a következő
                                    képernyőn választható ki."|translate}}</p>
                                <div class="d-lg-flex mt-3 mx-auto d-block w-lg-75">
                                    <input type="radio" class="btn-check" name="options" id="success-outlined"
                                        (change)="onAssessmentLanguageChange($event.target.value)" autocomplete="off"
                                        checked value="hu">
                                    <label class="btn btn-outline-primary btn-block  btn-menta"
                                        for="success-outlined">{{"magyar"|translate}}</label>
                                    <input type="radio" class="btn-check" name="options" id="danger-outlined"
                                        (change)="onAssessmentLanguageChange($event.target.value)" autocomplete="off"
                                        value="en">
                                    <label class="btn btn-outline-primary btn-block  btn-menta"
                                        for="danger-outlined">{{"angol"|translate}}</label>
                                </div>
                                <h3 class="mt-3">{{"Ügyfél adatok"|translate}}</h3>
                                <p>{{"Itt megadhatja, hogy a tesztelés során milyen személyes adatokat vár az
                                    ügyféltől."|translate}}

                                <div style="min-height:284px;" class="row m-auto w-100">
                                    <app-loader class="m-auto" style="width:150px;"
                                        *ngIf="!spinnerHiddenForSwitches"></app-loader>
                                    <div class="mt-3 row mx-auto w-100" *ngIf="spinnerHiddenForSwitches">
                                        <div class="col-lg-3 col-6 offset-lg-2 text-right">
                                            <ngContainer *ngFor="let field of personalFields; let even = even;">
                                                <div *ngIf="even" class="pt-1 text-center text-sm align-middle"
                                                    [disabled]="!field.isOptional">
                                                    {{translate.currentLang=='hu' ? field.hu: field.en}}
                                                    {{!field.isOptional ? '*':''}}
                                                </div>
                                                <div *ngIf="even" class="text-center pt-1">
                                                    <label class="switch">
                                                        <input type="checkbox" [checked]="!field.isOptional"
                                                            [disabled]="!field.isOptional" [(ngModel)]="field.checked"
                                                            (change)="pickedPersonalFieldChange(field.id)" >
                                                        <span class="slider round " [id]="field.name"></span>
                                                    </label>
                                                </div>
                                            </ngContainer>
                                        </div>
                                        <div class="col-lg-3 col-6 offset-lg-2 text-right">
                                            <ngContainer *ngFor="let field of personalFields; let odd = odd;">
                                                <div *ngIf="odd" class="pt-1 text-center text-sm align-middle"
                                                    [disabled]="!field.isOptional">
                                                    {{translate.currentLang=='hu' ? field.hu: field.en}}
                                                    {{!field.isOptional ? '*':''}}
                                                </div>
                                                <div *ngIf="odd" class="text-center pt-1">
                                                    <label class="switch">
                                                        <input type="checkbox" [checked]="!field.isOptional"
                                                            [disabled]="!field.isOptional" [(ngModel)]="field.checked"
                                                            (change)="pickedPersonalFieldChange(field.id)" >
                                                        <span class="slider round "></span>
                                                    </label>
                                                </div>
                                            </ngContainer>
                                        </div>
                                        <p class="py-4"><strong>{{"Anonim mód."|translate}}</strong> 
                                            {{"A teszt során nem kérünk nevet, a kitöltő egy
                                            véletlenszerűen generált kóddal azonosítható."|translate}}
                                        </p>  
                                        <div class="row m-auto w-100">
                                        <div class="col-lg-3 col-6 offset-lg-2 text-left">
                                            <div class="pt-1 text-center text-sm align-middle">
                                                {{"Anonim mód"|translate}}
                                            </div>
                                            <div class="text-center pt-1">
                                                <label class="switch">
                                                    <input type="checkbox" [(ngModel)]="anonymousMode"
                                                    (change)="personalFields[0].checked= !personalFields[0].checked;pickedPersonalFieldChange(2)"> 
                                                    <span class="slider round "></span>
                                                </label>
                                            </div>
                                        
                                        </div>
                                        <div class="col-lg-3 col-6 offset-lg-2 text-center">
                                            <div class="pt-1 text-center text-sm align-middle">
                                                {{"Felmérő kód"|translate}}
                                            </div>
                                            <input class="round" maxlength="10" class="bx"
                                            style="border-radius: 3px; 
                                                   border-width:1px;
                                                   width:120px;
                                                   height:2.5rem;
                                                   font-family: Comfortaa;
                                                   font-weight: bold;
                                                   padding-top:12px;" 
                                            [disabled] = !anonymousMode
                                            [(ngModel)] = anonymousCode
                                            (keyup) = "removeReserved()"
                                            (focusout)="anonymousCode ? null : generateCode()"
                                            type="text">
                                        </div>
                                    </div>
                                
                                </div>
                                
                            </div>
                                
                            </div>

                            <div class="col-6 m-auto d-lg-flex d-none">
                                <img class="m-auto" src="../../../assets/img/wizard/setup2.png" style="height:450px   "
                                    alt="Assessment setup">
                            </div>
                        </div>
                        <div style="height: 7rem;"></div>
                    </div>
                    <div class="tab" [@fadeInTrigger]="currentTab==1">
                        <h2>{{"Tesztek kiválasztása"|translate}}</h2>
                        <app-sortable (onTestsPicked)="pickedTestChange($event)"></app-sortable>
                        <div style="height: 10rem;"></div>
                    </div>
                    <div class="tab" [@fadeInTrigger]="currentTab==2">
                        <h2>{{"Választott tesztek"|translate}}</h2>
                        <p>{{"Itt van lehetőség a kiválasztott tesztek áttekintésére, a tesztek sorrendjének
                            megváltoztatására és a felesleges tesztek törlésére."|translate}}</p>

                        <!-- Large screens -->
                        <div class="table-responsive d-md-block d-none">
                            <table class="table align-items-center table-flush">
                                <thead class="thead-light">
                                    <tr>
                                        <th *ngFor="let column of header" [ngClass]="column.class">
                                            {{ column.name | translate }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="allTests" [sortablejs]="selectedTestIds" [sortablejsOptions]="options">
                                    <ng-container *ngFor="let id of selectedTestIds;let i = index;">
                                        <tr>
                                            <td class="text-center">{{i+1}}</td>
                                            <td>{{allTests[id].shortName}}</td>
                                            <td>{{allTests[id].name[translate.currentLang]}}</td>
                                            <td>{{allTests[id].language[translate.currentLang]}}</td>
                                            <td class="drag-handle text-center">
                                                <div class="btn btn-outline-primary btn-sm shadow-none disabled"
                                                    style="cursor:grab; width:75%">

                                                    <svg style="transform: rotate(90deg); margin: auto;" width="30px"
                                                        height="30px" viewBox="0 0 15 15" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <circle cx="4.5" cy="2.5" r=".6" fill="currentColor" />
                                                        <circle cx="4.5" cy="4.5" r=".6" fill="currentColor" />
                                                        <circle cx="4.5" cy="6.499" r=".6" fill="currentColor" />
                                                        <circle cx="4.5" cy="8.499" r=".6" fill="currentColor" />
                                                        <circle cx="4.5" cy="10.498" r=".6" fill="currentColor" />
                                                        <circle cx="4.5" cy="12.498" r=".6" fill="currentColor" />
                                                        <circle cx="6.5" cy="2.5" r=".6" fill="currentColor" />
                                                        <circle cx="6.5" cy="4.5" r=".6" fill="currentColor" />
                                                        <circle cx="6.5" cy="6.499" r=".6" fill="currentColor" />
                                                        <circle cx="6.5" cy="8.499" r=".6" fill="currentColor" />
                                                        <circle cx="6.5" cy="10.498" r=".6" fill="currentColor" />
                                                        <circle cx="6.5" cy="12.498" r=".6" fill="currentColor" />
                                                        <circle cx="8.499" cy="2.5" r=".6" fill="currentColor" />
                                                        <circle cx="8.499" cy="4.5" r=".6" fill="currentColor" />
                                                        <circle cx="8.499" cy="6.499" r=".6" fill="currentColor" />
                                                        <circle cx="8.499" cy="8.499" r=".6" fill="currentColor" />
                                                        <circle cx="8.499" cy="10.498" r=".6" fill="currentColor" />
                                                        <circle cx="8.499" cy="12.498" r=".6" fill="currentColor" />
                                                        <circle cx="10.499" cy="2.5" r=".6" fill="currentColor" />
                                                        <circle cx="10.499" cy="4.5" r=".6" fill="currentColor" />
                                                        <circle cx="10.499" cy="6.499" r=".6" fill="currentColor" />
                                                        <circle cx="10.499" cy="8.499" r=".6" fill="currentColor" />
                                                        <circle cx="10.499" cy="10.498" r=".6" fill="currentColor" />
                                                        <circle cx="10.499" cy="12.498" r=".6" fill="currentColor" />
                                                    </svg>
                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <div (click)="removeTestId(id)"
                                                    class=" btn m-auto bg-primary btn-block  text-center " role="button"
                                                    style="width: 32px;height:32px; padding:0px">
                                                    <i class="text-white fa fa-minus m-auto centered" style="position: absolute;
                                                    left: 0; right: 0; top: 25%;"></i>
                                                </div>

                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <div style="height: 7rem;"></div>
                        </div>
                        <!-- For small screens  -->
                        <div [sortablejs]="selectedTestIds" [sortablejsOptions]="options" class="d-block d-md-none"
                            *ngIf="selectedTestIds.length">
                            <div [@fade]="'in'" class="card-contents" *ngFor="let id of selectedTestIds;let i = index;">
                                <div style="max-width:140px;" class="font-weight-bold">
                                    {{allTests[id].name[translate.currentLang]}}</div>
                                <div style="max-width:60px;">{{allTests[id].language[translate.currentLang]}}</div>
                                <div class="d-flex">
                                    <button (click)="removeTestId(id)"
                                        class="btn btn-sm btn-outline-primary btn-check m-2"
                                        style="height:48px;width:48px;">
                                        <i class="fa fa-2x fa-plus centered" style="transform: rotate(45deg);"></i>
                                    </button>
                                    <button class="btn btn-sm btn-primary btn-check m-2 drag-handle"
                                        style="height:48px;width:48px;">
                                        <i class="fa fa-2x fa-arrows-alt centered"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style="min-height:180px"></div>
                        <!-- For small screens end -->

                    </div>
                    <div class="tab" [@fadeInTrigger]="currentTab==3">
                        <h2>{{"Link kiküldése"|translate}}</h2>
                        <p>{{"A teszt link kiküldésére két lehetőséget is kínálunk. Az első opció választása
                            esetén a tesztek linkjét
                            a rendszer által generált emailben küldjük ki. A második opció egy linket generál, amit a
                            terapeutának
                            személyesen kell eljuttatni az ügyfélhez. Ez a link többször is felhasználható, vagyis
                            ugyanazt a linket
                            több ügyfélnek is el lehet küldeni."|translate}}
                        </p>
                        <div class="row justify-content-center mt-4">
                            <div class="col-md-12 col-lg-4 m-auto align-items-stretch d-flex">
                                <div class="card">
                                    <!-- Disable card overlay -->
                                    <!-- <div id="overlay"></div> -->
                                    <div class="card-header bg-primary"></div>
                                    <div class="card-body mobile-padding">
                                        <h2>{{"Link kiküldése emailben"|translate}}</h2>
                                        <p>{{"Írd be az ügyfeled nevét, emailcímét és kattints a"
                                            |translate}}&nbsp;<b
                                                style="white-space: nowrap;">{{"Küldés"|translate}}&nbsp;<i
                                                    class='fa fa-paper-plane'></i></b>{{" gombra.
                                            Az ügyfeled hamarosan emailt fog kapni, amelyben a teszt kitöltéséhez
                                            szükséges link található."|translate}}</p>
                                        <form role="form" [formGroup]="invitationForm" (ngSubmit)="sendAssessment()">
                                            <div class="d-flex flex-row flex-wrap">
                                                <div class="form-label-group w-100 mb-4">
                                                    <input formControlName="firstName" type="text" id="firstName"
                                                        class="form-control"
                                                        [ngClass]="!firstName.touched ? '' : firstName.invalid ? 'is-invalid':''"
                                                        placeholder="{{ 'Ügyfél keresztneve'}}">
                                                    <label for="firstName" innerHTML=>{{
                                                        'Ügyfél keresztneve'|translate }}</label>
                                                    <div class="invalid-feedback position-absolute">{{
                                                        'Kérünk, add meg ügyfeled keresztnevét'|translate }}
                                                    </div>

                                                </div>
                                                <div class="form-label-group  w-100 mb-4" id="last">
                                                    <input formControlName="lastName" type="text" id="lastName"
                                                        class="form-control"
                                                        [ngClass]="!lastName.touched ? '' : lastName.invalid ? 'is-invalid':''"
                                                        placeholder="{{ 'Ügyfél vezetékneve'}}">
                                                    <label for="lastName">
                                                        {{ 'Ügyfél vezetékneve'|translate}}
                                                    </label>
                                                    <div class="invalid-feedback position-absolute">{{
                                                        'Kérünk, add meg ügyfeled vezetéknevét'|translate }}</div>
                                                </div>
                                                <div class="form-label-group  w-100 mb-4">
                                                    <input formControlName="email" type="email" id="email"
                                                        class="form-control"
                                                        [ngClass]="!email.touched ? '' : email.invalid ? 'is-invalid':''"
                                                        placeholder="{{ 'Ügyfél emailcíme'}}">
                                                    <label for="email">
                                                        {{ 'Ügyfél emailcíme'|translate}}
                                                    </label>
                                                    <div class="invalid-feedback position-absolute">{{
                                                        'Kérünk, add meg ügyfeled emailcímét'|translate }}</div>
                                                </div>
                                            </div>
                                            <div class="mt-4 m-auto row">
                                                <div class="my-auto col-9 align-middle d-inline pr-2"
                                                    style="font-size: 14px;">{{"Email elküldése a saját emailcímemre
                                                    is"|translate}}
                                                </div>
                                                <!-- <div class="d-inline"> -->
                                                <div class="d-flex align-items-center justify-content-center m-auto">
                                                    <label class="switch my-auto">
                                                        <input type="checkbox" formControlName="sendToTherapist"
                                                            id="sendToTherapist">
                                                        <span class="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <button [disabled]="isSubmitted" type="submit"
                                                class="btn btn-primary mt-4 mx-auto btn-block btn-menta">
                                                <div style="display: inline-flex; text-transform: uppercase;">
                                                    <div innerHTML="{{submitBtnText}}">
                                                    </div>
                                                    <svg *ngIf="!spinnerHidden" style="position: relative;"
                                                        class="spinner" viewBox="0 0 50 50">
                                                        <circle class="path" cx="25" cy="25" r="20" fill="none"
                                                            stroke-width="5"></circle>
                                                    </svg>
                                                </div>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-4 d-flex mx-auto align-self-stretch ">
                                <div class="card card-block">
                                    <div class="card-header bg-info"></div>
                                    <div class="pad-util d-block">
                                        <h2>{{"Link megosztása egyénileg"|translate}}</h2>
                                    </div>
                                    <div
                                        class="mobile-padding card-body align-items-center d-flex justify-content-center">
                                        <div>
                                            <div class="spinner-border" role="status"></div>
                                            <div class="spinner-border" role="status"></div>

                                            <div class="d-block">
                                                <p>{{"Kattintson a "|translate}}&nbsp;<b><i class="far fa-clone"
                                                            aria-hidden="true">&nbsp;</i>{{"Link másolása"|translate}}
                                                    </b>{{"gombra. A vágólapra másolt linken keresztül bárki számára
                                                    elérhetővé válik az összeállított tesztsor."|translate}}</p>
                                                <div class="row mt-4" (mouseleave)="p.close()">
                                                    <div class="col-9">
                                                        <input disabled id="link" #link type="text"
                                                            class="text-sm form-control" placeholder=""
                                                            [value]="assessmentLink">
                                                        <div class="spinner-border" role="status"></div>
                                                    </div>
                                                    <div class="d-flex align-items-center justify-content-center m-auto"
                                                        (click)="copied.open();p.close();copyText(link)"
                                                        #copied="ngbPopover" ngbPopover="{{'Lemásolva!'|translate}}"
                                                        [closeDelay]="2000">
                                                        <div (mouseenter)="p.open();copied.close()" #p="ngbPopover"
                                                            ngbPopover="{{'Link másolása'|translate}}"
                                                            class="m-auto d-flex align-items-center justify-content-between col-2">
                                                            <i style="margin-left:-20px" class="far fa-2x fa-clone "
                                                                aria-hidden="true"></i>
                                                        </div>
                                                    </div>
                                                    <div *ngIf=assessmentCreateError>
                                                        {{errorMessage}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style="height: 7rem;"></div>
                    </div>
                    <div
                        style="margin-bottom: 2rem;position: absolute; bottom:0; left:0; width: -moz-available; width: -webkit-fill-available;">
                        <div class="mx-auto px-3">
                            <div class="text-center py-3">
                                <span class="step" [ngClass]="stepIndicatorClass[0]"></span>
                                <span class="step" [ngClass]="stepIndicatorClass[1]"></span>
                                <span class="step" [ngClass]="stepIndicatorClass[2]"></span>
                                <span class="step" [ngClass]="stepIndicatorClass[3]"></span>
                            </div>
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <button [ngStyle]="{'display': prevBtnDisplay}"
                                            class="btn btn-primary my-1 w-lg-50 btn-block btn-menta"
                                            style="text-transform: uppercase;" type="button" id="prevBtn"
                                            (click)="nextPrev(-1)">{{"Előző"|translate}}</button>
                                    </div>
                                    <div class="col-lg-6 text-right">
                                        <button [ngStyle]="{'display': nextBtnDisplay}"
                                            class="btn btn-primary my-1 w-lg-50 btn-block btn-menta"
                                            style="text-transform: uppercase;" [ngClass]="nextBtnClass" type="button"
                                            id="nextBtn"
                                            [disabled]="(currentTab==1&&selectedTestIds.length==0)||(currentTab==2&&selectedTestIds.length==0)"
                                            (click)="nextPrev(1)">{{"Következő"|translate}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>