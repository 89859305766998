import { Component, OnInit, OnDestroy, HostListener } from "@angular/core";
import { Router } from "@angular/router";
import { LocalStorageService } from "src/app/services/localstorage.service";

@Component({
  selector: "app-auth-layout",
  templateUrl: "./auth-layout.component.html",
  styleUrls: ["./auth-layout.component.scss"]
})

export class AuthLayoutComponent implements OnInit, OnDestroy {


  test: Date = new Date();
  public isCollapsed = true;

  // public toggle = '';
  // public currentLanguage;
  // public displayLanguage;
  // public languageOption;

  // toggleLanguage = (switchTo: string) => {
  //   if (switchTo != this.currentLanguage) {
  //     if (switchTo != 'hu') {
  //       // this.currentLanguage = 'en'
  //       this.changeLanguage(this.currentLanguage)
  //       this.languageOption = 'hu'
  //       this.displayLanguage = 'hu - magyar'
  //     } else {
  //       // this.currentLanguage = 'hu'
  //       this.changeLanguage(this.currentLanguage)
  //       this.languageOption = 'en'
  //       this.displayLanguage = 'en - English'
  //     }
  //   }
  //   this.toggle = ""
  // }

  // showHide = () => {
  //   if (this.toggle == 'show') {
  //     this.toggle = ''
  //   } else {
  //     this.toggle = 'show'
  //   }
  // }

  constructor(
    private router: Router,
    private storageService: LocalStorageService
  ) { }

  // changeLanguage(lang: string) {
  //   // Changed in component by user - update
  //   this.storageService.language = lang;
  // }

  // languageChanged(lang: string){
  //   // Observer emitted a new value
  //   this.currentLanguage = lang;
  // }

  ngOnInit() {
    // this.storageService.languageSubject.subscribe((nextLanguage) => {
    //   this.languageChanged(nextLanguage);

    // })

    var html = document.getElementsByTagName("html")[0];
    // html.classList.add("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-default");
    var navbar = document.getElementsByClassName("navbar-horizontal")[0];
    navbar.classList.add("navbar-light");
    navbar.classList.add("navbar-transparent");
  }
  ngOnDestroy() {
    var html = document.getElementsByTagName("html")[0];
    // html.classList.remove("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("bg-default");
    var navbar = document.getElementsByClassName("navbar-horizontal")[0];
    navbar.classList.remove("navbar-light");
    navbar.classList.remove("navbar-transparent");
  }
}
