import { Component, HostListener, OnInit } from '@angular/core';
import { AttributeService } from 'src/app/services/attribute.service';
import { LocalStorageService } from "src/app/services/localstorage.service";

@Component({
  selector: 'app-lang-switch',
  templateUrl: './lang-switch.component.html',
  styleUrls: ['./lang-switch.component.scss']
})
export class LangSwitchComponent implements OnInit {
  @HostListener('document:click', ['$event'])
  function(event): void {
    // console.log(event['target']['id'])
    const id= event['target']['id']; 
    if ( id!= 'navbarDropdownMenuLink' && id!='selectedLanguage' && id!='globe' && id!='globe-svg' ){
    // console.log('close')
    // if (event['target']['id'] != 'lang-toggle')
      this.toggle = ""
      this.isDropdownOpen = false;
    }
  }
  public toggle = '';
  public currentLanguage;
  public LANGUAGEOPTIONS;

  // selectedLanguage = 'English';
  isDropdownOpen = false;

  selectLanguage(language: string) {
    // this.selectedLanguage = language;
    if (language != this.storageService.language) {
      this.storageService.language = language;
    }
    this.isDropdownOpen = false;
  }

  changeLanguage(lang: string) {
    // Changed in component by user - update service
    if (lang != this.storageService.language) {
      this.storageService.language = lang;
    }
  }

  languageChanged(language: string) {
    // Observed subject emitted a new value
    this.currentLanguage = language;
  }

  showHide = () => {
    console.log('showh')
    if (this.toggle == 'show') {
      this.toggle = ''
      this.isDropdownOpen = false;

    } else {
      this.toggle = 'show'
      this.isDropdownOpen = true;
    }
    console.log(this.toggle)
  }

  constructor(
    private storageService: LocalStorageService,
    public attribute: AttributeService,
  ) { }

  ngOnInit(): void {
    this.LANGUAGEOPTIONS = this.attribute.LANGUAGESELECTOR;
    // Subscribe to storage for language settings
    this.storageService.languageSubject.subscribe((nextLanguage) => {
      this.languageChanged(nextLanguage);
    })
  }
}
