import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition,
  state,
  keyframes
} from '@angular/animations';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Apollo } from 'apollo-angular';
import { firstValueFrom, generate, Subscription } from 'rxjs';
import { createAssessmentMutation, sendAssessmentInvite } from 'src/app/graphql/mutations';
import { ClipboardService } from 'ngx-clipboard';
import { PersonalFieldsService } from 'src/app/services/personal-fields.service';
import { ApolloError } from '@apollo/client';
import { HttpHeaders } from '@angular/common/http';
import { SortableOptions } from 'sortablejs';
import { TestsService } from 'src/app/services/tests.service';
import { SortableComponent } from '../tables/sortable/sortable.component';
import { LocalStorageService } from 'src/app/services/localstorage.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ComponentsModule } from 'src/app/components/components.module';

@Component({
  selector: 'app-wizard',
  templateUrl: './wizard.component.html',
  styleUrls: ['./wizard.component.scss'],
  animations: [
    trigger('languageChanged', [
      state('void', style({ opacity: 0 })),
      state('true', style({ opacity: 1 })),
      state('false', style({ opacity: 1 })),
      transition('* => *', animate('.6s',
        keyframes([
          style({ opacity: '0', offset: '0' }),
          style({ opacity: '1', offset: '1' })
        ])
      ))
    ]),
    trigger('fade', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [style({ opacity: 0 }), animate(600)]),
      transition(':leave', animate(600, style({ opacity: 0 })))
    ]),
    trigger('fadeInTrigger', [
      state('true',
        style({
          opacity: '1',
          'max-height': 'unset',
          overflow: 'unset'
        })
      ),
      state('false',
        style({
          opacity: '0',
          'max-height': 0,
          overflow: 'hidden'
        })
      ),
      transition("true => false", animate('.3s',
        keyframes([
          style({ opacity: '1', 'max-height': 'unset', overflow: 'unset', offset: '0' }),
          style({ opacity: '0', 'max-height': 'unset', overflow: 'hidden', offset: '0.99' }),
          style({ opacity: '0', 'max-height': '0', overflow: 'hidden', offset: '1' })
        ])
      )),
      transition("false => true", animate('.5s  .3s',
        keyframes([
          style({ opacity: '0', 'max-height': '0', overflow: 'hidden', offset: '0' }),
          style({ opacity: '0', 'max-height': 'unset', overflow: 'unset', offset: '0.01' }),
          style({ opacity: '1', 'max-height': 'unset', overflow: 'unset', offset: '1' })
        ])
      ))
    ])
  ]
})

export class WizardComponent implements OnInit, OnDestroy {
  @ViewChild(SortableComponent) testsTable: SortableComponent;
  isSubmitted: Boolean = false;
  anonymousMode = false;
  anonymousCode = "";
  submitBtnText: String;
  spinnerHiddenForSwitches: Boolean = false;
  spinnerHidden: Boolean = true;
  errorMessage: any;
  assessmentCreateError: boolean;
  selectedTestIds = [];
  selectedFieldIds = [];
  assessmentLink: string = '';
  assessmentHash: string = '';
  hideNavigation = true;
  languageTrigger = null;
  currentTab = 0;
  prevTab; prevBtnDisplay = 'none';
  nextBtnDisplay = 'inline';
  nextBtnClass = 'btn-primary';
  currentPage = '';
  currentLanguage;
  stepIndicatorClass = ['active', '', '', ''];
  pageNames = ['Ügyfél adatok bekérése',
    'Tesztek kiválasztása',
    'Választott tesztek',
    'Tesztek elküldése'];

  public testIds: any;

  header = [{ name: 'Sorrend', class: 'text-center' },
  { name: 'Teszt rövidítése', class: '' },
  { name: 'Teszt neve', class: '' },
  { name: 'Nyelv', class: '' },
  { name: 'Mozgatás', class: 'text-center' },
  { name: 'Törlés', class: 'text-center' }]
  personalFields: any[];
  assessmentLanguage = "hu";

  testSubscription: Subscription;

  options: SortableOptions = {
    disabled: false,
    handle: ".drag-handle",
  };

  invitationForm: UntypedFormGroup;

  get firstName() { return this.invitationForm.get('firstName'); }
  get lastName() { return this.invitationForm.get('lastName'); }
  get email() { return this.invitationForm.get('email'); }
  get sendToTherapist() { return this.invitationForm.get('sendToTherapist'); }

  constructor(
    public translate: TranslateService,
    private apollo: Apollo,
    private clipboard: ClipboardService,
    private personal: PersonalFieldsService,
    private testService: TestsService,
    private localStorageService: LocalStorageService,
    private formBuilder: UntypedFormBuilder,

  ) { }

  sendAssessment = () => {
    // console.log('submit pushed!')
// this.translate.
    if (!this.invitationForm.valid) {
      this.invitationForm.markAllAsTouched();
    }
    else {
      this.spinnerHidden = false;
      this.submitBtnText = this.translate.instant('Küldés...');

      this.apollo.mutate({
        mutation: sendAssessmentInvite(
          this.assessmentHash,
          this.sendToTherapist.value,
          this.firstName.value,
          this.lastName.value,
          this.email.value),
        context: {
          headers: new HttpHeaders({
            "atk": localStorage.getItem('token') === null ? '' : localStorage.getItem('token')
          })
        }
      }).subscribe(
        {
          next: (response) => {
            this.spinnerHidden = true;
            this.submitBtnText = this.translate.instant('Levél elküldve!');
            this.isSubmitted = true;
          },
          error: (e: ApolloError) => {
            this.isSubmitted = true;
            this.spinnerHidden = true;
            this.errorMessage = e.networkError['error']['errors'][0]['message']
            this.submitBtnText = this.errorMessage;
          }
        });
    }
  }
  createAssessment = (tests: number[], fields: number[], language: string) => {
    // console.log('this.personalFields',this.personalFields)
    this.apollo.mutate({
      mutation: createAssessmentMutation(tests, fields, language),
      context: {
        headers: new HttpHeaders({
          "atk": localStorage.getItem('token') === null ? '' : localStorage.getItem('token')
        })
      }
    }).subscribe(
      {
        next: (response) => {
          this.assessmentHash = response['data']['assessmentsCr']['link'];
          this.assessmentLink = `${environment.baseUrl}#/assessment?id=${response['data']['assessmentsCr']['link']}`
          if(this.anonymousMode){
            this.assessmentLink += `&anon=${this.anonymousCode}`
          }
          this.assessmentLink = this.removeHttp(this.assessmentLink)
          this.assessmentCreateError = false;
        },
        error: (e: ApolloError) => {
          this.errorMessage = e.networkError['error']['errors'][0]['message']
          this.assessmentCreateError = true;
        }
      }
    );
  }

  copyText = (element) => {
    this.clipboard.copyFromContent(element['value'])
  }

  public onAssessmentLanguageChange(value) {
    this.assessmentLanguage = value;
  }

  private removeHttp(url) {
    return url.replace(/^https?:\/\//, '');
  }

  public pickedTestChange(id: any): void {
    if (this.selectedTestIds.includes(id)) {
      this.selectedTestIds.splice(this.selectedTestIds.indexOf(id), 1);
    } else { this.selectedTestIds.push(id) }
  }

  public pickedPersonalFieldChange(id: any): void {
    if (this.selectedFieldIds.includes(id)) {
      this.selectedFieldIds.splice(this.selectedFieldIds.indexOf(id), 1);
    } else { this.selectedFieldIds.push(id) }
  }

  allTests;

  removeTestId = (id) => {
    this.testsTable.uncheckTest(id);
    this.pickedTestChange(id)
    if (this.selectedTestIds.length == 0) this.nextPrev(-1);
  }

  async ngOnInit() {
    this.localStorageService.initialGetLanguage();
    this.showTab(this.currentTab);

    this.submitBtnText = this.translate.instant("Küldés") + "&nbsp;<i class='fa fa-paper-plane'></i>";
    // console.log(this.translate.currentLang)
    // console.log(this.translate.instant("Küldés"))
    
    this.testSubscription = this.testService
      .getTestProperties()
      .subscribe((tests) => {
        this.allTests = {};
        tests.forEach(element => {
          this.allTests[element.id] = {
            shortName: element.shortName,
            name: element.name,
            language: element.language
          }
        });
      });

    this.invitationForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      sendToTherapist: [false]
    });
    this.generateCode();
    this.anonymousCode="M-" + (Math.floor(Math.random() * (99999 - 10000 + 1)) + 1000).toString();

    this.translate.onLangChange.subscribe(() => {
      // for page animation
      if (this.languageTrigger) { this.languageTrigger = false } else {
        this.languageTrigger = true;
        this.submitBtnText = this.translate.instant("Küldés") + "&nbsp;<i class='fa fa-paper-plane'></i>";

      }
    })

    try {
      this.personalFields = await firstValueFrom(this.personal.fields$);
      // console.log('personalFields',this.personalFields);
      this.personalFields.forEach(element => {
        if (!element.isOptional) this.selectedFieldIds.push(element.id)
      });
      this.spinnerHiddenForSwitches = true;
    } catch (error) {
      console.log(error)
    }
    // console.log(this.translate.instant("Küldés"))
    
  }

  showTab = (n) => {
    if (n == 0) {
      this.prevBtnDisplay = "none";
    } else {
      this.nextBtnDisplay = "inline";
      this.prevBtnDisplay = "inline";
    }
    if (n == (3)) {
      this.nextBtnClass = "d-none";
    } else {
      this.nextBtnClass = "btn-primary";
    }
    this.fixStepIndicator(n);
    this.currentPage = `${n + 1}. ${this.pageNames[n]}`;

    setTimeout(() => {
      this.hideNavigation = true;
    }, 300);
  }

  fixStepIndicator = (n) => {
    this.stepIndicatorClass = Array(3).fill('');
    for (let i = 0; i < n; i++) {
      this.stepIndicatorClass[i] = "finish";
    }
    this.stepIndicatorClass[n] = "active";
  }

  nextPrev = (n) => {
    this.hideNavigation = false;
    this.prevTab = this.currentTab;
    this.currentTab = this.currentTab + n;
    if (this.currentTab != 4) {
      if (this.currentTab == 3) {
        // console.log('this.selectedFieldIds',this.selectedFieldIds);
        this.createAssessment(this.selectedTestIds, this.selectedFieldIds, this.assessmentLanguage)
        // console.log(this.assessmentLink)
      }
      this.showTab(this.currentTab)
    } else {
      this.currentTab = 2;
      return false;
    }
  }
  removeReserved(){
    // Remove special characters from user input
    this.anonymousCode = this.anonymousCode.replaceAll(/[^A-Za-z0-9\-]+/g,'');
  }

  generateCode(){
  this.anonymousCode="M-" + (Math.floor(Math.random() * (99999 - 10000 + 1)) + 1000).toString();
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.selectedTestIds, event.previousIndex, event.currentIndex);
  }
  ngOnDestroy() { 
    // this.translate.onLangChange.unsubscribe();
  }
}

