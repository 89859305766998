import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Apollo } from "apollo-angular";
import { QUERY_PERSONAL_FIELDS } from "src/app/graphql/queries";
import { HttpHeaders } from '@angular/common/http';

interface PersonalFields {
  id: number;
  name: string;
  en: string;
  hu: string;
  orderNumber: number;
  isOptional: boolean;
  checked: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class PersonalFieldsService {

  constructor(private apollo: Apollo) {
    // console.log('personal.fields service')
    this.fields$ = this.getFields();
  }

  public fields$: Observable<PersonalFields[]>;

  public getFields(): Observable<PersonalFields[]> {
    // console.log('getPersonalFields')
    return this.apollo
      .watchQuery({
        query: QUERY_PERSONAL_FIELDS,
        context: {
          headers: new HttpHeaders({
            "atk": localStorage.getItem('token') === null ? '' : localStorage.getItem('token')
          })
        }
      })
      .valueChanges
      .pipe(map(({ data, loading }) => this.mapResult(data['personalFields'])));
  }

  private mapResult(response): PersonalFields[] {
    let fields: PersonalFields[] = [];
    response.forEach(element => {
    fields.push( { 
      id : Number(element['id']),
      name: element['name'],
      en: element['en'],
      hu: element['hu'],
      orderNumber: Number(element['orderNumber']),
      isOptional: Boolean(element['isOptional']=='true'),
      checked:Boolean(element['isOptional']!='true')
    })
    fields = fields.sort(({orderNumber:a}, {orderNumber:b}) => a-b)
  });
    return fields;
  }
}
