<div class=" header   align-items-center"
  style="min-height: 100px;  background-size: cover; background-position: center top; background-color: #f8f9fe ;opacity: 0;">
  <span class=" mask bg-white opacity-8"> </span>
  <div class=" container-fluid d-flex align-items-center">
    <div class=" row">
      <div class=" col-lg-7 col-md-10">
      </div>
    </div>
  </div>
</div>
<div class=" container-fluid mt--6 ">
  <div class="row">
    <div class="col-md-12 col-lg-3 col-xl-4 order-lg-2">
      <div class=" card card-profile">
        <img alt="Image placeholder" class=" card-img-top" src="assets/img/background/eberhard-unsplash.jpg" />
        <div class=" row justify-content-center">
          <div class=" col-lg-3 order-lg-2">
            <div class=" card-profile-image">
              <a href="javascript:void(0)">
                <img class="rounded-circle" src="assets/img/avatar.png" />
              </a>
            </div>
          </div>
        </div>
        <div class=" card-header text-center border-0 pt-5 ">
        </div>
        <div class="card-body pt-4" *ngIf="user$ | async as user">
          <div class=" text-center">
            <h5 class=" h3">
              {{nameOnScreen}}<span class=" font-weight-light"></span>
            </h5>
            <div class=" h5 font-weight-300">
              <i class=" ni location_pin mr-2"> </i> {{user?.city}}, {{user?.country[translate.currentLang]}}
            </div>
            <div>
              <i class=" ni education_hat mr-2"> </i> {{user?.occupation[translate.currentLang]}}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-9 col-xl-8 order-lg-1">
      <div class=" card">
        <div class=" card-header">
          <div class=" row align-items-center">
            <div class=" col-8">
              <h2 class=" mb-0" style="text-transform: uppercase; font-family: 'Comfortaa', sans-serif;">{{"Fiók szerkesztése"|translate}}</h2>
            </div>
          </div>
        </div>
        <div class="mobile-padding card-body" *ngIf="userForm.contains('email')">
          <form (ngSubmit)="submitChanges()" [formGroup]="userForm">
            <h6 class=" heading-small text-muted">{{"Személyes adatok"|translate}}</h6>
            <div class="px-lg-4">
              <div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-label-group d-inline-block w-100 mx-2"
                      style="margin-top: 1rem;margin-bottom: 1rem;">
                      <input formControlName="email" type="email" id="email" class="form-control"
                        [ngClass]="!email.touched ? '' : email.invalid ? 'is-invalid':''"
                        placeholder="{{ text.label.email[language] }}" disabled>
                      <label for="email">{{ text.label.email[language] }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="d-lg-flex d-block justify-content-between col-lg-12" [ngClass]="language">
                    <div class="form-label-group w-100 mx-2" style="margin-bottom: 1rem;" id="first">
                      <input formControlName="firstName" type="text" id="firstName" class="form-control"
                        [ngClass]="language" placeholder="{{ text.label.firstName[language] }}">
                      <label for="firstName">{{ text.label.firstName[language] }}</label>
                    </div>
                    <div class="form-label-group  w-100 mx-2 " style="margin-bottom: 1rem;" id="last">
                      <input formControlName="lastName" type="text" id="lastName" class="form-control"
                        [ngClass]="language" placeholder="{{text.label.lastName[language]}}">
                      <label for="lastName">{{ text.label.lastName[language] }}</label>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="OCCUPATIONS">
                  <div class="col-lg-6">
                    <div class="input-group mb-3 mx-2" style="margin-bottom: 1rem;">
                      <div class="input-group-prepend">
                        <label class="text-sm input-group-text" style="color:#495057; " for="occupation">{{
                          text.label.occupation[language] }}</label>
                      </div>
                      <select formControlName="occupation" class="form-control"
                        [ngClass]="!occupation.touched ? '' : occupation.invalid ? 'is-invalid':''"
                        style="height: 3.125rem;" id="occupation">
                        <option value="" selected disabled>{{ text.choose[language] }}</option>
                        <option *ngFor="let occupation of OCCUPATIONS| sort:language;" [ngValue]="occupation['id']">
                          {{occupation[language]}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr class=" my-4" />
            <div>
              <h6 class=" heading-small text-muted">{{"Kapcsolat"|translate}}</h6>
              <div class="px-lg-4">
                <div class="row">
                  <div class="col-lg-12 d-lg-flex d-block justify-content-between">
                    <div class="form-label-group w-100 mx-2" style="margin-top: 1rem;margin-bottom: 1rem;">
                      <input formControlName="streetName" type="text" id="streetName" class="form-control"
                        placeholder="{{ text.label.streetName[language] }}" required>
                      <label for="address">{{ text.label.streetName[language] }}</label>
                    </div>
                    <div class="form-label-group w-100 mx-2" style="margin-top: 1rem;margin-bottom: 1rem;">
                      <input formControlName="houseNumber" type="text" id="houseNumber" class="form-control"
                        placeholder="{{ text.label.houseNumber[language] }}" required>
                      <label for="address">{{ text.label.houseNumber[language] }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12 d-lg-flex d-block">
                    <div class="form-label-group mx-2 w-100" style="margin-bottom: 1rem;">
                      <input formControlName="city" type="text" id="city" class="form-control"
                        placeholder="{{ text.label.city[language] }}" required>
                      <label for="city">{{ text.label.city[language] }}</label>
                    </div>
                    <div class="input-group mb-3 mx-2 w-100" style="margin-bottom: 1rem;">
                      <div class="input-group-prepend">
                        <label class="text-sm input-group-text" style="color:#495057; " for="country">{{
                          text.label.country[language] }}</label>
                      </div>
                      <select style="height: 3.125rem;" formControlName="country" class="form-control" id="country">
                        <option value="" selected disabled>{{ text.choose[language] }}</option>
                        <option *ngFor="let country of COUNTRIES | sort:language;" [ngValue]="country['id']">{{country[language]}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-label-group w-100 mx-2" style="margin-bottom: 1rem;">
                      <input formControlName="zipCode" type="text" id="zipCode" class="form-control"
                        placeholder="{{ text.label.zipCode[language] }}" required>
                      <label class="text-sm" for="zipCode">{{ text.label.zipCode[language] }}</label>
                    </div>
                  </div>
                </div>
                <div class="row py-3">
                  <div class="col-lg-4 m-2">
                    <button type="submit" class="btn btn-block btn-primary">{{"Mentés"|translate}}</button>
                  </div>
                  <div class="col-lg-4 m-2">
                    <button type="button" (click)="discardChanges()"
                      class="btn btn-secondary btn-block ">{{"Elvetés"|translate}}</button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <hr class=" my-4" />
          <h6 class=" heading-small text-muted mb-4">{{"Jelszóváltás"|translate}}</h6>
          <form (ngSubmit)="submitNewPassword()" [formGroup]="passwordForm">
            <div class=" pl-lg-4">
              <div class=" row">
                <div class="col-lg-6">
                  <div class="form-label-group w-100 mx-2">
                    <input formControlName="password" type="password" id="password" class="form-control "
                      [ngClass]="!password.touched ? '' : password.invalid ? 'is-invalid':''"
                      placeholder="{{ text.label.password[language] }}" required autocomplete="new-password">
                    <label class="text-sm" for="password">{{ text.label.password[language] }}</label>
                    <div class="invalid-feedback position-absolute">{{ text.error.password[language]}}</div>
                  </div>
                  <div class="form-label-group w-100 mx-2" style="margin-top: 1.8rem;">
                    <input formControlName="confirmPassword" type="password" id="confirmPassword" class="form-control"
                      [ngClass]="!confirmPassword.touched ? '' : passwordForm.hasError('notSame') ? 'is-invalid':''"
                      placeholder="{{ text.label.confirmPassword[language] }}" required autocomplete="new-password">
                    <label class="text-sm" for="confirmPassword">{{ text.label.confirmPassword[language] }}</label>
                    <div class="invalid-feedback position-absolute">{{ text.error.nonMatchingPassword[language]}}</div>
                  </div>
                </div>
              </div>
              <div class="row py-3">
                <div class="col-lg-4">

                  <button class="m-2" type="submit" [disabled]="passwordForm.hasError('notSame') || password.invalid"
                    class="btn btn-block btn-primary">{{"Mentés"|translate}}</button>
                </div>
                <div class="text-sm col-lg-4 my-auto">
                  {{message}}
                </div>
              </div>
            </div>
          </form>
          <hr class=" my-4" />
          <h6 class=" heading-small text-muted mb-4">{{"Platform nyelve"|translate}}</h6>
          <form [formGroup]="languageForm">
            <div class=" pl-lg-4">
              <div class=" row">
                <div class="col-lg-6 ">
                  <div class="input-group mb-3 mx-2 w-100" style="margin-bottom: 1rem;">
                    <div class="input-group-prepend">
                      <label class="text-sm input-group-text" style="color:#495057; " for="languageSelect">{{
                        text.label.language[language] }}</label>
                    </div>
                    <select style="height: 3.125rem;" formControlName="languageSelect" class="form-control"
                      id="languageSelect" >
                      <option value="" selected disabled>{{ text.choose[language] }}</option>
                      <option (click)="submitLanguageChange()"
                      *ngFor="let lang of LANGUAGEOPTIONS | sort:language;" [ngValue]="lang['id']">
                        {{lang[language]}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>