import { Injectable } from '@angular/core';
import { firstValueFrom, BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { Apollo } from "apollo-angular";
import { fetchAssessmentByLink } from "src/app/graphql/queries";
import { HttpHeaders } from '@angular/common/http';

interface Assessment {
  id?: number;
  language?: string;
  personalFields?: Array<any>;
  tests?: Array<any>;
  totalCount?: number;
  pages?: Array<any>;
}

@Injectable({
  providedIn: 'root'
})
export class AssessmentService {

  public _assessments: BehaviorSubject<Assessment> = new BehaviorSubject(null);
  public readonly assessment$: Observable<Assessment> = this._assessments.asObservable();
  public assessment: Assessment;

  constructor(private apollo: Apollo) { }

  public get(id): Observable<any> {

    return this.apollo.watchQuery({
      query: fetchAssessmentByLink(id),
    })
      .valueChanges
      .pipe(map(({ data, loading }) => this.mapResult(data['assessmentsByLink'][0]))
      )
  }


  private mapResult(response): Assessment {

    let assessment: Assessment = {};
    let totalCount = 0;
    assessment.language = response.language
    let personalFields = []
    response.personalFields.forEach(element => {
      personalFields.push(element.name)
    });
    let tests = [];
    let pages = [];

    response.testsSetsId
      .forEach((test, testIndex) => {
        tests.push(test['testsid'])

        let testId = Number(test.testsid.id)

        if ([68, 69].includes(testId)) {
          pages.push([testIndex, 0, 0, testId]);
          totalCount++;
        }
        test['testsid'].questions
          .forEach((question, questionIndex) => {
            question.subQuestions
              .forEach((subQuestion, subQuestionIndex) => {
                totalCount++;
                pages.push([testIndex, questionIndex, subQuestionIndex, testId]);
              });
          });
      });
    assessment.personalFields = personalFields;
    assessment.tests = tests;
    assessment.totalCount = totalCount;
    assessment.pages = pages;
    assessment.id = response.id;

    return assessment;
  }

  public load(id: string, callbackFn: () => void) {
    let _this = this;
    this.apollo.query({
      query: fetchAssessmentByLink(id),
    }
    ).pipe(map(({ data, loading }) => this.mapResult(data['assessmentsByLink'][0]))
    ).subscribe({
      next(res) {
        // console.log(res)
        _this.assessment = res;
        _this._assessments.next(res);
        callbackFn();
      }
    })
  }
}
