export const displayTexts = { 
    "assessment": { 
      "instructionsText": { 
        "en": "The following assessment form was created by your mental health professional. Menta will not share the data provided with any third parties. By filling in the form, you will help her find the most fitting treatment approach.", 
        "hu": "Az alábbi felmérést mentálhigiénés szakember állította össze. Az itt megadott adatok harmadik személlyel nem kerülnek megosztásra. A kérdőív kitöltésével a szakember munkáját segíti a megfelelő kezelés megtalálásában." 
      }, 
      "expectedTimeText": { 
        "en": "Expected time for completion is around 60 minutes.", 
        "hu": "A várható kitöltési idő 60 perc." 
      }, 
      "startAssessmentButtonText": { 
        "en": "Start assessment", 
        "hu": "Kitöltés elkezdése" 
      }, 
      "completionText": { 
        "en": "Thank you for helping the treatment process by completing the assessment.", 
        "hu": "Köszönjük, hogy a kérdőív kitöltésével segítette a szakember munkáját." 
      }, 
      "completionSubText": { 
        "en": "After clicking on the button below, your answers will be collected and shared with your advisor only.", 
        "hu": "Az alábbi gomb megnyomása után válaszait összegyűjtjük és kizárólag terapeutájával osztjuk meg." 
      } 
    }, 
    "navigation": { 
      "homeText": { 
        "en": "Home", 
        "hu": "Kezdőlap" 
      }, 
      "previousButtonText": { 
        "en": "Previous", 
        "hu": "Előző" 
      }, 
      "nextButtonText": { 
        "en": "Next", 
        "hu": "Következő" 
      }, 
      "goBackButtonText": { 
        "en": "Go back", 
        "hu": "Vissza" 
      }, 
      "startTestButtonText": { 
        "en": "Start test", 
        "hu": "Teszt indítása" 
      }, 
      "submitAssessmentButtonText": { 
        "en": "Submit assessment", 
        "hu": "Eredmények elküldése" 
      } 
    }, 
    "personal": { 
      "titleText": { 
        "en": "Personal data", 
        "hu": "Személyes adatok" 
      }, 
      "subtitleText": { 
          "en": "By filling in the required data, you are helping in assessing your circumstances better. Data will not be shared with third parties.", 
          "hu": "Az adatok kitöltésével az Ön körülményeinek jobb megismerését segíti. Az adatok harmadik féllel nem kerülnek megosztásra." 
        }, 
        "nameText": { 
          "en": "Full name:", 
          "hu": "Teljes név:" 
        }, 
        "emailText": { 
          "en": "Email address:", 
          "hu": "Email cím:" 
        }, 
        "phoneText": { 
          "en": "Phone number:", 
          "hu": "Telefonszám:" 
        }, 
        "sexText": { 
          "en": "Sex:", 
          "hu": "Születési nem:" 
        }, 
        "dobText": { 
          "en": "Date of birth:", 
          "hu": "Születési idő:" 
        }, 
        "nationalityText": { 
          "en": "Nationality:", 
          "hu": "Nemzetiség:" 
        }, 
        "addressText": { 
          "en": "Address:", 
          "hu": "Lakcím:" 
        } 
      } 
    }
