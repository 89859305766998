<div class="container">
  <ng-container *ngIf="(test$ | async) as test; else loading">
    <div class="row justify-content-center">
      <div class="col">
        <div class="text-center">
          <h1>{{ test.nameid[translate.currentLang] }}
          </h1>
          <p>{{ test.testInstructions }}</p>
        </div>
        <hr>
        <ng-container *ngFor="let question of test.questions; let i=index">
          <div>
            <h2>{{i+1}}. {{ question.questionText }}</h2>
            <div>
              <div *ngFor="let subQuestion of question.subQuestions; let j=index" class="py-2 pl-2">
                <div class="row">
                  <div class="col-12">
                    <p class="h3" style="font-weight: 300;">{{j+1}}. {{ subQuestion.subQuestionText }}</p>
                  </div>
                </div>
                <div class="row pt-2">
                  <ng-container *ngFor="let option of subQuestion.options;let k=index">
                    <div class="col-md-6">
                      <label >
                        <div class="pl-4 d-flex" style="align-items: first baseline;">
                          <div class="circle mr-3" style="border: solid .5px rgb(150, 150, 150)">
                            <span class="letter p-5" style="color:#525f7f" >{{letters[k]}}.</span>
                          </div>
                          <span> {{ option.optionText }} </span>
                        </div>
                      </label>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

  </ng-container>

  <ng-template #loading>
    <div class="d-flex justify-content-center align-items-center" style="height:75vh;">
      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" stroke="#144bc6">
        <g fill="none" fill-rule="evenodd" stroke-width="4">
          <circle cx="40" cy="40" r="2">
            <animate attributeName="r" begin="0s" dur="1.8s" values="2; 40" calcMode="spline" keyTimes="0; 1"
              keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" />
            <animate attributeName="stroke-opacity" begin="0s" dur="1.8s" values="1; 0" calcMode="spline"
              keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" />
          </circle>
          <circle cx="40" cy="40" r="2">
            <animate attributeName="r" begin="-0.9s" dur="1.8s" values="2; 40" calcMode="spline" keyTimes="0; 1"
              keySplines="0.165, 0.84, 0.44, 1" repeatCount="indefinite" />
            <animate attributeName="stroke-opacity" begin="-0.9s" dur="1.8s" values="1; 0" calcMode="spline"
              keyTimes="0; 1" keySplines="0.3, 0.61, 0.355, 1" repeatCount="indefinite" />
          </circle>
        </g>
      </svg>
    </div>
  </ng-template>
</div>