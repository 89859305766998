import { Component, OnInit, OnDestroy } from '@angular/core';
import { text } from './text.constants';
import { countries } from './countries.constants';
import { nationalities } from './nationalities.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { sexes } from './sex.constants';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { DomSanitizer, Title } from "@angular/platform-browser";
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { ProgressService } from 'src/app/services/progress.service';

@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss'],
  animations: [
    trigger('fadeIn', [
      state('in',
        style({ opacity: '1' })
      ),
      state('out',
        style({ opacity: '0' })
      ),
      transition("out => in", animate('.4s ease-in'))
    ])
  ]
})
export class AssessmentComponent implements OnInit {
  // assessmentLoading = true;
  spinnerHidden = true;

  // ***
  stage = ['welcome', 'personal', 'question', 'submit']
  text = text;
  countries = countries;
  nationalities = nationalities;
  sexes = sexes;
  // language = 'en';
  language = this.store.language;
  submitBtnText; submitBtnClass;

  get firstName() { return this.store.userForm.get('firstName'); }
  get lastName() { return this.store.userForm.get('lastName'); }
  get email() { return this.store.userForm.get('email'); }
  get phone() { return this.store.userForm.get('phone'); }
  get dob() { return this.store.userForm.get('dob'); }
  get sex() { return this.store.userForm.get('sex'); }
  get specify() { return this.store.userForm.get('specify'); }
  get address() { return this.store.userForm.get('address'); }
  get zip() { return this.store.userForm.get('zip'); }
  get city() { return this.store.userForm.get('city'); }
  get country() { return this.store.userForm.get('country'); }
  get nationality() { return this.store.userForm.get('nationality'); }

  goToFeedback(){
    this.router.navigate(
      ['/feedback'],
      { queryParams: { lang: this.store.language } }
    );
  }

  show = 'out';

  next() {
    if (this.stage[this.store.step] == "welcome") {
      this.store.currentPage++;
      this.show = 'out';
      this.store.step += 1;
    } else if (this.stage[this.store.step] == "personal" && !this.store.userForm.valid) {
      this.store.userForm.markAllAsTouched();
    } else {
      let isAnswered = true;
      if (!this.store.isCustomTest) {
        isAnswered = this.store.testDataForm.controls[this.store.test['id']]
          .get(this.store.question['id'].toString())
          .get(this.store.subQuestion['id'].toString()).valid;
      }
      if (this.stage[this.store.step] == "question" && this.store.isCustomTest) {
        // console.log('custom test!')
        this.store.bmiForm.markAllAsTouched();
        isAnswered = this.store.bmiForm.valid;
      }

      if (this.stage[this.store.step] == "question" && !isAnswered
      ) {
        // this.currentPage--
        return false
      } else if (this.stage[this.store.step] == "question" && isAnswered
      ) {
        this.store.currentPage++;
        this.store.pageNum++;
        if (this.store.pages.length > this.store.pageNum) {
          this.show = 'out';
          this.store.getCurrentQuestion(this.store.pageNum);
        } else {
          // console.log('C')
          this.show = 'out';
          this.store.step += 1;
        }
      }
      else {
        // console.log('B')
        this.store.currentPage++;
        this.show = 'out';
        this.store.step += 1;
        // window.scroll(0, 0);
      }
    }
    setTimeout(() => {
      this.show = 'in';
    }, 0);
  }

  previous() {
    this.show = 'out';
    if (this.stage[this.store.step] == "submit") {
      this.store.currentPage--;
      this.store.step--
      this.store.pageNum--
      this.store.getCurrentQuestion(this.store.pageNum)
    } else if (this.stage[this.store.step] == "question") {
      if (0 == this.store.pageNum) {
        this.store.currentPage--;
        this.store.step--
      } else {
        this.store.currentPage--;
        this.store.pageNum--;
        this.store.getCurrentQuestion(this.store.pageNum);
      }
    } else {
      // console.log('stepback')
      this.store.currentPage--;
      this.store.step -= 1;
    }
    setTimeout(() => {
      this.show = 'in';
    }, 0);
  }

  isThisOn(currentStage) {
    console.log('this.store.step', this.store.step)
    switch (this.store.step) {
      case 0:
        if (currentStage == 'welcome') { return true } else { false }
        break;
      case 1:
        if (currentStage == 'personal') { return true } else { false }
        break;
      case 2:
        if (currentStage == 'question') { return true } else { false }
        break;
      case 3:
        if (currentStage == 'submit') { return true } else { false }
        break;
    }
  }

  optionClicked(linkRef) {
    linkRef.click();
    if (this.uiOptions.autoNext) {
      setTimeout(() => {
        this.next();
      }, 300);
    }
  }

  openSnackBar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.duration = 8000;
    this.snackBar.open(message, action, config);
  }

  constructor(
    public sanitizer: DomSanitizer,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public store: ProgressService,
    private router:Router,
    private titleService: Title
  ) { }

  getAssessmentError = false;
  uiOptions: any;

  onlyNumbersAllowed() {
    this.store.userForm.controls['phone'].setValue(this.store.userForm.value['phone'].replaceAll(/[^0-9\-]+/g, ''));
  }

  optionsChanged(options: any) {
    this.uiOptions = options;
  }


  async ngOnInit() {
    // ***Title
  if(this.store.language=='hu'){
        this.titleService.setTitle('[menta] Online felmérés')
      }else{
        this.titleService.setTitle('[menta] Online assessment')
      }
Object.keys(text).forEach(function(k){
  Object.keys(text[k]).forEach(function(j){
  console.log(text[k][j].en);
});
});

    this.show = 'in'
    this.store.errors.subscribe(message => {
      if (message != null) this.openSnackBar(message, 'Dismiss');
    })

    this.activatedRoute.queryParams.subscribe(params => {
      console.log('query parameters changed!')   
      this.store.loadAssessment(params['id'], params['anon']);
    });
  }
}


