import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Apollo } from "apollo-angular";
import { queryTestsByIdArray, QUERY_PERSONAL_FIELDS } from "src/app/graphql/queries";
import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class TestByIdService {

  constructor(private apollo: Apollo) {
    console.log('test-by-id service')
    
  }

  public fields$: Observable<any[]>;

  public getTest(testIds): Observable<any> {
    console.log('getTest')
    return this.apollo.watchQuery({
        query: queryTestsByIdArray(testIds),
        context: {
          headers: new HttpHeaders({
            "atk": localStorage.getItem('token') === null ? '' : localStorage.getItem('token')
          })
        }
      })
      .valueChanges
      .pipe(map(({ data, loading }) => {
        console.log(data["testsById"][0])  
        return data["testsById"][0]
        }));
  }
}
