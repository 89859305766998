import { gql } from 'apollo-angular';

function addEscapeChar(str) {
  return str.replace(/"/g, '\\"');
}

export interface User {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
  country: number,
  occupation: number
}

export interface Login {
  email: string,
  password: string
}

export const USER_ACTIVATION = gql`
mutation{
 confirmMail 
}`

export function sendAssessmentInvite(
  linkHash: String,
  sendToTherapist: Boolean,
  firstName: String,
  lastName: String,
  email: String
) {
  const req = `mutation {
    invitationMail(
        asseLink: "${linkHash}"
        sendToTherapist: ${sendToTherapist}
        person: { 
          lname: "${lastName}", 
          fname: "${firstName}", 
          email: "${email}" 
        }
    )
  }`
  console.log(req)
  return gql`${req}`
}

export function loginMutation(
  login: Login
) {
  console.log(`
mutation{
 login(
   email: "${login.email}", 
   password: "${login.password}"){
atk
}
}`)

  return gql`
       mutation{
        login(
          email: "${login.email}", 
          password: "${addEscapeChar(login.password)}"){
    atk
  }
}`
}

export function createUserMutation(
  user: User, languageId: number
) {
//   console.log(`
//   mutation {
//     userCr(
//     data: {
//   firstName: "${user.firstName}"
//   lastName: "${user.lastName}"
//   email: "${user.email}"
//   countryId: ${user.country}
//   occupationId: ${user.occupation}
//   password: "${addEscapeChar(user.password)}"
//   languageId: ${languageId}
//   }
// )
// }`)

  return gql`
      mutation {
	userCr(
		data: {
			firstName: "${user.firstName}"
			lastName: "${user.lastName}"
			email: "${user.email}"
      countryId: ${user.country}
      occupationId: ${user.occupation}
			password: "${addEscapeChar(user.password)}"
      languageId: ${languageId}
      }
    )
  }`
}

export function createAssessmentMutation(
  testIds: number[],
  fieldIds: number[],
  language: string
) {
  console.log(`
    mutation{
      assessmentsCr(
        testdata:{testsId:[${testIds}]}
        data: {language: "${language}"}
        perfild: {personalFieldId: [${fieldIds}]})
        {
          link
        }
    }
    `)

  return gql`
      mutation{
        assessmentsCr(
		      testdata:{testsId:[${testIds}]}
		      data: {language: "${language}"}
		      perfild: {personalFieldId: [${fieldIds}]})
          {
            link
          }
      }`
}

export function sendContactForm(
  name: string,
  email: string,
  message: string

) {
 
  return gql`
      mutation{
        sendContactForm(
		      name:"${name}",
		      email:"${email}",
          message:"${message}"
		      )
      }`
}


export function createPassResetMutation(
  email: string,
  language: string
) {
  console.log(
    `
      mutation{
        passResetMail(
		      lang:"${language}",
		      email:"${email}"
		      )
      }`
  )

  return gql`
      mutation{
        passResetMail(
		      lang:"${language}",
		      email:"${email}"
		      )
      }`
}

export function createPassResetMutation2(
  password: string
) {
  // console.log(
  //   ` 
  //   mutation {
  //     passReset(data:{
  //       password:"${password}"
  //     })
  //     }`
  // );
  return gql` 
    mutation {
      passReset(data:{
        password:"${addEscapeChar(password)}"
      })
      }`
}

export function updateUserDetails(
  details: {}
) {
  // console.log(details)

  // console.log(` 
  // mutation {
  //   updateUser(
  //     data: {
  //       ${details.hasOwnProperty('firstName') ? "firstName:\"" + details['firstName'] + "\"" : ""}
  //       ${details.hasOwnProperty('lastName') ? "lastName:\"" + details['lastName'] + "\"" : ""}
  //       ${details.hasOwnProperty('streetName') ? "streetName:\"" + details['streetName'] + "\"" : ""}
  //       ${details.hasOwnProperty('houseNumber') ? "houseNumber:\"" + details['houseNumber'] + "\"" : ""}
  //       ${details.hasOwnProperty('zipCode') ? "zipCode:\"" + details['zipCode'] + "\"" : ""}
  //       ${details.hasOwnProperty('city') ? "city:\"" + details['city'] + "\"" : ""}
        
  //       ${details.hasOwnProperty('country') ? "countryId:" + details['country'] + "" : ""}
  //       ${details.hasOwnProperty('occupation') ? "occupationId:" + details['occupation'] + "" : ""}
  //       ${details.hasOwnProperty('language') ? "languageId:" + details['language'] + "" : ""}
  //     }
  //   ) {
  //     email
  //   }
  // }` )

  return gql` 
    mutation {
      updateUser(
        data: {
          ${details.hasOwnProperty('firstName') ? "firstName:\"" + details['firstName'] + "\"" : ""}
          ${details.hasOwnProperty('lastName') ? "lastName:\"" + details['lastName'] + "\"" : ""}
          ${details.hasOwnProperty('streetName') ? "streetName:\"" + details['streetName'] + "\"" : ""}
          ${details.hasOwnProperty('houseNumber') ? "houseNumber:\"" + details['houseNumber'] + "\"" : ""}
          ${details.hasOwnProperty('zipCode') ? "zipCode:\"" + details['zipCode'] + "\"" : ""}
          ${details.hasOwnProperty('city') ? "city:\"" + details['city'] + "\"" : ""}
          
          ${details.hasOwnProperty('country') ? "countryId:" + details['country'] + "" : ""}
          ${details.hasOwnProperty('occupation') ? "occupationId:" + details['occupation'] + "" : ""}
          ${details.hasOwnProperty('language') ? "languageId:" + details['language'] + "" : ""}

        }
      ) {
        email
      }
    }`
}

export function changeUserPassword(
  password: string
) {

  // console.log(` 
  // mutation {
  //   updateUser(
  //     data: {
  //       password: "${password}"
  //   }
  //   ) {
  //     email
  //   }
  // }` )

  return gql`
  mutation {
    updateUser(
      data: {
        password: "${addEscapeChar(password)}"
    }
    ) {
      email
    }
  }`
}

