import { CommonModule } from '@angular/common';
import { Component, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
// import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";


// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//     suppressScrollX: true
// };
import { AssessmentComponent } from './assessment.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ComponentsModule } from 'src/app/components/components.module';
// import { SortPipe } from 'src/app/pipes/sort.pipe';
import { SortPipeModule } from 'src/app/modules/sort.pipe/sort.pipe.module';

@NgModule({
    declarations: [
        AssessmentComponent,
        
    ],
    exports: [
        AssessmentComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        // PerfectScrollbarModule,
        BrowserAnimationsModule,
        NgbModule,
        // DatepickerModule,
        BsDatepickerModule,
        ComponentsModule,
        SortPipeModule
        
    ],
    // providers: [
    //     {
    //         provide: PERFECT_SCROLLBAR_CONFIG,
    //         useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    //     }
    // ]
})
export class AssessmentModule { } 