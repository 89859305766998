export const countries = {
    en: [
        "Afghanistan",
        "Algeria",
        "Angola",
        "Argentina",
        "Austria",
        "Australia",
        "Bangladesh",
        "Belarus",
        "Belgium",
        "Bolivia",
        "Bosnia and Herzegovina",
        "Brazil",
        "Great Britain",
        "Bulgaria",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Central African Republic",
        "Chad",
        "China",
        "Colombia",
        "Costa Rica",
        "Croatia",
        "Czech Republic",
        "Democratic Republic of the Congo",
        "Denmark",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Estonia",
        "Ethiopia",
        "Finland",
        "France",
        "Germany",
        "Ghana",
        "Greece",
        "Guatemala",
        "Netherlnds",
        "Honduras",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Ivory Coast",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Laos",
        "Latvia",
        "Libya",
        "Lithuania",
        "Madagascar",
        "Malaysia",
        "Mali",
        "Mauritania",
        "Mexico",
        "Morocco",
        "Namibia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Norway",
        "Oman",
        "Pakistan",
        "Panama",
        "Paraguay",
        "Peru",
        "Philippines",
        "Poland",
        "Portugal",
        "Republic of the Congo",
        "Romania",
        "Russia",
        "Saudi Arabia",
        "Senegal",
        "Serbia",
        "Singapore",
        "Slovakia",
        "Somalia",
        "South Africa",
        "Spain",
        "Sudan",
        "Sweden",
        "Switzerland",
        "Syria",
        "Thailand",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Ukraine",
        "The United Arab Emirates",
        "United States",
        "Uruguay",
        "Vietnam",
        "Wales",
        "Zambia",
        "Zimbabwe"
    ],
    hu: [
        "Afganisztán",
        "Algéria",
        "Angola",
        "Argentína",
        "Ausztria",
        "Ausztrália",
        "Banglades",
        "Fehéroroszország",
        "Belgium",
        "Bolívia",
        "Bosznia és Hercegovina",
        "Brazília",
        "Nagy Britannia",
        "Bulgária",
        "Kambodzsa",
        "Kamerun",
        "Kanada",
        "Közép-Afrikai Köztársaság",
        "Csád",
        "Kína",
        "Kolumbia",
        "Costa Rica",
        "Horvátország",
        "Cseh Köztársaság",
        "Kongói Demokratikus Köztársaság",
        "Dánia",
        "Ecuador",
        "Egyiptom",
        "El Salvador",
        "Észtország",
        "Etiópia",
        "Finnország",
        "Franciaország",
        "Németország",
        "Ghána",
        "Görögország",
        "Guatemala",
        "Hollandia",
        "Honduras",
        "Magyarország",
        "Izland",
        "India",
        "Indonézia",
        "Irán",
        "Irak",
        "Írország",
        "Izrael",
        "Olaszország",
        "Elefántcsontpart",
        "Jamaica",
        "Japán",
        "Jordánia",
        "Kazahsztán",
        "Kenya",
        "Laosz",
        "Lettország",
        "Líbia",
        "Litvánia",
        "Madagaszkár",
        "Malájzia",
        "Mauritánia",
        "Mexikó",
        "Marokkó",
        "Namíbia",
        "Új Zéland",
        "Nicaragua",
        "Niger",
        "Nigéria",
        "Norvégia",
        "Omán",
        "Pakisztán",
        "Panama",
        "Paraguay",
        "Peru",
        "Fülöp-szigetek",
        "Lengyelország",
        "Portugália",
        "Kongói Köztársaság",
        "Románia",
        "Oroszország",
        "Szaud-Arábia",
        "Szenegál",
        "Szerbia",
        "Szingapúr",
        "Szlovákia",
        "Szomália",
        "Dél-Afrikai Köztársaság",
        "Spanyolország",
        "Szudán",
        "Svédország",
        "Svájc",
        "Szíria",
        "Thaiföld",
        "Tunézia",
        "Törökország",
        "Türkmenisztán",
        "Ukrajna",
        "Egyesült Arab Emírségek",
        "Egyesült Államok",
        "Uruguay",
        "Vietnám",
        "Zambia",
        "Zimbabwe",
    ]
}