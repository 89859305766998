import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute } from '@angular/router';
import { text } from './text.constants';
import { TranslateService } from '@ngx-translate/core';

import { ApolloError } from "@apollo/client";
import { Apollo } from "apollo-angular";
import { sendContactForm } from "src/app/graphql/mutations";

import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { animate, state, style, transition, trigger } from '@angular/animations';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss'], animations: [
    trigger('fadeIn', [
      state('in',
        style({ opacity: '1' })
      ),
      state('out',
        style({ opacity: '0' })
      ),
      transition("out => in", animate('.4s ease-in'))
    ])]
})
export class FeedbackComponent {

  feedbackForm: FormGroup;
  selectedRating: number = 0;
  stars: number[] = [1, 2, 3, 4, 5];
  feedback: number[][] = [[1, 2, 3, 4, 5], [1, 2, 3, 4, 5], [1, 2, 3, 4, 5]];
  spinnerHidden = true;
  submitBtnClass = "btn-outline-primary";
  language;
  text = text;
  submitBtnText;
  submitted = false;
  show;

  constructor(private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    public snackBar: MatSnackBar,
    public apollo: Apollo,
    public translate: TranslateService,
  ) { }

  ngOnInit() {
    this.show = 'out';
    console.log('show', this.show)

    this.activatedRoute.queryParams.subscribe(params => {
      if (['en', 'hu'].includes(params['lang'])) { this.language = params['lang']; } else {
        this.language = "en";
      }
    });
    this.submitBtnText = this.text.submit[this.language];
    this.feedbackForm = this.fb.group({
      rating: [0, Validators.pattern('[1-5]')],
      comments: [''],
      // privacy: [false, Validators.requiredTrue]
    });

    setTimeout(() => {
      this.show = 'in';
    }, 0);
    
    console.log('show', this.show)
  }

  setRating(rating: number) {
    this.selectedRating = rating;
    this.feedbackForm.get('rating').setValue(rating);
  }

  submitForm() {
    if (this.feedbackForm.valid) {
      // Replace this with your actual HTTP post request
      this.spinnerHidden = false;
      this.submitBtnText = this.text.submitting[this.language];
      //       console.log('Submitting feedback:', this.feedbackForm.value);
      //       console.log(`Értékelés: ${JSON.stringify(this.feedbackForm.value.rating)}
      // Észrevétel: ${JSON.stringify(this.feedbackForm.value.comments).replace(/\"/g, "")}s`)
      try {

        this.apollo.mutate({
          mutation: sendContactForm(
            'feedback form entry',
            'feedback@mentahealth.app',
            `'Értékelés: ${JSON.stringify(this.feedbackForm.value.rating)} Észrevétel: ${JSON.stringify(this.feedbackForm.value.comments).replace(/\"/g, "")}`)
        }).subscribe({
          next: (response) => {
            console.log(response);
            if (response['data']['sendContactForm']) {
              this.submitBtnText = this.text.submissionSuccessful[this.language]
              this.submitted = true;
              this.submitBtnClass = "btn btn-primary"
            }
            else {
              this.submitBtnText = this.text.submissionFailed[this.language]
              setTimeout(() => {
                this.submitBtnText = this.text.submit[this.language]
              }, 1000);
            }
            this.spinnerHidden = true;
          },
          error: (e: ApolloError) => {
            //create snackbar for error message
            this.spinnerHidden = true;
            this.submitBtnText = this.text.submissionFailed[this.language]
            setTimeout(() => {
              this.submitBtnText = this.text.submit[this.language]
            }, 1000);

            console.log(e.networkError['error']['errors'][0]['message'])
            this.openSnackBar(e.networkError['error']['errors'][0]['message'], 'Dismiss')

          }
        });
      } catch (error) {
        this.spinnerHidden = true;
        this.submitBtnText = this.text.submissionFailed[this.language]
        setTimeout(() => {
          this.submitBtnText = this.text.submit[this.language]
        }, 1000);

        console.log(error)
        this.openSnackBar(error, 'Dismiss')
      }


    } else {
      this.feedbackForm.markAllAsTouched();
      console.log("form invalid!")
      console.log(this.feedbackForm.get('rating').invalid)
      console.log(this.feedbackForm.get('rating').touched)
      console.log(!this.feedbackForm.get('rating').invalid && !this.feedbackForm.get('rating').touched)
    }
  }
  openSnackBar(message: string, action: string) {
    let config = new MatSnackBarConfig();
    config.duration = 5000;
    this.snackBar.open(message, action, config);
  }
}

