import { trigger, transition, animate, style } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-cookie-consent',
  templateUrl: './cookie-consent.component.html',
  styleUrls: ['./cookie-consent.component.scss'],
  animations: [
    trigger('fade', [
      transition(':leave', [
        animate('300ms ease-out', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class CookieConsentComponent implements OnInit {

  isAccepted = false;

  constructor(public translate: TranslateService) { }

  ngOnInit() {
    const accepted = localStorage.getItem('cookieAccepted');
    if (accepted === 'true') {
      this.isAccepted = true;
    }
  }

  acceptCookies() {
    localStorage.setItem('cookieAccepted', 'true');
    this.isAccepted = true;
  }
}
