import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Apollo } from 'apollo-angular';
import { Subscription } from 'rxjs';
import { gql } from 'apollo-angular';
import { queryTestsByIdArray } from '../../graphql/queries';
import { HttpHeaders } from '@angular/common/http';
import { ApolloError } from '@apollo/client';
import { displayTexts } from './displaytexts.constants';
import { TestByIdService } from 'src/app/services/test-by-id.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent implements OnInit {

  private querySubscription: Subscription;

  @Input() testId: any;

  errorMessage;

  test = {
    "name": "",
    "testInstructions": "",
    "questions": []
  };

  letters = ['a','b','c','d','e','f','g','h'];

  assessmentLanguage = 'en';
  displayTexts = displayTexts;
  test$;

  constructor(
    // private apollo: Apollo,
    private testById: TestByIdService,
    public translate: TranslateService
  ) { }

  ngOnInit(): void {
    // console.log('preview');

    let token = localStorage.getItem('token');
    console.log('token', token);
    console.log('testId', this.testId);

    let testIds = [this.testId];
    console.log('preview',testIds)
    this.test$ = this.testById.getTest(testIds);

    // this.apollo.query({
    //     query: queryTestsByIdArray(testIds),
    //     context: {
    //         headers: new HttpHeaders({
    //           "atk": localStorage.getItem('token') === null ? '' : localStorage.getItem('token') 
    //         })
    //       }
    //   }).subscribe(
    //     {
    //       next: (response) => {
    //         this.test = response["data"]["testsById"][0];
    //         console.log('this.test', this.test);
    //       },
    //       error: (e: ApolloError) => {
    //         this.errorMessage = e.networkError['error']['errors'][0]['message']
    //         console.log(this.errorMessage)
    //       }
    //     }
    //  );
  }
}
