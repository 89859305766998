import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
// import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
// import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
// import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

// const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
//   suppressScrollX: true
// };
import { SidebarComponent } from "./sidebar/sidebar.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { FooterComponent } from "./footer/footer.component";
// import { VectorMapComponent1 } from "./vector-map/vector-map.component";

import { RouterModule } from "@angular/router";
import { CollapseModule } from "ngx-bootstrap/collapse";
// import { DxVectorMapModule, DxPieChartModule } from 'devextreme-angular';
import { BsDropdownModule } from "ngx-bootstrap/dropdown";
import { TranslateService, TranslatePipe, TranslateModule } from "@ngx-translate/core";
import { LangSwitchComponent } from './lang-switch/lang-switch.component';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { ContactFormComponent } from './contact-form/contact-form.component';
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { OptionsComponent } from './options/options.component';
import { LoaderComponent } from './loader/loader.component';

@NgModule({
  imports: [
    // TranslateService.,
    // TranslatePipe,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    CommonModule,
    RouterModule,
    CollapseModule.forRoot(),
    // PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    LangSwitchComponent,
    CookieConsentComponent,
    ContactFormComponent,
    OptionsComponent,
    LoaderComponent
  ],
  exports: [
    TranslateModule,
    FooterComponent,
    LangSwitchComponent,
    NavbarComponent,
    SidebarComponent,
    CookieConsentComponent,
    ContactFormComponent,
    OptionsComponent,
    LoaderComponent
  ],
  // providers: [
  //   {
  //     provide: PERFECT_SCROLLBAR_CONFIG,
  //     useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  //   }
  // ]
})
export class ComponentsModule { }
