import { ViewportScroller } from "@angular/common";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit {
  test: Date = new Date();

  scrollTo(id: string) {
    this.viewportScroller.scrollToAnchor(id);
  }

  constructor(private viewportScroller: ViewportScroller) { }

  ngOnInit() { }
}
